<template>
  <b-modal size="lg" :title="modalTitle" ref="modal" ok-title="Save" :hide-footer="uploadState != 'uploaded'">
    <template #modal-footer>
      <div class="d-flex w-100 mx-2 align-items-end">
        <div class="flex-grow-1">
          <span v-if="isEditing && !deleting">
            <a href="#"><label for="file-input" class="replace-label" title="replace document"><i class="fa fa-file-import replace-icon mr-2"/>Replace file</label></a>
            <a href="#" @click.prevent="deleting = documents[0]"><i class="fas fa-trash text-danger trash-icon"/>Delete</a>
          </span>
        </div>
        <button class="btn btn-secondary mr-2" @click.prevent="$refs.modal.hide()">Cancel</button>
        <button v-if="deleting" class="btn btn-danger" @click.prevent="onDelete">Delete</button>
        <button v-else class="btn btn-primary" @click.prevent="postDocuments">Save</button>
      </div>
    </template>
    <FileDragTarget v-show="uploadState == 'pending'" fileInput="child" @change="uploadDocuments">
      <div class="big-drag-target d-flex flex-column justify-content-center align-items-center">
        <div class="mb-2">
          To upload documents, drag files here, or...
        </div>
        <label class="btn btn-primary d-inline-block">
          Choose documents...
          <input type="file" id="file-input" @change=uploadDocuments multiple hidden/>
        </label>
      </div>
    </FileDragTarget>
    <div v-if="this.deleting">
      This operation cannot be undone!  Please confirm you wish to delete:<br/>
      <b><document-title :doc="deleting"/></b> ({{ this.deleting.filename }})
    </div>
    <ValidationObserver v-else ref="docForm">
      <div v-for="(doc, index) in documents" class="container-fluid mb-4">
        <div class="form-row">
          <div class="col-12 col-md-6">
            <label v-if="index == 0" class="mainstage-form-label">File</label>
            <label v-else class="mainstage-form-label d-md-none">File</label>
            <div class="filename-container">
              <span class="filename"><document-icon :doc="doc"/>{{ doc.filename }}</span>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ValidationProvider :vid="`documentType-${index}`"
            name="Document Type" mode="passive" rules="required" v-slot="{ errors, classes }">
              <label v-if="index == 0" class="mainstage-form-label">Document Type</label>
              <label v-else class="mainstage-form-label  d-md-none">Document Type</label>
              <select class="form-control animated-error" v-model="doc.document_type">
                <option :value="null">Choose a document type...</option>
                <option v-for="type in documentTypes" :value="type.key">{{ type.title }}</option>
                <option value="other">Other</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-row">
          <div class="col-12" v-if="doc.document_type == 'other'">
            <ValidationProvider :vid="`documentTitle-${index}`" name="Document Title" rules="required" v-slot="{ errors, classes }">
              <label class="mainstage-form-label">Document Title</label>
              <input
              class="form-control"
              type="text"
              v-model="doc.title"
              placeholder="Document title..."
              :disabled="doc.deleted"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
        <b-progress v-if="!doc.upload_id && doc.progress && doc.progress < 1" :value="doc.progress" max="1" class="mt-2"/>
        <div v-if="!doc.upload_id && doc.progress == 1"><i class="fa fa-spinner fa-spin mr-2"></i>Processing...</div>
      </div>
    </ValidationObserver>
    <div class="text-danger ml-3">{{ errors }}</div>
  </div>
  </b-modal>
</template>

<style scoped>
.replace-label,
.trash-icon {
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.big-drag-target {
  height: 300px;
  border: 2px dashed #ccc;
}

.filename-container {
  white-space: nowrap;
  display: flex;
  align-items: center;
  line-height: 1.5;
}

.filename {
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

</style>

<script>
import DocumentIcon from 'components/document_icon.vue'
import FileDragTarget from 'components/file_drag_target.vue'
import UploadWithProgress from 'mixins/upload_with_progress.js'
import axios from 'axios'

export default {
  components: { FileDragTarget, DocumentIcon },
  mixins: [UploadWithProgress],
  props: [ "investor-id" ],
  data() {
    return {
      isEditing: false,
      errors: "",
      documents: [],
      deleting: null
    }
  },
  computed: {
    modalTitle() {
      if ( this.isEditing ) {
        return "Editing " + this.documents[0].filename
      } else {
        return "Upload document(s)"
      }
    },
    documentTypes() {
      return this.$globals.documentTypes.filter(t => t.investor_ownable)
    },
    uploadsComplete() {
      return this.documents.every(d => d.upload_id)
    },
    uploadState() {
      if ( this.documents.length == 0 )
        return 'pending'
      else if ( this.uploadsComplete || this.isEditing )
        return 'uploaded'
      else
        return 'uploading'
    }
  },
  methods: {
    show(docs) {
      this.documents = docs || []
      this.errors = ""
      this.isEditing = !!docs
      this.$refs.modal.show()
      this.deleting = null
    },
    uploadDocuments(data) {
      let files
      if( data.target )
        files = data.target.files
      else
        files = data

      // replace file
      if ( this.isEditing ) {
        this.documents[0].filename = files[0].name
        return this.uploadWithProgress(this.documents[0], files[0])
      }

      for(let i = 0; i < files.length; i++) {
        let doc = {
          document_type: null,
          user_id: this.investorId,
          title: null,
          filename: files[i].name,
          upload_id: null,
          progress: 0
        }

        this.documents.push(doc)
        this.uploadWithProgress(doc, files[i])
      }
    },
    async onDelete() {
      let parameters = {
        authenticity_token: this.$csrfToken,
      }

      let r = await axios.delete(`/documents/${this.documents[0].id}`, { data: parameters })
          .catch(r => this.errors = "Sorry, we couldn't delete that document")

      this.documents[0].deleted = true
      this.$emit("input", [this.documents[0]])
      this.$refs.modal.hide()
    },
    async postDocuments() {
      let success = await this.$refs.docForm.validate()
      if ( !success )
        return

      let parameters = {
        authenticity_token: this.$csrfToken,
      }

      if ( this.isEditing ) {
        parameters.document = this.documents[0]

        let r = await axios.put(`/documents/${this.documents[0].id}`, parameters)
          .catch(r => this.errors = "Sorry, we couldn't save your changes.")

        this.$emit("input", [r.data.document])
      } else {
        parameters.documents = this.documents

        let r = await axios.post('/documents/bulk', parameters)
          .catch(r => this.errors = "Sorry, we couldn't process your upload.")
        this.$emit("input", r.data.documents)
      }
      this.$refs.modal.hide()

    }
  },
}
</script>
