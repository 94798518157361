<template>
  <div class="deleted-overlay">
    <div class="deleted-overlay-blur">
    </div>
    <div class="deleted-overlay-controls">
      <div :class="{'deleted-message': !!msg}" class="d-flex deleted-controls">
        <span class="flex-grow-1">{{ msg }}</span>
        <button class="btn btn-light undelete-button" @click.prevent="$emit('undelete')">
          undelete
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.deleted-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
}

.deleted-overlay-blur {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.6;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 1111;
}

.deleted-overlay-controls {
  z-index: 1112;
  width: 100%;
  height: 100%;
  position: absolute;
}

.deleted-message {
  font-size: 150%;
  color: white;
  background: rgba(0, 0, 0, 0.6);
}

.deleted-controls {
  margin-left: 15px;
  margin-top: 30px;
  margin-right: 15px;
  padding: 10px;
}

.undelete-button {
  color: #111;
}

</style>
<script>
export default {
  props: ["msg"]
}
</script>
