export default {
  install(Vue, options) {
    Vue.prototype.$fieldName = function() {
      if ( this.formPrefix === undefined )
        throw("please set data.formPrefix before using this helper!")

      let name = this.formPrefix
      for ( let i = 0; i < arguments.length; i++ )
        name = name + `[${arguments[i]}]`

      return name
    }
  }
}
