<template>
  <div class="grid-list">
    <span>
      <a :href="href" class="document-link" target="_blank"><document-title :doc="doc"/></a>
    </span>
    <span class="text-right float-right document-links">
      <a v-if="backstageOnly" class="nda-required">Shareholders only</a>
      <a v-else-if="ndaRequired" class="nda-required">VIP Required</a>
      <a v-else :href="href" target="new">view</a>
    </span>
  </div>
</template>
<style scoped>
.document-link  {
  color: #333;
}
.nda-required {
  color: red;
}
</style>
<script>
  export default {
    props: [ "doc", "is-example" ],
    methods: {
      lackingRole(doc, role_id) {
        if ( this.isExample ) {
          return doc.required_role == role_id
        } else {
          return doc.required_role && doc.required_role >= role_id &&
            this.$globals.currentUser.numeric_role < role_id
        }
      }
    },
    computed: {
      href() {
        if ( this.doc.upload_id )
          return `/upload/${this.doc.upload_id}`
        else
          return `/documents/${this.doc.id}`
      },
      ndaRequired() {
        return this.lackingRole(this.doc, 20)
      },
      backstageOnly() {
        return this.lackingRole(this.doc, 30)
      }
    }
  }
</script>
