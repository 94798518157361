<template>
  <div class="position-relative">
    <div v-if="!member.deleted" class="team-member-controls">
      <span class="handle">
      </span>
      <span>
        <a href="#" @click.prevent="$emit('delete')">
          <img class="team-member-delete-button" src="/images/close-black.svg"/>
        </a>
      </span>
    </div>
    <deleted-overlay v-if="member.deleted" @undelete="$emit('undelete')" msg="Deleted"/>
    <input type="hidden" v-if="member.deleted" :name="$fieldName('deleted')" value="1"/>
    <div class="team-member-edit-container">
      <div class="form-group">
        <input type="hidden" :name="$fieldName('idx')" v-model="member.idx" class="hidden-input"/>
        <label :for="$fieldName('name')">Name</label>
        <ValidationProvider :vid="'#team.' + $fieldName('name')"
          name="team member name" rules="required" v-slot="{ errors, classes }">
          <input type="text" class="form-control"
            :id="$fieldName('name')"
            :name="$fieldName('name')"
            placeholder="team member name"
            v-model="member.name"
            :class="classes"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </div>
      <ImageField
        :file-field="$fieldName('photo')"
        :dimensions-field="$fieldName('photo_crop')"
        :initialCrop="member.photo_crop"
        :image-id="member.photo_id"
        :viewport="{ width: 280, height: 280, type: 'circle' }"
        :boundary="{ width: 280, height: 280 }"
        @result="updateCroppedURL($event)"
      />
      <div class="form-group mt-3">
        <label :for="$fieldName('position')">Position</label>
        <input
          type="text"
          class="form-control"
          :name="$fieldName('position')"
          :id="$fieldName('position')"
          placeholder="team member position"
          v-model="member.position"
        />
      </div>
      <div class="form-group">
        <label :for="$fieldName('bio')">Biography</label>
        <markdown-editor
          v-model="member.bio"
          :name="$fieldName('bio')"
          :id="$fieldName('bio')"
          placeholder="team member bio"
          small="true"
        />
      </div>
      <div class="form-group">
        <label :for="$fieldName('linkedin')">LinkedIn URL</label>
        <input
          type="text"
          class="form-control"
          :name="$fieldName('linkedin')"
          :id="$fieldName('linkedin')"
          placeholder="https://www.linkedin.com/in/jerrygharrison/"
          v-model="member.linkedin"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.team-member-edit-container {
  padding: 15px;
}

.team-member-controls {
  float: right;
  display: flex;
  align-items: center;
}

.team-member-delete-button {
  margin: 5px;
  width: 20px;
  height: 20px;
  display: inline-block;
}


.handle {
  margin: 5px;
  display: inline-block;
  width: 16px;
  height: 8px;
  cursor: move;
}

.handle,
.handle::before {
  background-image: radial-gradient(black 40%, transparent 40%);
  background-size: 4px 4px;
  background-position: 0 100%;
  background-repeat: repeat-x;
}

.handle::before {
  content: '';
  display: block;
  width: 100%;
  height: 33%;
}
</style>

<script>
import ImageField from 'components/image_field.vue'

export default {
  data() {
    return {
      ignoreFirstCroppieEvent: !!this.member.photo_id
    }
  },
  props: ["member"],
  components: { ImageField },
  methods: {
    updateCroppedURL(url) {
      // croppie triggers a "user-changed-crop" event when we ask it to load an image.
      // this messes with our "unsaved-changes" logic and so we ignore the first thing it sends
      if ( this.ignoreFirstCroppieEvent ) {
        this.ignoreFirstCroppieEvent = false
        return
      }

      this.member.croppie_url = url
    }
  },
  computed: {
    formPrefix() {
      return `members[${this.member.id}]`
    }
  }
}
</script>

