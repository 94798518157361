<template>
  <div>
    <div v-if="message" class="mb-2">
      <i class="text-secondary">{{ message }}</i>
    </div>
    <div v-for="doc in docs" class="document">
      <span>
        <a :href="h(doc)" class="document-link" target="_blank"><document-title :doc="doc"/></a>
      </span>
      <a v-if="canEdit(doc)" href="#" @click="openEditModal(doc)">Edit</a>
    </div>
    <div class="mt-2"><a href="#" @click.prevent="openUploadModal"><i class="fa fa-upload"/>
      <span v-if="asAdmin">Upload User Documents</span>
      <span v-else>Upload Documents</span>
    </a></div>
    <document-upload-modal ref="uploadModal" :investor-id="investor.id" @input="mergeDocs($event)" :allow-notify-investor="asAdmin"/>
  </div>
</template>
<style scoped>
.document {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 5px;
  margin-right: 15px;
}
</style>
<script>
import Document from 'document.vue'
import DocumentUploadModal from 'document_upload_modal.vue'

export default {
  props: [ "documents", "investor", "message", "as-admin" ],
  data() {
    return { docs: this.documents.slice() }
  },
  components: { Document, DocumentUploadModal },
  methods: {
    mergeDocs(newDocs) {
      for ( let doc of newDocs ) {
        let oldIndex = this.docs.findIndex(d => d.id == doc.id)
        if ( oldIndex == -1 )
          this.docs.push(doc)
        else {
          if ( doc.deleted ) {
            this.docs.splice(oldIndex, 1)
          } else {
            this.$set(this.docs, oldIndex, doc)
          }
        }
      }
    },
    canEdit(doc) {
      return this.asAdmin || doc.investor_uploaded
    },
    openEditModal(doc) {
      let clone = Object.assign({}, doc)
      this.$refs.uploadModal.show([clone])
    },
    openUploadModal() {
      this.$refs.uploadModal.show()
    },
    openDocusignModal() {
      this.$refs.docusignModal.show()
    },
    h(doc) {
      if ( !doc ) return null
      return `/documents/${doc.id}`
    }
  }
}
</script>
