<template>
  <div class="container-fluid purple-box">
    <h1>Company Documents</h1>
    <DocumentEditRow
      v-for="(doc, idx) in docs"
      :key="doc.id"
      :document="doc"
      :folders="folders"
      @input="docChanged($event, idx)"
      @upload-start="$emit('upload-start', $event)"
      @upload-progress="$emit('upload-progress', $event)"
      @upload-complete="$emit('upload-complete', $event)"
      :upload_id="doc.upload_id"
    />
    <FileDragTarget class="d-none d-lg-block" fileInput="child" @change=uploadDocuments>
      <div class="big-drag-target d-flex flex-column justify-content-center align-items-center">
        <div class="mb-2">
          To upload documents, drag files here, or...
        </div>
        <label class="btn btn-primary d-inline-block">
          Choose documents...
          <input type="file" @change=uploadDocuments multiple hidden/>
        </label>
      </div>
    </FileDragTarget>
    <!-- mobile -->
    <label class="d-lg-none btn btn-primary float-right">
      Upload new document
      <input type="file" @change=uploadDocuments multiple hidden/>
    </label>
  </div>
</template>
<style scoped>
.big-drag-target {
  height: 300px;
  border: 2px dashed #ccc;
}
</style>

<script>

import DocumentEditRow from 'document_edit_row.vue'
import FileDragTarget from 'components/file_drag_target.vue'
import UploadWithProgress from 'mixins/upload_with_progress.js'

export default {
  props: ["documents"],
  mixins: [UploadWithProgress],
  components: { DocumentEditRow, FileDragTarget },
  data() {
    let docs = this.documents.slice().filter(t => t.document_type != "investor_pitch_deck")
    return {
      folders: this.computeUniqueFolders(docs.map(d => d.folder)),
      fileLists: [],
      docs: docs
    }
  },
  methods: {
    computeUniqueFolders(folders, folder) {
      let h = {}
      let a = []

      for ( let f of folders.concat([folder]) ) {
        if ( f && !h[f] && f != "__create" ) {
          a.push(f)
          h[f] = 1
        }
        if ( f && f.includes(" / ") ) {
          let split = f.split(" / ")
          for (let i = 0 ; i < split.length; i++ ) {
            let subpath = split.slice(0, i + 1).join(" / ")
            if ( !h[subpath] ) {
              a.push(subpath)
              h[subpath] = 1
            }
          }
        }
      }

      return a.sort()
    },
    docChanged(doc, index) {
      this.folders = this.computeUniqueFolders(this.folders, doc.folder)
      this.$set(this.docs, index, doc)
      this.$emit('input', this.docs)
    },
    uploadDocuments(data) {
      // todo; unify me
      let files
      if( data.target )
        files = data.target.files
      else
        files = data

      for(let i = 0; i < files.length; i++) {
        let doc = {
          id: "_new_" + this.docs.length + i + 1,
          document_type: null,
          title: null,
          filename: files[i].name,
          upload_id: null,
          progress: 0,
          required_role: 10
        }

        this.uploadWithProgress(doc, files[i]).then(() => {
          this.docs.push(doc)
          this.$emit('input', this.docs)
        })
      }
    }
  }
}
</script>
