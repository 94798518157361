<template>
  <form method="post" action="/audience/resend_invites" ref="form">
  <csrf-token/>
  <div class="mt-3 purple-box container-fluid">
    <h1>Resend Invitations</h1>

    <div class="form-group">
      <div class="row">
        <div class="col-12 col-sm-6"><b>Invitee</b></div>
        <div class="d-none d-sm-block col-sm-6"><b>Last Invited</b></div>
      </div>
      <div v-for="(user, idx) in users" class="row mt-2">
        <div class="col-12 col-sm-6">
          <input type="checkbox" name="users[]" :value="user.id" checked>
          {{ user.fullname }} ({{user.email }})
        </div>
        <div class="d-none d-sm-block col-sm-6">
          {{ user.invited_at | formatDatetime }}
        </div>
      </div>
    </div>
    <email-from-chooser form-prefix="invitation" @input="emailFrom = $event"/>
    <div class="form-group">
      <label for="invitation[message]">Message</label>
      <markdown-editor
        id="invitation[message]" name="invitation[message]" :errors="invite.errors"
        disable-underline="true"
        v-model="invite.message" placeholder="your message goes here...

The message will automatically include a link to your Story Vault page."
      />
        <small v-for="error in errors(invite, 'message')" class="text-danger">
          {{ error }}
        </small>
    </div>
    <div class="d-flex flex-direction-column align-items-center">
      <button class="btn btn-primary" @click.prevent="preview">Preview &amp; Send</button>
      <span class="flex-grow-1"></span>
    </div>
     <email-preview
       @send="submit" subject=''
       :body='invite.message'
       ref='email-preview'
       :to-text="previewToText"
       mail-type="resend_invitation_email"
       :to-email="users[0].email"
       :firstname="users[0].firstname"
       :lastname="users[0].lastname"
       :email-from="emailFrom"
     />
  </div>
  </form>
</template>

<style scoped>
label {
  font-weight: bold;
}
.spam-note {
  font-weight: 50;
  color: #777;
  margin-top: 10px;
}
</style>

<script>
import EmailPreview from 'email_preview.vue'
import EmailFromChooser from 'email_from_chooser.vue'

export default {
  components: { EmailPreview, EmailFromChooser },
  props: [ "users" ],
  data() {
    return {
      formPrefix: "invitation",
      emailFrom: "replyTo",
      invite: {
        message: "",
        errors: []
      }
    }
  },
  methods: {
    errors(user, field) {
      if ( user.errors && user.errors[field] )
        return user.errors[field]
      else
        return null
    },
    errorClassFor(user, field) {
      let err = this.errors(user, field)
      if ( err && err.length > 0 ) {
        return [ "is-invalid" ]
      } else {
        return []
      }
    },
    addUser() {
      this.invite.users.push(this.createUser())
    },
    preview() {
      this.$refs['email-preview'].getPreview()
    },
    submit() {
      this.$refs.form.submit()
    },
  },
  computed: {
    previewToText() {
      return "to " + this.users.length + " " + this.$pluralize("guest", this.users.length)
    }
  }
}
</script>



