export default {
  methods: {
    documentURL(obj) {
      if ( obj.upload_id ) {
        return `/upload/${obj.upload_id}`
      } else {
        return `/documents/${obj.document_id}`
      }
    }
  }
}
