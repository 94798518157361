<template>
  <div class="d-flex flex-wrap justify-content-around">
    <div class="purple-box mb-4 w-100">
      <div>
        <h1>Timeline Events <button class="btn btn-primary ml-4" @click.prevent="createEvent">+ New Event</button></h1>
      </div>
      <div v-for="event in events"  :key="event.id">
        <input v-if="event.deleted" type="hidden" :name="`traction[${event.id}][deleted]`" value="1"/>
        <div v-if="event.isEditing" class="traction-edit mb-3">
          <traction-edit-row :event="event" @input="updateEvent($event)" @cancel="cancelEdit(event)" @delete="deleteEvent(event)" @undelete="event.deleted = false"/>
        </div>
        <div v-else :class="{ deleted: event.deleted }" class="position-relative">
          <deleted-overlay v-if="event.deleted" msg="Event will be deleted on save" @undelete="$emit('undelete')" class="delete-overlay"/>
          <p>
            <b>{{ event.title }}</b>
            <a href="#" @click.prevent="beginEdit(event)"><i class="fa fa-pencil"></i></a>
            <br/>
            <i>{{ event.event_date | formatDate }}</i>
            <vue-markdown :source="event.body"/>
          </p>
        </div>
      </div>
      <div v-if="!events || events.length == 0">
        Display your company progress and achievements by building out your company timeline.
        <br/>To get started click "New Event".
      </div>
    </div>
  </div>
</template>

<style scoped>
.traction-box {
  width: 100%;
}


.pencil-small {
  font-size: 0.85rem;
  vertical-align: middle;
}


h3 {
  color: #444;
  font-size: 20px;
}

.deleted {
  padding-top: 30px;
  padding-bottom: 30px;
}

.deleted h4,
.deleted p {
  text-decoration: line-through !important;
}

h4 {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
}
</style>

<script>
import TractionEditRow from './traction_edit_row.vue'

import format from 'date-fns/format'
import parseJSON from 'date-fns/parseJSON'
import parseISO from 'date-fns/parseISO'

export default {
  props: [ "traction_events" ],
  components: { TractionEditRow },
  data() {
    return {
      editEvent: null,
      preEditEvents: {},
      events: this.traction_events.map(e => Object.assign({ isEditing: false, deleted: false }, e)),
      newEvent: this.buildEvent(),
      newEventID: 1
    }
  },
  methods: {
    updateEvent(ev) {
      let idx = this.events.findIndex(e => e.id == ev.id)
      if ( idx >= 0 ) {
        this.$set(this.events, idx, ev)
      }
      this.$emit('input', this.events)
    },
    beginEdit(ev) {
      this.preEditEvents[ev.id] = Object.assign({}, ev)
      ev.isEditing = true
    },
    cancelEdit(ev) {
      this.updateEvent(this.preEditEvents[ev.id])
    },
    deleteEvent(ev) {
      if ( ev.isNew ) {
        let idx = this.events.findIndex(e => e.id == ev.id)
        if ( idx >= 0 )
          this.events.splice(idx, 1)
      } else {
        ev.deleted = true
        ev.isEditing = false
      }

      this.$emit('input', this.events)
    },
    createEvent() {
      this.events.unshift(this.buildEvent())
    },
    buildEvent() {
      let today = format(new Date(), "yyyy-MM-dd")
      return { id: "_new_" + this.newEventID++, title: null, body: null, event_date: today, isEditing: true, isNew: true }
    }
  },
  computed: {
    eventsByMonth() {
      let byMonth = {}
      let keys = []
      this.events.forEach(ev => {
        let month = format(parseISO(ev.event_date), "MMMM yyyy")
        if ( byMonth[month] != undefined ) {
          byMonth[month].push(ev)
        } else {
          keys.push(month)
          byMonth[month] = [ev]
        }
      })

      return keys.map(k => {
        return { title: k, events: byMonth[k] }
      })
    }
  }
}
</script>
