<template>
  <div>
    <company-profile-headline headline="Company Timeline" edit-text="Edit timeline" edit-link='/edit#timeline'/>
    <example-overlay :isExample="events.isExample" edit-link="/edit#traction" max-width="450px">
      <vertical-timeline :inputData="timelineEvents" :reversed="true"/>
    </example-overlay>
  </div>
</template>
<style scoped>
h3 {
  color: #222;
}
h4 {
  color: #555;
  font-weight: normal;
}
</style>
<script>
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

import VerticalTimeline from './vertical_timeline.vue'
import ExampleData from 'mixins/example_data.js'
import CompanyProfileHeadline from 'components/company_profile_headline.vue'

let exampleTimeline = [
  { title: "Company launches", event_date: "2019-09-15", body: "Company launches aboard great steamship, bound for greatness and Cleveland." },
  { title: "Seed round opens", event_date: "2019-12-15", body: "Company is pleased to announce convertible debt offering of $1.5m" },
  { title: "15 new customers", event_date: "2020-03-15", body: "Company brings 12 great new customers aboard." }
]

export default {
  mixins: [ ExampleData ],
  components: { VerticalTimeline, CompanyProfileHeadline },
  props: [ "traction_events" ],
  computed: {
    events() {
      let events = this.traction_events
      if ( events )
        events = events.filter(e => !e.deleted)

      return this.exampleData(events, exampleTimeline)
    },
    timelineEvents() {
      return this.events.data.map(e => {
        let parsed = parseISO(e.event_date)
        let formatted

        if ( isNaN(parsed) )
          formatted = ""
        else
          formatted = format(parsed, "MMMM yyyy")

        return {
          _id: e.id,
          jsonDate: e.event_date,
          date: formatted,
          title: e.title,
          text: e.body,
          tags: []
        }
      })
    },
  }
}
</script>
