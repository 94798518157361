<template>
  <b-modal ref="modal" size="xl" :hide-header="true" :hide-footer="true" ok-title="Close" class="team-modal" ok-only>
    <div class="float-left">
      <a href="#" @click.prevent="$refs.modal.hide()"><i class="fa fa-close"/></a>
    </div>
    <div class="float-right">
      <a v-if="member.linkedin" :href="member.linkedin" target="_blank">
        <img src="/images/linkedin.svg" class="team-profile-linkedin-image"/>
      </a>
    </div>
    <div class="team-member">
      <div class="team-profile-pic">
        <img :src="imageURL">
      </div>
      <div class="team-profile-name">
        {{ member.name || "Name" }}
      </div>
      <div class="team-profile-position">
        {{ member.position || "&nbsp;" }}
      </div>
      <div class="team-profile-bio">
        <vue-markdown :source="member.bio" :anchorAttributes="{ target: '_blank' }"/>
      </div>
    </div>
  </b-modal>
</template>
<style scoped>

.team-modal {
  background: #eee;
}

.team-profile-linkedin-image {
  width: 30px;
}

.team-profile-name {
  font-size: 3rem;
  text-align: center;
}

.team-profile-position {
  text-align: center;
  font-size: 1.5rem;
  color: #666;
}

.team-profile-pic {
  text-align: center;
}
.team-profile-pic img {
  max-width: 350px;
  clip-path: circle(50% at center);
}

.team-profile-bio {
  margin: auto;
  max-width: 800px;
}
</style>
<script>
export default {
  props: [ 'imageURL', 'member', 'visible' ],
  methods: {
    showModal() {
      this.$refs.modal.show()
    }
  }

}
</script>

