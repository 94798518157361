<template>
  <div>
    <label class="d-none d-lg-block">&nbsp;</label>
    <div class="w-100">
      <label class="btn btn-primary mb-0">
        Replace
        <input type="file" @change="$emit('change', $event)" :accept=accept hidden/>
      </label>
      <button v-if="!hideDelete" class="btn btn-danger " @click.prevent="$emit('delete')">Delete</button>
    </div>
  </div>
</template>

<script>
export default {
  props: [ "accept", "hide-delete" ]
}
</script>
