<template>
  <div class="investor-container">
    <div class="investor-box" id="header">
      <div id="investor-name">{{ investor.firstname }} {{ investor.lastname }}</div>
      <i v-if="roleState.roleChanging" class="fa fa-spinner fa-spin"></i>
      <i v-else-if="roleState.roleChanged" class="fa fa-check text-success mr-2"></i>
      <mainstage-investor-roles @input="changeRole($event)" :value="role" :success="roleState.roleChanged"/>
    </div>
    <div class="investor-column" id="investor-column-left">
      <div class="investor-box" id="profile">
        <div class="d-flex">
          <h4 class="w-50">Details</h4>
          <a :href="`/audience/${investor.id}/edit`" class="col-6 edit-link">Edit <i class="far fa-edit mr-2"></i></a>
        </div>
        <div class="container-fluid p-0">
          <dl class="row">
            <dt class="col-6">Type:</dt>
            <dd class="col-6">{{ $roleHash[investor.role].label }}</dd>
            <dt class="col-6">Email:</dt>
            <dd class="col-6">{{ investor.email }}</dd>
            <template v-if="investor.investor_company">
              <dt class="col-6">Company:</dt>
              <dd class="col-6">{{ investor.investor_company }}</dd>
            </template>
            <template v-if="investor.city || investor.state || investor.postal_code">
              <dt class="col-6">Address:</dt>
              <dd class="col-6">
                <div v-if="investor.address">{{ investor.address }}</div>
                <div v-if="investor.city || investor.state || investor.postal_code">
                  <span v-if="investor.city && investor.state">{{ investor.city }}, {{ investor.state }}</span>
                  <span v-else-if="investor.city">{{ investor.city }}</span>
                  <span v-else-if="investor.state">{{ investor.state }}</span>
                  <span v-if="investor.postal_code">{{ investor.postal_code }}</span>
                </div>
              </dd>
            </template>
            <template v-if="investor.phone">
              <dt class="col-6">Phone:</dt>
              <dd class="col-6">{{ investor.phone }}</dd>
            </template>
            <template v-if="investor.notes" class="row">
              <dt class="col-12">Notes:</dt>
              <dd class="col-12">
                <pre>{{ notes }}</pre>
              </dd>
            </template>
            <template v-if="investor.accepted_invite_at" class="row">
              <dt class="col-6">
                Accepted invite:
              </dt>
              <dd class="col-6">
                {{ investor.accepted_invite_at | formatDatetime }}
              </dd>
            </template>
            <template v-else-if="outstandingInvite" class="row">
              <dt class="col-6">
                Invited on:
              </dt>
              <dd class="col-6">
                {{ outstandingInvite.created_at | formatDatetime }}
              </dd>
              <dt class="col-6">
                Accepted invite:
              </dt>
              <dd class="col-6">
                <b>Not yet responded</b>
              </dd>
            </template>
          </dl>
          <div class="row text-muted" v-if="!investor.accepted_invite_at && !invitationType">
            <div class="col-12">
              <i v-if="role == 'backstage'">
                {{ investor.firstname }} will not have access to your story vault until they click on their invitation.
                They will, as a shareholder, receive company communications.
              </i>
              <i v-else>
                {{ investor.firstname }} will not have access to your story vault or
                receive company communications until they click on their
                invitation.
              </i>
            </div>
          </div>
          <form v-if="invitationType" action="/invitations" method="post" ref="inviteForm">
            <csrf-token/>
            <input type="hidden" name="invitation[invitation_type]" :value="invitationType"/>
            <input type="hidden" name="invitation[user_id]" :value="investor.id"/>

            <h4>Resend invitation:</h4>
            <div class="form-group">
              <label for="invitation[message]">Message:</label>
              <markdown-editor
              name="invitation[message]"
              id="invitation[message]"
              small=true
              disable-underline="1"
              v-model="invitationMessage"
              /></div>
              <div>
                <button class="btn btn-primary" @click.prevent="previewInvite">
                  Preview &amp; resend Invite
                </button>
                <button class="btn btn-secondary" @click.prevent="invitationType = null">Cancel</button>
              </div>
              <email-preview
                @send="$refs.inviteForm.submit()"
                subject=''
                :body='invitationMessage'
                ref='email-preview'
                :to-text="`to ${investor.firstname} ${investor.lastname}`"
                mail-type="resend_invitation_email"
                :to-email="investor.email"
                :firstname="investor.firstname"
                :lastname="investor.lastname"
                />
            </form>
            <div class="text-right">
              <a href="#" v-if="!investor.accepted_invite_at && !invitationType" @click.prevent="beginResend()" class="btn btn-primary mr-2">Reinvite</a>
              <a :href="`/audience/${investor.id}/edit`" class="btn btn-primary">Edit Details</a>
            </div>
        </div>
      </div>
      <div class="investor-box" id="investments">
        <h2>Investment</h2>
        <investor-edit-investments :investor-id="investor.id" :role="role" :investments="investments"/>
        <b-modal ref="downgradeWarning" title="Warning" @ok="doDowngrade" ok-variant="danger" title-class="text-danger" centered>
          Downgrading this user to <b>{{ $roleLabel(roleState.to) }}</b> will remove
          their investment of {{ invested | currency }}
        </b-modal>
      </div>
    </div>
    <div class="investor-column" id="investor-column-right">
      <!-- right hand bar -->
      <div class="investor-box" id="documents">
        <h2>Documents</h2>
        <investor-documents
          :documents="investor.documents"
          :investor="investor"
          as-admin="1"
          message="These documents are private to this user."
        />
      </div>
      <div class="investor-box" id="documents">
        <h2>Docusign</h2>
        <investor-docusign
          :investor="investor"
          as-admin="1"
        />
      </div>
      <div class="investor-box" id="communications">
        <investor-communications
        :investor-name="fullname"
        :sent-communications="investor.sent_communications"
        :received-communications="investor.received_communications"/>
      </div>
      <div v-if="investor.activity.length > 0" class="investor-box" id="activity">
        <h2>Recent Activity</h2>
        <ul class="list-group">
          <investor-session v-for="a in investor.activity" :session="a" :key="a.id"/>
        </uL>
      </div>
    </div>
  </div>
</template>
<style scoped>
pre {
  font-family: Roboto;
  margin-bottom: 0;
}

.investor-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0;
}


.investor-column {
  width: 100%;
}

@media (min-width: 768px) {
  #investor-column-left {
    width: 60%;
  }
  #investor-column-right {
    width: 40%;
  }
}

.investor-box {
  background: #fff;

}

.investor-box {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .investor-box {
    padding: 20px;
    margin: 10px;
    box-shadow: 1px 1px 1px #dedede;
    border: solid 1px #fef;
  }
}

#communications {
  padding-bottom: 5px;
}

.edit-link {
  margin-right: -15px;
}

#header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

#investor-name {
  font-size: 2rem;
  flex-grow: 1;
}

@media (min-width: 768px) {
  #investor-name {
    font-size: 3rem;
  }
}

h2 {
  font-size: 1.4rem;
}
</style>
<script>
import InvestorEditInvestments from 'investor_edit_investments.vue'
import InvestorCommunications from 'investor_communications.vue'
import InvestorDocuments from 'investor_documents.vue'
import InvestorDocusign from 'investor_docusign.vue'
import InvestorSession from 'investor_session.vue'
import MainstageInvestorRoles from 'components/mainstage_investor_roles.vue'
import EmailPreview from 'email_preview.vue'
import api from 'api/api'

export default {
  props: ["investor", "investments"],
  components: { InvestorEditInvestments, InvestorCommunications, InvestorSession, InvestorDocuments, InvestorDocusign, EmailPreview, MainstageInvestorRoles },
  data() {
    return {
      role: this.investor.role,
      roleState: { },
      invitationType: null,
      invitationMessage: "",
    }
  },
  methods: {
    async changeRole(to) {
      this.roleState = { }

      if ( to != 'backstage' && this.invested ) {
        this.roleState.to = to
        this.$refs.downgradeWarning.show()
        return
      }

      this.role = await api.changeRole(this.investor, to, this.roleState)
    },
    async doDowngrade() {
      this.role = await api.changeRole(this.investor, this.roleState.to, this.roleState)
      this.investments.each(i => {
        i.invested = null
        i.invested_on = null
      })
    },
    beginResend() {
      this.invitationType = 'resend_initial'
      let quotedMessage = this.outstandingInvite.message.split("\n").map(line => {
        if ( !line.startsWith(">") )
          return ">" + line
        else
          return line
      }).join("\n")
      this.invitationMessage = "&nbsp;\n" + quotedMessage
    },
    previewInvite() {
      this.$refs['email-preview'].getPreview()
    }
  },
  computed: {
    notes() {
      return this.investor.notes.replace(/^\s+|\s+$/g, '')
    },
    invested() {
      return this.investments.map(i => i.invested).reduce((a,b) => a + b)
    },
    fullname() {
      return this.investor.firstname + " " + this.investor.lastname
    },
    outstandingInvite() {
      let invitations = this.investor.invitations.slice()

      invitations = invitations.filter(i => i.invitation_type == 'initial')

      invitations.sort((a,b) => b.created_at.localeCompare(a.created_at))
      return invitations[0]
    }
  }
}
</script>
