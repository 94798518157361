<template>
  <div>
    <communications-modal
      :subject="modalComm.subject"
      :body="modalComm.body"
      :date="modalComm.created_at"
      :name="recipient"
      :direction="direction"
      :communication-type="modalComm.communication_type"
      ref="modal"
    />
    <h1>{{ title }}</h1>
    <vuetable
      ref="vuetable"
      class="investor-table mainstage-table"
      :css="tableCSS"
      :fields="fields"
      :show-sort-icons="true"
      :api-url="endpoint"
      :append-params="apiParams"
      data-path="communications"
      pagination-path="links.pagination"
      @vuetable:pagination-data="onPaginationData">

      <div slot="name-slot" slot-scope="props">
        <a v-if="inbound && !investorId">
          <a :href="`/audience/${props.rowData.from_user.id}`">{{ props.rowData.from_user.fullname }}</a>
        </a>
        <span v-else>{{ props.rowData.from_user.fullname }}</span>
      </div>
      <div slot="title-slot" slot-scope="props">
        <a href="#" @click.prevent="showComm(props.rowData)">{{ props.rowData.subject }}</a>
      </div>
      <div slot="sent-slot" slot-scope="props">
        {{ props.rowData.created_at | formatDatetime("PPP' @ 'p") }}
      </div>
    </vuetable>
    <div class="d-flex justify-content-end mt-2">
      <vuetable-bootstrap-pagination
        @vuetable-pagination:change-page="onChangePage"
        v-show="showPagination"
        ref="pagination" />
    </div>
  </vuetable>
  </div>
</template>

<script>
import CommunicationsModal from 'communications_modal.vue'

import Vuetable from 'vuetable-2'
import VuetableBootstrapPagination from 'components/vuetable_bootstrap_pagination.vue'

export default {
  data() {
    return {
      tableCSS: {
        tableWrapper: '',
        tableHeaderClass: 'mb-0',
        tableBodyClass: 'mb-0',
        tableClass: 'table table-hover',
        loadingClass: 'loading',
        ascendingIcon: 'fa fa-chevron-up purple',
        descendingIcon: 'fa fa-chevron-down purple',
        ascendingClass: 'sorted-asc',
        descendingClass: 'sorted-desc',
        sortableIcon: 'fa fa-sort',
        detailRowClass: 'vuetable-detail-row',
        handleIcon: 'fa fa-bars text-secondary',
        renderIcon(classes, options) {
          return `<i class="${classes.join(' ')}"></span>`
        }
      },
      fields: [
        {
          name: "name-slot",
          title: "From"
        },
        {
          name: "title-slot",
          title: "Title"
        },
        {
          name: "sent-slot",
          title: "Sent"
        }
      ],
      modalComm: {},
      showPagination: false
    }
  },
  props: [ "communications", "direction", "title", "investor-id" ],
  components: { CommunicationsModal, Vuetable, VuetableBootstrapPagination },
  methods: {
    showComm(comm) {
      this.modalComm = comm
      this.$refs.modal.show()
    },
    onPaginationData (paginationData) {
      this.showPagination = paginationData.lastPage > 1
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    }
  },
  computed: {
    apiParams() {
      if ( this.inbound )
        return { direction: 'inbound' }
      else
        return { direction: 'outbound' }
    },
    endpoint() {
      if ( this.investorId ) {
        return `/communications/user/${this.investorId}.json`
      } else {
        return '/communications.json'
      }
    },
    inbound() {
      return this.direction == 'from'
    },
    recipient() {
      if ( this.direction == 'to' )
        return "your entire audience"
      else if ( this.modalComm.from_user )
        return this.modalComm.from_user.fullname
      else
        return ""
    }
  }
}
</script>

