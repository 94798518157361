<template>
  <div id="investment-container">
    <div class="investor-dash-circle">
      <svg>
        <defs>
          <linearGradient id="gradient">
            <stop offset="0%" stop-color="#e3282f" />
            <stop offset="100%" stop-color="#002fa7" />
          </linearGradient>
        </defs>
        <circle stroke="black" stroke-width="2" fill="none" cx="80" cy="80" :r="innerRadius + strokeWidth"/>
        <circle stroke="black" id="investment-inner-circle" stroke-width="2" fill="black" cx="80" cy="80" :r="innerRadius"/>
        <path v-if="raised" class="total-investment" stroke-linecap="round" :stroke-width="strokeWidth" fill="none" :d="makeArc(raised / raise)"/>
        <path v-if="invested" class="my-investment" :stroke="myInvestmentStroke" stroke-linecap="round" :stroke-width="strokeWidth" fill="none" :d="makeArc(invested / raise)"/>
      </svg>
      <div id="percent" :class="{ oneHundred: ownershipPct == '100%' }">
        {{ ownershipPct }}
        <div id="percent-hover-target"></div>
      </div>
      <b-tooltip target="percent-hover-target" placement="bottom" title="My ownership of current round"></b-tooltip>
    </div>
    <div id="legend-container">
      <h4 id="round-amount">{{ roundName }}: {{ round.amount | currency }}</h4>
      <div v-if="invested" id="investment-amount" class="legend my-investment">
        My investment: {{ invested | currency }}
      </div>
      <div v-if="raised" id="completed-amount" class="legend total-investment">
        Completed: {{ raised | currency }}
      </div>
      <div id="outstanding-amount" class="legend">
        Outstanding: {{ outstanding | currency }}
      </div>
    </div>
  </div>
</template>
<style scoped>
.my-investment {
  background: #e3282f;
}

.total-investment {
  stroke: #002fa7;
  background: #002fa7;
}

#investment-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.legend {
  font-size: 16px;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  width: 100%;
}

#legend-container {
  width: 100%;
}

h4 {
  font-size: 20px;
}
#investment-amount {
  color: #fff;
}

#completed-amount {
  color: #fff;
}

#outstanding-amount {
  background: #eee;
}

#round-amount {
  width: 200px;
}

.investor-dash-circle {
  position: relative;
}

@media (min-width: 576px) {
  #legend-container {
    width: auto;
  }

  .investor-dash-circle {
    margin-right: 10px;
  }

  .legend {
    font-size: 18px;
  }
}


.investor-dash-circle svg {
  width: 160px;
  height: 160px;
  z-index: 1000;
}

#percent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 700;
  font-size: 40px;
  transition: 0.5s;
  color: #fff;
}

#percent-hover-target {
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

#percent.oneHundred {
  font-size: 35px;
}

</style>
<script>
export default {
  props: [ "investment", "round", "company-name" ],
  data() {
    return {
      innerRadius: 50,
      strokeWidth: 25,
      raise: this.round.amount,
      raised: this.round.total_invested,
      invested: this.investment ? this.investment.invested : 0
    }
  },
  methods: {
    xyForPct(pct) {
      if ( pct >= 1 ) {
        pct = 0.99
      }
      let degrees = pct * 360 - 90.0
      let radians = (Math.PI * degrees) / 180.0

      let radius = this.innerRadius + (this.strokeWidth / 2)

      return {
        x: (Math.cos(radians) * radius)  + 80,
        y: (Math.sin(radians) * radius)  + 80,
        flag: degrees > 90 ? '1' : '0'
      }
    },
    makeArc(pct) {
      let i = this.xyForPct(pct)
      let r = this.innerRadius + (this.strokeWidth / 2)
      return `M 80 ${80 - r} A ${r} ${r} 0 ${i.flag} 1 ${i.x} ${i.y}`
    },
  },
  computed: {
    roundName() {
      if ( this.round.funding_stage )
        return this.round.funding_stage
      else
        return "Current round"
    },
    myInvestmentStroke() {
      if ( this.ownershipPct == '100%' )
        return "url(#gradient)"
      else
        return "#e3282f"
    },
    outstanding() {
      return this.raise - this.raised
    },
    ownershipPct() {
      if ( !this.raised  )
        return ""

      let pct = Math.min((this.invested / this.raised) * 100, 100)
      return parseInt(pct) + '%'
    }
  }
}
</script>
