<template>
  <div>
    <div v-if="inviteCompany">
      <h1>Inviting {{ inviteCompany.name }} into {{ agency.name }}</h1>
      <p>
        When {{ inviteCompany.owner.firstname }} {{ inviteCompany.owner.lastname }} accepts this invitation,
        you will be able to view information about the company.  This includes website activity and investor/investment information.
        You will also be granted read-only access to their company profile.
      </p>
      <form method="post" action="/invitations">
        <csrf-token/>
        <input type="hidden" name="invitation[user_id]" :value="inviteCompany.owner.id"/>
        <input type="hidden" name="invitation[invitation_type]" value="agency"/>
        <div class="form-group">
          <label for="invitation[message]">Message</label>
          <textarea class="form-control" id="invitation[message]" name="invitation[message]" rows=10
          placeholder="your message here...">Hi {{ inviteCompany.owner.firstname}},
please accept this invitation to share information about {{ inviteCompany.name }} with {{ agency.name }}.
        </textarea>
        </div>
        <input type="submit" class="btn btn-primary" value="Send Invitation"/>
      </form>
    </div>
    <div v-else>
      <h1>Invite a company into {{ agency.name }}</h1>
      <div>
        Lookup company by owner's email address:
      </div>
      <div class="input-group email-lookup">
        <input type='text' class="form-control" @keyup.enter="runSearch" placeholder='owner@example.com' v-model="email"/>
        <div class="input-group-append">
          <button class="btn btn-primary" type="button" @click="runSearch">Search</button>
        </div>
      </div>

      <div class="mt-3" v-if="companies != null">
        <div v-if="companies.length == 0">
          No results found.
        </div>
        <table class="mainstage-table invite-results" v-else>
          <tr v-for="company in companies">
            <td class="mr-2">{{ company.name }}</td>
            <td class="mr-2">{{ company.owner.firstname }} {{ company.owner.lastname }} ({{ company.owner.email }})</td>
            <td class="mr-2">
              <i v-if="company.agency_id == agency.id">Already joined</i>
              <button v-else class="btn btn-primary" @click="inviteCompany = company">Invite this company</button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
.email-lookup {
  max-width: 600px;
}
.invite-results td {
  font-size: 100%;
}
</style>

<script>
import axios from 'axios'

export default {
  props: ["agency"],
  data() {
    return {
      email: '',
      inviteCompany: null,
      companies: null
    }
  },

  methods: {
    runSearch() {
      axios("/admin/search.json?email=" + this.email).then(response => {
        this.companies = response.data
      })
    }
  }
}
</script>
