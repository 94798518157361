<template>
  <div v-if="communications.length > 0">
    <communications-modal
      :subject="modalComm.subject"
      :body="modalComm.body"
      :date="modalComm.created_at"
      :name="investorName"
      :direction="modalComm.direction"
      :communication-type="modalComm.communication_type"
      ref="modal"
    />
    <h4>Communications</h4>
    <div v-for="(comm, i) in communications" class="comm">
      <div class="subject">
        <a href="#" @click.prevent="showComm(i)">{{ comm.subject }}</a>
      </div>
      <div class="d-flex justify-content-between">
        <div class="direction">
          <span v-if="comm.direction == 'from'" class="text-success">
            Received from
          </span>
          <span v-else class="text-secondary">
            Sent to
          </span>
          {{ firstname }}
        </div>
        <div class="time">
          <b>{{ comm.created_at | formatDatetime }}</b>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.subject {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comm {
  margin-bottom: 15px;
}
/* at small breakpoints the text is not truncating properly.  I'm not... entirely sure why. */
@media (max-width: 500px) {
  .subject {
    max-width: 400px;
  }
}

@media (max-width: 450px) {
  .subject {
    max-width: 350px;
  }
}

@media (max-width: 380px) {
  .subject {
    max-width: 280px;
  }
}

</style>

<script>

import CommunicationsModal from 'communications_modal.vue'

export default {
  props: [ "investor-name", "sent-communications", "received-communications" ],
  components: { CommunicationsModal },
  data() {
    return {
      modalComm: {}
    }
  },
  methods: {
    showComm(idx) {
      this.modalComm = this.communications[idx]
      this.$refs.modal.show()
    }
  },
  computed: {
    firstname() {
      return this.investorName.split(" ")[0]
    },
    communications() {
      let sent = this.sentCommunications.map(c => Object.assign({ direction: 'from'}, c))
      let received = this.receivedCommunications.map(c => Object.assign({ direction: 'to'}, c))

      return sent.concat(received).sort((a,b) => b.id - a.id)
    }
  }
}
</script>
