<template>
  <div>
    <div v-if="expanded" v-for="item in sortedItems">
      <div class="grid-list" v-if="item.type == 'folder'">
        <a href="#" class="document-folder" @click.prevent="toggleExpand(item.name)"><i class="fas fa-folder mr-2"/>{{ item.name }}</a>
        <span class="text-right float-right document-links">
          <a href="#" @click.prevent="toggleExpand(item.name)"><i class="fas mr-2" :class="faClass(item)"/></a>
        </span>
        <documents-folder :folder="item" :expanded="expandState[item.name]" :is-example="isExample"/>
      </div>
      <Document v-else :key="item.id" :doc="item" :is-example="isExample"/>
    </div>
  </div>
</template>
<style scoped>
.document-folder {
  color: #333;
}
.document-folder i {
  color: #3c96f7;
}
</style>
<script>
import Document from 'document.vue'

export default {
  name: "documents-folder",
  data() {
    let expandState = {}
    this.folder.items.forEach(i => {
      if ( i.type == "folder" )
        expandState[i.name] = i.expanded
    })

    return {
      expandState: expandState
    }
  },
  watch: {
    // if we are a folder with one element, recursively expand until we hit some docs
    expanded() {
      if ( !this.expanded ) return
      if ( this.sortedItems.length == 1 && this.sortedItems[0].type == "folder" ) {
        this.setExpand(this.sortedItems[0].name, true)
      }
    }
  },
  computed: {
    sortedItems() {
      let items = this.folder.items.slice()
      return items.sort((a, b) => {
        if ( a.type == b.type ) {
          if ( a.name < b.name )
            return -1
          else if ( b.name > a.name )
            return 1
          else return 0
        } else if ( b.type == 'folder' ) {
          return -1
        } else {
          return 1
        }
      })
    }
  },
  methods: {
    faClass(item) {
      if ( this.expandState[item.name] )
        return {"fa-minus": true}
      else
        return {"fa-plus": true}
    },
    setExpand(name, value) {
      this.$set(this.expandState, name, value)
    },
    toggleExpand(name) {
      this.setExpand(name, !this.expandState[name])
    }
  },
  props: [ "folder", "expanded", "is-example" ],
  components: { Document }
}
</script>
