<template>
  <div>
    <div v-if="loading" class="text-center p-3"><i class="fa fa-spinner fa-spin"></i></div> 
    <div v-else-if="investorEnvelopes && investorEnvelopes.length > 0">
      <table class="w-100">
      <tr v-for="env in investorEnvelopes" class="document" :key="env.id">
        <td>
          <a class="document-link" 
             :href="envelopeLink(env)" 
             :target="envelopeLinkTarget(env)">{{env.emailSubject || "(no title)"}}</a> 
        </td>
        <td style="width: 33%;"><i :title="statusField(env, true)" 
          class="fa fa-icon fa-fw mr-1" :class="statusClass(env)"></i>{{statusField(env)}}</td>
      </tr>
      </table>
    </div>
    <div v-else-if="asAdmin && !connected">
      Send signing requests to your audience and keep track
      the signed documents on the investor dashboard.
      <div class="mt-2 text-right">
        <a :href = "connectURL" class="btn btn-primary">Connect your Docusign account</a>
      </div>
    </div>
    <div v-if="connected && asAdmin && !loading">
      <div class="mt-2"><a href="#" @click.prevent="openSigningModal"><i class="fa fa-signature"/>
        Sending signing request
      </a></div>
      <document-docusign ref="docusignModal" :investor-id="investor.id" @input="mergeDocs($event)"/>
    </div>
  </div>
</template>

<style scoped>
.document td {
  padding-top: 10px;
  padding-bottom: 10px;
  vertical-align: top;
  border-bottom: 1px solid #eee;
}
</style>
<script>

import DocumentDocusign from 'document_docusign.vue'
import axios from 'axios'

import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

export default {
  props: [ "investor", "message", "as-admin", "envelopes" ],
  data() {
    return { loading: true, connected: false, investorEnvelopes: [], templates: [] }
  },
  components: { DocumentDocusign },
  methods: {
    async loadState() {
      if ( this.envelopes ) {
        this.connected = true
        this.investorEnvelopes = this.envelopes
        this.loading = false
        console.log(this.envelopes)
        return
      }

      const result = await axios.get("/integrations/docusign", { params: { user_id: this.investor.id } })
      this.connected = result.data.status == "connected"
      this.investorEnvelopes = result.data.envelopes
      this.templates = result.data.templates
      this.loading = false
    },
    statusClass(env) { 
      if ( env.status == "sent" ) { 
        return "fa-signature"
      } else if ( env.status == "completed" ) {
        return "fa-check"
      }
    },
    statusField(env, includeStatusText) {
      let date
      let status

      if ( !env.sentDateTime ) {
        return "(Draft)"
      }

      if ( env.status == "completed" ) {
        date = env.completedDateTime
        status = "Signed"
      } else {
        date = env.sentDateTime
        if ( env.status == "sent" ) { 
          status = "Sent"
        } 
      }
      const parsed = parseISO(date) 
      const formatted = format(parsed, "yyyy-MM-dd")
      return `${includeStatusText ? status : ''} ${formatted}`
    },
    envelopeLinkTarget(env) {
      if ( env.status == "sent" && !this.asAdmin )  {
        // signing workflow, replace current window and it redirects back.
        return ""
      } else {
        return "_blank"
      }
    },
    envelopeLink(env) { 
      if ( this.asAdmin ) {
        return `/integrations/docusign/admin_envelope/${env.envelopeId}`
      } else { 
        return `/integrations/docusign/investor_envelope/${env.envelopeId}?return_to=${window.location.href}`
      }
    },
    openSigningModal() {
      this.$refs.docusignModal.show(this.templates, this.investor)
    }
  },
  computed: { 
    connectURL() {
      return "/auth/docusign/request?return_to=" + window.location
    }
  },
  mounted() { 
    this.loadState()
  }
}
</script>
