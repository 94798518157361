<template>
  <b-modal body-class="m-2" size="lg" ok-title="Send" @ok="$emit('send')" id="preview-modal" title="Email Preview" centered>
    Here's an example of how your email
    <b v-if="toText">{{ toText }}</b>
    will appear:
    <div v-html="previewHTML" class="email-preview"></div>
    <div class="signatureText">You can change your email signature by <a :href="myProfileLink" target="_blank">editing your profile</a></div>
  </b-modal>
</template>
<style>
.email-preview-header {
  color: #555;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px #ddd solid;
}

.email-preview {
  background: #eee;
  padding: 10px;
  border-radius: 10px;
}
</style>
<style scoped>
.signatureText {
  font-size: 0.8rem;
  font-style: italic;
  text-align: right;
}
</style>
<script>

import axios from 'axios'

export default {
  data() {
    return {
      previewHTML: ''
    }
  },
  props: ['subject', 'body', 'to-email', 'firstname', 'lastname', 'to-text', 'mail-type', 'email-from'],
  methods: {
    async getPreview() {
      let csrfToken = $('meta[name="csrf-token"]').attr("content")
      let preview = await axios.post('/communications/preview', {
        subject: this.subject,
        body: this.body,
        to_email: this.toEmail,
        firstname: this.firstname,
        lastname: this.lastname,
        mail_type: this.mailType,
        email_from: this.emailFrom,
        authenticity_token: csrfToken
      })
      this.previewHTML = preview.data
      this.$bvModal.show("preview-modal")
    }
  },
  computed: {
    myProfileLink() {
      return `/users/${this.$globals.currentUser.id}/edit`
    }
  }
}
</script>
