<template>
  <div class="document-row">
    <div class="position-relative">
      <deleted-overlay v-if="editDocument.deleted" @undelete="editDocument.deleted = false" class="delete-overlay" msg="Document will be deleted upon save"/>
      <div class="row">
        <input v-if="editDocument.deleted" type="hidden" :name="$fieldName('deleted')" :value="editDocument.deleted"/>

        <input v-if="upload_id" type="hidden" :name="$fieldName('upload_id')" :value="upload_id"/>

        <input v-if="documentType" type="hidden" :name="$fieldName('document_type')" :value="documentType"/>
        <div v-else class="form-group col-12 col-lg-5">
          <ValidationProvider :vid="makeValidationID('document_type')"
            name="Document Type" mode="passive" rules="required" v-slot="{ errors, classes }">
            <label class="mainstage-form-label">Document Type</label>
            <select class="form-control animated-error"
              :class="classes" :name="$fieldName('document_type')" v-model="editDocument.document_type" :disabled="editDocument.deleted">
              <option :value="null">Choose a document type...</option>
              <option value="other">Other</option>
              <option v-for="type in documentTypes" :value="type.key">{{ type.title }}</option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </div>

        <div class="form-group overflow-hidden col-12 col-lg-4" :class="{'col-lg-9': documentType}">
          <label class="d-block">File</label>
          <a target="_new" :href="url">{{ filename }}</a>
        </div>
        <document-edit-row-buttons
          :accept="accept"
          :hide-delete="hideDelete"
          @change="replaceFile"
          @delete="editDocument.deleted = true"
          class="form-group d-none d-lg-block col-12 col-lg-3 p-3 p-lg-0"/>
      </div>
      <div v-if="!documentType" class="row">
        <div class="form-group col-12 col-md-6 col-lg-4">
          <label class="mainstage-form-label">Available To</label>
          <select class="form-control" :name="$fieldName('required_role')" v-model="editDocument.required_role">
            <option value=10>Everyone</option>
            <option value=20>VIPs</option>
            <option value=30>Shareholders</option>
          </select>
        </div>
        <div v-if="editDocument.document_type == 'other'" class="form-group col-12 col-md-6 col-lg-5 offset-lg-1" :disabled="editDocument.deleted">
          <ValidationProvider :vid="makeValidationID('title')" name="Document Title" rules="required" v-slot="{ errors, classes }">
            <label class="mainstage-form-label">Document Title</label>
            <input
              class="form-control"
              :class="classes"
              type="text"
              :name="$fieldName('title')"
              v-model="editDocument.title"
              placeholder="Document title..."
              :disabled="editDocument.deleted"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </div>
      </div>
      <document-edit-row-folder
        v-if="!documentType"
        v-model="editDocument.folder"
        :fieldName="$fieldName('folder')"
        :folders="folders"
      />
      <!-- mobile buttons -->
      <div class="row">
        <document-edit-row-buttons
          :accept="accept"
          :hide-delete="hideDelete"
          @change="replaceFile"
          @delete="editDocument.deleted = true"
          class="form-group d-lg-none col-12"/>
      </div>
    </div>
  </div>
</template>
<style scoped>
.document-row {
  padding: 10px;
  margin-bottom: 20px;
  border: #ddd 1px solid;
  border-radius: 10px;
  background: #fbfbfb;
}
.delete-overlay {
  margin: -10px;
  margin-bottom: -20px;
}
.no-folder {
  color: #999;
}
</style>
<script>

import UploadWithProgress from 'mixins/upload_with_progress.js'
import DocumentEditRowButtons from 'document_edit_row_buttons.vue'
import DocumentEditRowFolder from 'document_edit_row_folder.vue'

export default {
  components: { DocumentEditRowButtons, DocumentEditRowFolder },
  props: [ "document", "progress", "document-type", "accept", "folders", "hide-delete" ],
  mixins: [ UploadWithProgress ],
  data() {
    return {
      editDocument: {... {upload_id: null, deleted: false}, ... this.document }
    }
  },
  computed: {
    id() { return this.document.id },
    upload_id() { return this.editDocument.upload_id || this.document.upload_id },
    filename() { return this.editDocument.filename || this.document.filename },
    documentTypes() {
      return this.$globals.documentTypes.filter(t => t.key != 'investor_pitch_deck' && !t.investor_exclusive)
    },
    url() {
      if ( this.upload_id ) {
        return `/upload/${this.upload_id}`
      } else {
        return `/documents/${this.document.id}`
      }

    },
    formPrefix() { return `documents[${this.id}]` }
  },
  watch: {
    editDocument: {
      deep: true,
      handler() {
        this.$emit('input', this.editDocument)
      }
    }
  },
  methods: {
    makeValidationID(field) {
      return `#documents.${this.$fieldName(field)}`
    },
    replaceFile(ev) {
      let file = ev.target.files[0]
      if ( file ) {
        this.editDocument.filename = file.name
        this.uploadWithProgress(this.editDocument, file)
      }
    }
  }
}
</script>
