import Vue from 'vue'

let GlobalsPlugin = {
  install(Vue, options) {
    Object.defineProperty(Vue.prototype, '$globals', {
      get () {
        if ( window.$vueGlobals )
          return window.$vueGlobals

        window.$vueGlobals = $("#vue-globals").data()
        return window.$vueGlobals
      }
    })
    Object.defineProperty(Vue.prototype, '$roleHash', {
      get() {
        let hash = {}
        this.$globals.roles.forEach(r => hash[r.key] = r )
        return hash
      }
    })
    Object.defineProperty(Vue.prototype, '$roleLabel', {
      value: function(role) {
        if ( this.$roleHash[role] )
          return this.$roleHash[role].label
        else
          return ''
      }
    })
    Object.defineProperty(Vue.prototype, '$csrfToken', {
        get() {
          return $('meta[name=csrf-token]').attr('content')
        }
    })
  }
}

Vue.use(GlobalsPlugin)

