<template>
  <div class="form-group">
    <label class="mainstage-form-label" :class="labelClass" :for="$fieldName(field)">{{ label }}</label>
    <mainstage-input v-bind="$props" @input="$emit('input', $event)" @blur="$emit('blur', $event)" @focus="$emit('focus', $event)"/>
    <small class="annotation" v-if="annotation">{{ annotation }}</small>
    <slot></slot>
  </div>
</template>
<style scoped>
.annotation {
  color: #888;
}
</style>
<script>
  import MainstageInput from 'components/mainstage_input.vue'
  export default {
    props: [ "label", "labelClass", "inputClass", "prefix", "field", "type", "size", "placeholder", "value", "errors", "annotation", "data-lpignore", "maxlength" ],
    data() {
      return {
        formPrefix: this.prefix
      }
    },
    components: { MainstageInput }
  }
</script>
