<template>
  <div class="offwhite">
    <div class="position-relative">
      <div v-if="company.banner_url" class="container-fluid banner-image d-none d-md-block" :style="backgroundImageStyle"></div>
      <div class="container-fluid mb-3 px-0 py-2 company-profile-header" :class="{ 'company-name-overlay': company.banner_url }">
        <div class="company-profile-row row">
          <div class="col d-flex flex-column justify-content-center">
            <div class="company-header-text">
              <h1 class="company-profile-name"> {{ company.name }} </h1>
            </div>
          </div>
          <div class="d-none d-lg-block col-lg-auto text-right">
            <img :src="logoURL" class="company-profile-logo"/>
          </div>
        </div>
      </div>
    </div>
    <div class="company-profile-row mission-statement mb-md-4">
      <h4>{{ company.mission_statement }}</h4>
    </div>
    <company-goal-bar :hide-bar="company.active_round.hide_goal" :round="company.active_round" :company="company" class="my-3 company-profile-row"/>
    <div class="company-profile-row">
      <HashTabs
        class="mx-md-3"
        @input="changeTab($event)"
        nav-class="mainstage-company-tabs"
        :incoming-tab="editTab"
        content-class="mainstage-company-tab-content-wrapper py-md-3 p-sm-4">
        <b-tab title="Our Story" id="story" :title-item-class="{ active: tabIsActive('#story') }">
          <Pitch :company="company"/>
        </b-tab>
        <b-tab lazy v-if="showTeam" title="Team" id="team" :title-item-class="{ active: tabIsActive('#team') }">
          <company-profile-headline headline="Team" edit-text="Edit team" edit-link="/edit#team" edit-role="admin"/>
          <Team :members="company.team_members" class="red-dot"/>
        </b-tab>
        <b-tab v-if="showDealTab" title="Deal Info" id="deal" class="offwhite" :title-item-class="{ active: tabIsActive('#deal') }">
          <div class="py-2 py-sm-0 px-2 px-sm-0">
            <div v-if="showOffering">
              <company-profile-headline headline="Offering" edit-text="Edit offering" edit-link="/edit#deal" edit-role="admin"/>
              <DealInfo :round="company.active_round" class="mb-4 red-dot"/>
            </div>
            <div v-if="company.lead_investors && company.lead_investors.length > 0" class="mb-4">
              <h4>Lead Investors</h4>
              <div class="red-dot">
                <LeadInvestor v-for="investor in company.lead_investors" :key="investor.id" :investor="investor"/>
              </div>
            </div>
            <div v-if="showDocuments">
              <company-profile-headline headline="Documents" edit-text="Upload documents" edit-link="/edit#documents" edit-role="admin"/>
              <documents :documents="documents" class="red-dot"/>
            </div>
          </div>
        </b-tab>
        <b-tab v-if="showTimeline" title="Timeline" id="timeline" class="offwhite" :title-item-class="{ active: tabIsActive('#timeline') }">
          <div class="py-2 py-sm-0 px-2 px-sm-0">
            <Traction :traction_events="company.traction_events"/>
          </div>
        </b-tab>
        <b-tab lazy v-if="showMedia" :title="mediaName" id="media" class="offwhite" :title-item-class="{ active: tabIsActive('#media') }">
          <div class="py-2 py-sm-0 px-2 px-sm-0">
            <company-profile-headline v-if="company.reg_cf" headline="Press" edit-text="Edit press" edit-link="/edit#media" edit-role="admin"/>
            <company-profile-headline v-else="company.reg_cf" headline="More Media" edit-text="Edit media" edit-link="/edit#media" edit-role="admin"/>
            <MoreMedia :media="company.media"/>
          </div>
        </b-tab>
        <b-tab alias="invest_now" title="Contact" id="contact" class="offwhite" 
          :title-item-class="{ active: tabIsActive('#contact') || tabIsActive('#invest_now') || tabIsActive('#links'), 'red-tab': true }"
        >
          <div class="px-2 px-sm-0 mb-2">
            <Contact
              :company="company"
              :communication="communication"
              :invest-now="tabIsActive('#invest_now')"
              :can-use-ira="company.active_round && company.active_round.accepts_ira"
            />
          </div>
        </b-tab>
      </HashTabs>
      <div v-if="company.show_redcrow_disclaimer">
      </div>
    </div>
    <DemoFloater v-if="company.subdomain == 'musicandmedicine' || company.subdomain == 'demosen-jampharmaceutical'"/>
  </div>
</template>
<style scoped>
.mission-statement {
  padding-left: 15px;
  margin-top: 1.5rem !important;
}

.mission-statement h4 {
  color: #000;
  font-weight: normal;
}

.company-profile-row {
  max-width: 1170px;
  margin: auto;
}

.company-profile-logo {
  max-height: 120px;
  max-width: 400px;
}

.company-profile-header {
  background-color: #fff;
  color: #333;
}

.company-profile-name {
  color: #000000;
  font-weight: 500 !important;
  font-family: "Big Shoulders";
  font-size: 80px;
  margin: 0;
}

@media (max-width: 400px) {
  .company-profile-name {
    color: #000000;
    font-weight: 500 !important;
    font-family: "Big Shoulders";
    font-size: 48px;
  }
}

.company-profile-tagline {
  color: #000000;
  font-family: "Big Shoulders";
  font-weight: 300;
  font-size: 32px;
  letter-spacing: 0.3px;
}

.banner-image {
  background-size: cover;
  height: 500px;
}


@media (min-width: 768px) {
  .company-name-overlay {
    position: absolute;
    bottom: 0px;
    opacity: 0.85;
    margin-bottom: 0px !important;
  }
}
</style>
<style lang="scss">
@import 'vars';

.mainstage-company-tabs {
  padding: 0px 0px;
  border-bottom: 1px solid #979797 !important;
}

.mainstage-company-tabs a {
  font-size: 18px;
  color: #000000;
  border: none !important;
  outline: none !important;
  padding: 0px !important;
}

.mainstage-company-tabs li {
  padding: 2px 8px;
}

@media (min-width: 768px) {
  .mainstage-company-tabs li {
    padding: 2px 20px;
  }
}

@media (min-width: 768px) {
  .mainstage-company-tabs li {
    padding: 2px 16px;
  }

  .mainstage-company-tabs a {
    font-size: 25px;
  }
}

@media (min-width: 992px) {
  .mainstage-company-tabs li {
    padding: 2px 30px;
  }
}

.mainstage-company-tabs li.active {
  border-bottom: 5px solid #3c444d;
}

.mainstage-company-tabs li.active a {
}

.mainstage-company-tab-content-wrapper {
  border-bottom: 1px solid #979797 !important;
}

@media (min-width: 768px) {
  .mainstage-company-tab-content-wrapper {
    border-left: 1px solid #979797;
    border-right: 1px solid #979797;
    margin-bottom: 20px;
  }
}

.red-tab.active {
    border-bottom: 5px solid #BD1028 !important;
}

.red-tab a {
  color: #BD1028 !important;
}
</style>
<script>
import Team from 'team.vue'
import CompanyGoalBar from 'company_goal_bar.vue'
import Pitch from 'pitch.vue'
import DealInfo from 'deal_info.vue'
import Documents from 'documents.vue'
import HashTabs from 'components/hash_tabs.vue'
import Traction from 'traction.vue'
import LeadInvestor from 'lead_investor.vue'
import CompanyLinks from 'company_links.vue'
import Contact from "contact.vue"
import MoreMedia from "more_media.vue"
import ExampleData from 'mixins/example_data.js'
import CompanyProfileHeadline from 'components/company_profile_headline.vue'
import Tracking from 'api/tracking.js'
import DemoFloater from 'demo_floater.vue'
import CompanyRedcrowDisclaimer from 'company_redcrow_disclaimer.vue'

export default {
  props: ['company', 'editTab', 'communication'],
  mixins: [ ExampleData ],
  data() {
    return {
      activeTab: null
    }
  },
  computed: {
    documents() {
      if ( !this.company.documents )
        return []

      return this.company.documents.filter(d => !d.deleted && d.document_type != 'investor_pitch_deck')
    },
    backgroundImageStyle() {
      if ( this.company.banner_url ) {
        return { 'background-image': `url("${this.company.banner_url}")` }
      }
    },
    logoURL() {
      return this.company.logo_url || '/images/rocket.svg'
    },
    showTeam() {
      return this.showExamples || this.company.team_members && this.company.team_members.length > 0
    },
    showOffering() {
      return this.showExamples || (this.company.active_round && !this.company.active_round.show_example)
    },
    showDocuments() {
      return this.showExamples || this.documents.length > 0
    },
    showDealTab() {
      return this.showOffering || this.showDocuments
    },
    showTimeline() {
      return ( this.showExamples || this.company.traction_events && this.company.traction_events.length > 0 )
    },
    showMedia() {
      return ( this.showExamples || this.company.media && this.company.media.length > 0 )
    },
    mediaName() { 
      return this.company.reg_cf ? "Press" : "Media"
    }
  },
  methods: {
    changeTab(tab) {
      if ( tab && this.activeTab != tab)
        Tracking.tabChange(tab)
      this.activeTab = tab
    },
    tabIsActive(tab) {
      return this.activeTab == tab || (tab == '#story' && !this.activeTab)
    }
  },
  components: { CompanyGoalBar, Team, DealInfo, Pitch, HashTabs, Documents, LeadInvestor, CompanyLinks, Traction, Contact, MoreMedia, CompanyProfileHeadline, DemoFloater, CompanyRedcrowDisclaimer }
}
</script>


