<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="d-flex">
          <h3 class="flex-grow-1">Edit details for {{ fullname }}</h3>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <div class="form-row">
        <mainstage-text
          class="col-12 col-lg-4"
          prefix="investor"
          label="Email address"
          field="email"
          type="email"
          v-model="editInvestor.email"
          :errors="investor.errors"
        />
          <mainstage-text
            class="col-12 col-lg-4"
            prefix="investor"
            label="First Name"
            field="firstname"
            v-model="editInvestor.firstname"
            :errors="investor.errors"
          />
          <mainstage-text
            class="col-12 col-lg-4"
            prefix="investor"
            label="Last Name"
            field="lastname"
            v-model="editInvestor.lastname"
            :errors="investor.errors"
          />
        </div>
        <div class="form-row">
        <mainstage-text
          class="col-12"
          prefix="investor"
          label="Company"
          field="investor_company"
          v-model="editInvestor.investor_company"
          :errors="investor.errors"
        />
        </div>
        <div class="mb-2">
          <div class="form-check">
            <input
              type="checkbox"
              name="investor[lead_investor]"
              id="investor[lead_investor]"
              v-model="editInvestor.lead_investor"
              class="form-check-input">
              <input v-if="!editInvestor.lead_investor" type="hidden" name="investor[lead_investor]" value="false"/>
            <label class="form-check-label" for="investor[lead_investor]">Lead Investor?</label>
          </div>
          <small class="text-muted">Lead investors appear on your Deal Info page</small>
        </div>
        <mainstage-text
          prefix="investor"
          label="LinkedIn URL"
          field="linkedin"
          placeholder="https://www.linkedin.com/in/jerrygharrison/"
          v-model="editInvestor.linkedin"
          :errors="investor.errors"
        />
        <div class="form-row">
          <mainstage-text
            prefix="investor"
            class="col-12"
            label="Address"
            field="address"
            placeholder="123 Main Street"
            v-model="editInvestor.address"
            :errors="investor.errors"
          />
        </div>
        <div class="form-row">
          <mainstage-text
            prefix="investor"
            class="col-12 col-lg-6"
            label="City"
            field="city"
            v-model="editInvestor.city"
            :errors="investor.errors"
          />
          <div class="form-group col-12 col-lg-auto px-lg-4">
            <label class="required" for="investor[state]">State</label>
            <select class="form-control"
              v-model="editInvestor.state"
              name="investor[state]">
              <option v-for="s in $globals.usStates" :value="s[1]">{{ s[0] }}</option>
            </select>
          </div>
          <mainstage-text
            class="col-12 col-lg"
            prefix="investor"
            label="Zip"
            field="postal_code"
            v-model="editInvestor.postal_code"
            :errors="investor.errors"
          />
        </div>
        <div class="form-row">
          <mainstage-text
            class="col-12 col-lg-6"
            prefix="investor"
            label="Phone"
            field="phone"
            v-model="editInvestor.phone"
            :errors="investor.errors"
          />
        </div>
        <div class="form-group">
          <label class="required" for="investor[notes]">Notes</label>
          <textarea class="form-control" name="investor[notes]" v-model="editInvestor.notes" rows=10 cols=80></textarea>
        </div>
        <div class="d-flex align-items-center">
          <button class="btn btn-primary">Save</button>
          <a v-if="investor.role != 'removed'" class="text-danger flex-grow-1 text-right" :href="`/audience/${investor.id}/remove`">Remove User</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MainstageInvestorRoles from 'components/mainstage_investor_roles.vue'
  import InvestorEditInvestments from 'investor_edit_investments.vue'
  import InvestorCommunications from 'investor_communications.vue'
  import InvestorDocuments from 'investor_documents.vue'
  import InvestorActivity from 'investor_activity.vue'
  import EmailPreview from 'email_preview.vue'

  export default {
    props: ["investor", "investment"],
    components: { InvestorEditInvestments, InvestorCommunications, InvestorActivity, InvestorDocuments, EmailPreview, MainstageInvestorRoles },
    data() {
      return {
        invitationType: null,
        invitationMessage: "",
        editInvestor: Object.assign({}, this.investor)
      }
    },
    methods: {
      beginResend() {
        this.invitationType = 'resend_initial'
        let quotedMessage = this.outstandingInvite.message.split("\n").map(line => {
          if ( !line.startsWith(">") )
            return ">" + line
          else
            return line
        }).join("\n")
        this.invitationMessage = "&nbsp;\n" + quotedMessage
      },
      previewInvite() {
        this.$refs['email-preview'].getPreview()
      }
    },
    computed: {
      fullname() {
        return this.editInvestor.firstname + " " + this.editInvestor.lastname
      },
      outstandingInvite() {
        let invitations = this.editInvestor.invitations.slice()

        invitations = invitations.filter(i => i.invitation_type == 'initial')

        invitations.sort((a,b) => b.created_at.localeCompare(a.created_at))
        return invitations[0]
      }
    }
  }
</script>
