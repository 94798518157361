<template>
  <b-tabs @activate-tab="tabChanged" ref="tab" v-model="activeTab"
    @changed="navChanged"
    :nav-class="navClass"
    :content-class="contentClass">
    <slot></slot>
  </b-tabs>
</template>

<script>
export default {
  props: [ 'nav-class', 'content-class', 'incoming-tab' ],
  data() {
    return {
      activeTab: 0
    }
  },
  mounted() {
    $(window).on('hashchange', () => this.navChanged())
    this.navChanged()
  },
  methods: {
    activateByHash(hash) {
      let tabs = this.$refs.tab.tabs
      for (let i = 0; i < tabs.length; i++ ) {
        let tabID = tabs[i].id
        let tabAlias = tabs[i].$attrs.alias

        if ( hash == "#" + tabID || tabAlias && hash == "#" + tabAlias )
          this.activeTab = i
      }
    },
    navChanged() {
      let href = window.location.hash
      if ( !href ) {
        this.activeTab = 0
      } else {
        this.activateByHash(href)
      }

      this.$emit("input", href)
    },
    tabChanged(index) {
      let tab = this.$refs.tab.tabs[index]
      let hash = window.location.hash
      let href = "#" + tab.id

      if ( hash == href || (tab.$attrs.alias && hash == "#" + tab.$attrs.alias) )
        return

      history.pushState({}, '', href)
      this.$emit("input", href)
    }
  },
  watch: {
    incomingTab(val) {
      if ( val ) {
        this.activateByHash(val)
        this.$emit("input", val)
      }
    }
  }
}
</script>
