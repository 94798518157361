<template>
  <example-overlay :isExample="url.isExample" edit-link="/edit#links">
    <div v-if="url.data" class="grid-list">
      <h3>Website</h3>
      <a :href="url.data">{{ url.data }}</a>
    </div>
    <div v-if="hasSocial" class="grid-list">
      <h3>Social Media</h3>
      <span v-for="link in links.data">
        <a :href="link.url" target="_blank">
          <img v-if="link.img" class="social-button-image" :src="link.img"/>
          <div v-else class="color social-button borderless" :class="`icon-${link.name}`"></div>
        </a>
      </span>
    </div>
  </example-overlay>
</template>
<script>

import socialMediaSites from 'social_media_sites.js'
import ExampleData from 'mixins/example_data.js'

export default {
  props: ["company" ],
  mixins: [ ExampleData ],
  computed: {
    needsExamples() {
      return !this.company.url && Object.values(this.socialLinks).filter(v => v).length == 0
    },
    url() {
      let url = this.company.url

      if  ( url && !url.startsWith("http://") && !url.startsWith("https://") )
        url = "https://" + url

      return this.exampleData(url, "https://www.example.com", this.needsExamples)
    },
    links() {
      let exampleLinks = [
        { name: 'facebook', url: 'https://facebook.com/example' },
        { name: 'twitter', url: 'https://twitter.com/example' },
        { name: 'instagram', url: 'https://instagram.com/example' }
      ]

      return this.exampleData(this.socialLinks, exampleLinks, this.needsExamples)
    },
    hasSocial() {
      return this.links.data.length > 0
    },
    socialLinks() {
      return socialMediaSites.map(site => {
        let url = this.company.links[site.name]
        if ( !url )
          return null;

        // be forgiving if user has pasted our prefix in.  could be improved.
        if ( url.startsWith(site.urlPrefix) )
          url = url.replace(site.urlPrefix, "")

        // be even more forgiving...
        if ( ! url.startsWith('https://') )
          url = site.urlPrefix + url
        return Object.assign(site, { url: url })
      }).filter(link => link != null)
    }
  }
}
</script>
