import ExampleOverlay from 'components/example_overlay.vue'

export default {
  components: { ExampleOverlay },
  methods: {
    exampleData(data, example, need_example) {
      if ( need_example == undefined ) {
        if ( data == null || data == undefined )
          need_example = true
        else if ( Array.isArray(data) )
          need_example = (data.length == 0)
        else
          need_example = false
      }

      if ( need_example && this.showExamples )
        return { isExample: true, data: example }
      else
        return { isExample: false, data: data }
    },
    withExample(data, exampleData, test) {
      let testResult
      if ( test != undefined )
        testResult = test
      else
        testResult = data

      if ( testResult )
        return data
      else if ( this.showExamples  )
        return exampleData
      else
        return data
    }
  },
  computed: {
    // override me in the class
    showExamples() {
      let isPreview = window.location.pathname.match(/^\/edit/)
      return !isPreview && this.userSeesExamples
    },
    userSeesExamples() {
      let user = this.$globals.currentUser
      return user && ['owner', 'admin', 'agent'].includes(user.role)
    }
  }
}
