<template>
  <div>
    <div class="activity-date">{{ formattedDate }}</div>
    <ul class="activity-list">
      <li v-for="c in session.clicks">
        <span v-if="c.type == 'vault'">Viewed story vault</span>
        <span v-if="c.type == 'tab'">Viewed {{ tabName(c.name) }}</span>
        <span v-if="c.type == 'document'">Viewed {{ c.name }}</span>
        <span v-if="c.type == 'dashboard'">Viewed Investor Dashboard</span>
        <span v-if="c.type == 'pitch_deck'">
          Viewed investor presentation
          <div class="page-list">
            <i>pages: {{ pageList(c.pages) }}</i>
          </div>
        </span>
      </li>
    </ul>
  </div>
</template>
<style scoped>
.activity-date {
  font-weight: 425;
  white-space: nowrap;
  width: 100%;
}

.activity-list {
  padding-left: 10px;
  list-style: inside;
}

.page-list {
  margin-left: 25px;
}

</style>
<script>
import format from 'date-fns/format'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

import parseJSON from 'date-fns/parseJSON'
import sub from 'date-fns/sub'

export default {
  methods: {
    tabName(tab) {
      switch(tab) {
        case "team":
          return "team tab"
        case "timeline":
          return "the timeline"
        case "deal":
          return "deal info tab"
        case "media":
          return "more media"
        case "story":
          return "our story"
        case "contact":
          return "contact tab"
      }
    },
    pageList(input) {
      let pages = input.slice().sort((a, b) => a - b)
      if ( pages[0] != 1 )
        pages.splice(0, 0, 1)

      let arr = []

      pages.forEach(page => {
        let p = parseInt(page)
        let last = arr[arr.length - 1]

        if ( last && (last[0] == page || last[1] == page)) // duplicate
          return

        if ( !last || last[1] != p - 1) {
          arr.push([p, p])
        } else {
          last[1] = p
        }
      })


      let strList = arr.map(a => {
        if ( a[0] == a[1] )
          return a[0]
        else
          return `${a[0]}-${a[1]}`
      })

      return strList.join(', ')
    }
  },
  computed: {
    formattedDate() {
      let at = this.session.ts
      if ( !at )
        return ""

      let dt = parseJSON(at)
      let yesterday = sub(new Date(), { days: 1 })
      if ( dt > yesterday ) {
        return formatDistanceToNow(dt) + " ago"
      } else {
        return format(dt, "MMM dd, yyyy")
      }
    }
  },
  props: ["session"]
}
</script>

