<template>
  <div>
    <div class="overlay-container">
      <div v-if="isExample" class="overlay-blur"></div>
      <div v-if="isExample" class="overlay-stamp">
        <img src="/images/example.svg" class="overlay-stamp-image">
      </div>
      <slot></slot>
    </div>
    <div v-if="isExample" class="overlay-message">
      Showing example {{ noun || "data" }}.  This section will be hidden from your audience until you <a :href="editLink">edit your company.</a>
    </div>
  </div>
</template>
<style scoped>
.overlay-container {
  position: relative;
}

.overlay-blur {
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  opacity: 0.4;
  z-index: 50;
  background: #fefefe;
}

.overlay-stamp {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  z-index: 51;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.overlay-stamp-image {
  transform: rotate(-15deg);
  width: 250px;
  opacity: 0.7;
}

.overlay-message {
  z-index: 51;
  font-weight: lighter;
  font-style: italic;
  margin-top: 5px;
}
</style>
<script>
export default {
  props: [ "is-example", "edit-link", "max-width", "height", "noun" ],
  computed: {
    stampStyle() {
      let style = {}
      if ( this.maxWidth )
        style['max-width'] = `min(${this.maxWidth}, 100%)`
      if ( this.height )
        style['height'] = this.height
      return style
    }
  }
}
</script>
