<template>
  <div>
    <h3 v-if="investNow">Interested In Investing?</h3>
    <h3 v-else>Get In Touch With {{ company.name }}</h3>
    <form method="post" action="/communications/inbound" ref="commsForm" class="red-dot">
      <csrf-token/>
      <mainstage-text v-if="anonymous" prefix="communication" v-model="comm.from_email" field="from_email" label="Your Email:" :errors="comm.errors"/>

      <div v-if="investNow" class="form-group">
        <label for="communication[investment_amount]">How much are you thinking of investing?</label>
        <mainstage-currency prefix="communication" v-model="comm.investment_amount" placeholder="10k, 100k, 1.2m" field="investment_amount" :errors="comm.errors"/>
      </div>
      <div v-if="investNow && canUseIra" class="form-group">
        <input type="checkbox" name="communication[ira_investment]" id="communication[ira_investment]"/>
        <label for="communication[ira_investment]">I'd like to use my IRA to invest</label>
      </div>
      <mainstage-text prefix="communication" v-model="comm.subject" field="subject" label="Subject" :errors="comm.errors"/>
      <div class="form-group">
        <label>Body</label>
        <textarea ref="body" class="form-control" :class="errClass(comm.errors, 'body')" name="communication[body]" v-model="comm.body" rows=10 cols=80></textarea>
        <mainstage-errors :errors="comm.errors ? comm.errors.body : []"/>
        <small v-if="anonymous">
          This form is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </small>
      </div>
      <input type="hidden" name="recaptcha_token" :value="recaptcha_token"/>
      <input type="submit" class="btn btn-primary-audience" value="Send" @click="recaptcha"/>
    </form>
  </div>
</template>
<script>
export default {
  props: ["company", "communication", "invest-now", "can-use-ira"],
  data() {
    let c = { subject: "", body: "", from_email: "" }
    if ( this.communication ) {
      c.subject = this.communication.subject
      c.body = this.communication.body
      c.from_email = this.communication.from_email
      c.errors = this.communication.errors
    }

    return {
      anonymous: this.$globals.currentUser.anonymous,
      comm: c,
      recaptcha_token: null
    }
  },
  mounted() {
    if ( this.comm.errors ) {
      this.$nextTick(() => this.$refs.body.scrollIntoView())
    }
  },
  methods: {
    recaptcha(ev) {
      if ( !this.anonymous )
        return

      ev.preventDefault()

      grecaptcha.ready(() => {
        grecaptcha.execute(this.$globals.recaptchaSite, {action: 'submit'}).then((token) => {
          this.recaptcha_token = token
          this.$nextTick(() => this.$refs.commsForm.submit())
        })
      })
    },
    errClass(allErrors, field) {
      if ( allErrors && allErrors[field] )
        return "is-invalid"
    }
  }
}
</script>
