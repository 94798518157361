let formatter = Intl.DateTimeFormat("en-us", {month: "long", day: "numeric", hour: "numeric", minute: "2-digit", year: "numeric"})

export default function() {
  $(".local-time").each((i, el) => {
    let val = $(el).html()
    let ms = Date.parse(val)
    if ( ms ) {
      let date = new Date(ms)
      $(el).html(formatter.format(date))
    }
  })
}

