<template>
  <b-dropdown :text="labelFor(value)" right offset="10" :variant="success ? 'outline-success' : 'outline-primary'" class="role-button" :size="size ? size : 'lg'">
    <b-dropdown-item class="role-item" @click="changeRole('prospect')">
      <div class="role-header">Guest</div>
      <div class="role-text">
        Potential investors who are just beginning to discover your company.
      </div>
    </b-dropdown-item>
    <b-dropdown-item class="role-item" @click="changeRole('vip')">
      <div class="role-header">VIP</div>
      <div class="role-text">
        VIPs are allowed access to more sensitive company documents.
      </div>
    </b-dropdown-item>
    <b-dropdown-item class="role-item" @click="changeRole('backstage')">
      <div class="role-header">Shareholder</div>
      <div class="role-text">
        Shareholders have invested in your company.
        Shareholders will receive <b>all</b> communications  and can view all documents.
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>
<style scoped>
.role-button {
  max-height: 60px;
}
</style>
<script>
export default {
  props: [ "value", "success", "size" ],
  methods: {
    changeRole(to) {
      if ( this.value == to )
        return

      this.$emit('input', to)
    },
    labelFor(key) {
      return this.$roleHash[key].label
    }
  }
}
</script>


