<template>
  <div>
    <mainstage-input v-bind="$props" errors="" type="hidden" :value="floatValue"/>
    <mainstage-input
      prefix="___"
      :field="field"
      :size="size"
      :placeholder="placeholder"
      :errors="errors"
      @blur="reformatCurrency"
      :value="stringValue"
      @input="updateValue($event)"/>
  </div>
</template>
<script>
  import formatCurrency from 'util/format_currency.js'

  export default {
    props: [ "inputClass", "prefix", "field", "type", "size", "placeholder", "value", "errors" ],
    data() {
      let floatVal = this.parseCurrency(this.value)

      return {
        floatValue: floatVal,
        stringValue: this.formatCurrency(floatVal)
      }
    },
    methods: {
      parseCurrency(val) {
        if ( typeof(val) != "string" || val == "" )
          return val

        let newVal = val

        if ( newVal.startsWith("$") ) {
          newVal = newVal.substring(1)
        }
        newVal = newVal.replace(/,/g, "")


        let asFloat = parseFloat(newVal)

        let ending = newVal.slice(-1).toUpperCase()
        if ( ending == "K" ) {
          asFloat *= 1000
        } else if ( ending == "M" ) {
          asFloat *= 1000000
        } else if ( ending == "B" ) {
          asFloat *= 1000000000
        }

        return asFloat
      },
      formatCurrency(v) {
        return formatCurrency(v)
      },
      reformatCurrency() {
        this.floatValue = this.parseCurrency(this.stringValue)
        this.stringValue = this.formatCurrency(this.floatValue)
      },
      updateValue(ev) {
        this.stringValue = ev
        this.$emit('input', this.parseCurrency(ev))
      }
    }
  }
</script>
