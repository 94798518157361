<template>
  <i class="fas mr-2" :class="documentIcon"/>
</template>
<style scoped>
i {
  color: #3c96f7;
}
</style>
<script>
export default {
  props: [ "doc" ],
  computed: {
    documentIcon() {
      if ( !this.doc.filename )
        return 'fa-file'
      let ext = this.doc.filename.split('.').pop()
      if ( !ext )
        return 'fa-file'

      switch(ext.toLowerCase()) {
        case 'pdf':
          return 'fa-file-pdf'
        case 'doc':
        case 'docx':
          return 'fa-file-word'
        case 'xls':
          return 'fa-file-excel'
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'png':
          return 'fa-file-image'
        default:
          return 'fa-file'
      }
    },

  }
}
</script>
