<template>
  <div>
    <form method="post" :action="`/audience/${investorId}`">
      <csrf-token/>
      <input type="hidden" name="_method" value="PUT">
      <div v-if="role != 'backstage'" class="form-row my-2">
        <small class="col-12">To record investments you've actually received, promote this user to a <b>Shareholder</b>.</small>
      </div>
      <div v-for="(investment, idx) in investments" :key="investment.id" class="mb-2">
        <investor-edit-investment-row :investment="investment" 
          :role="role" :show-delete="showDelete()" @delete="deleteInvestment(idx)"/>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <div>
          <button v-if="role == 'backstage'" @click.prevent="addInvestment" class="btn btn-secondary">+ Add Investment</button>
        </div>
        <div>
          <button class="btn btn-primary">Save Investments</button>
        </div>
      </div>
    </form>
  </div>
</template>
<style scoped>
.downgrade-warning {
  background: #bc0d1c;
  color: white;
  padding: 10px;
  border-radius: 5px;
}
</style>
<script>
import InvestorEditInvestmentRow from "./investor_edit_investment_row.vue"

export default {
  components: { InvestorEditInvestmentRow },
  props: [ "investor-id", "role", "investments" ],
  methods: {
    addInvestment() { 
      this.investments.push({
        
      })
    },
    deleteInvestment(idx) {
      if ( this.investments[idx].id ) { 
        this.$set(this.investments[idx], "deleted", true)
      } else { 
        this.investments.splice(idx, 1)
      }
    },
    showDelete() { 
      return this.investments.length > 1 || this.investments[0].id
    }
  }
}
</script>

