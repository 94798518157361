<template>
  <div>
    <div class="custom-file">
      <input type="file" class="custom-file-input" id="csvFile" @change="processCSV">
      <label class="custom-file-label" for="csvFile">
        <span v-if="filename">{{filename}}</span>
        <span v-else>Choose CSV file...</span>
      </label>
    </div>
    <div v-if="!filename" class="mt-3">
      Please format your CSV like so:
      <pre class="csv-guide">
email,first name,last name,investor type(optional)
guest@example.com,Mrs,Person,
john@example.com,John,Prendergast,guest
bob@example.com,Bob,Villa,vip
bob@rossy.com,Bob,Ross,shareholder</pre>
    </div>
    <div v-if="filename">
      <vuetable
        ref="vuetable"
        class="mainstage-table csv-import-table"
        pagination-path="pagination"
        @vuetable:pagination-data="onPaginationData"
        :css="tableCSS"
        :fields="fields"
        :per-page="10"
        :api-mode="false"
        :data-manager="dataManager">

        <div slot="email" slot-scope="props">
          <input class="form-control csv-input" v-if="props.rowData.edit" type="text" v-model="props.rowData.email"/>
          <span v-else>{{ props.rowData.email }}</span>
        </div>
        <div slot="firstname" slot-scope="props">
          <input class="form-control csv-input" v-if="props.rowData.edit" type="text" v-model="props.rowData.firstname"/>
          <span v-else>{{ props.rowData.firstname }}</span>
        </div>
        <div slot="lastname" slot-scope="props">
          <input class="form-control csv-input" v-if="props.rowData.edit" type="text" v-model="props.rowData.lastname"/>
          <span v-else>{{ props.rowData.lastname }}</span>
        </div>
        <div slot="role" slot-scope="props">
          <select v-if="props.rowData.edit" v-model="props.rowData.role" class="form-control csv-input">
            <option value="guest">Guest</option>
            <option value="vip">VIP</option>
            <option value="shareholder">Shareholder</option>
          </select>
          <span v-else>{{ props.rowData.role }}</span>
        </div>
        <div slot="buttons" slot-scope="props">
          <div class="d-flex">
            <button v-if="props.rowData.edit" class="btn btn-danger btn-small row-button" @click.prevent="deleteRow(props.rowData)">
              <i class="fa fa-icon fa-trash"></i>
            </button>
            <button class="btn btn-primary btn-small row-button" @click.prevent="props.rowData.edit = !props.rowData.edit">
              <i v-if="props.rowData.edit" class="fa fa-icon fa-check"></i>
              <i v-else class="fa fa-icon fa-edit"></i>
            </button>
          </div>
        </div>
      </vuetable>
      <div style="padding-top:10px" class="d-flex justify-content-end">
        <vuetable-bootstrap-pagination ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-bootstrap-pagination>
      </div>
      <small class="text-danger">{{ csvErrorMessage }}</small>
      <input type="hidden" name="invitation[from_csv]" value="1"/>
      <template v-for="row in validatedCSV" v-if="!row.deleted" v-key="row.email">
        <input type="hidden" name="invitation[users][][email]" :value="row.email"/>
        <input type="hidden" name="invitation[users][][firstname]" :value="row.firstname"/>
        <input type="hidden" name="invitation[users][][lastname]" :value="row.lastname"/>
        <input type="hidden" name="invitation[users][][role]" :value="mapRole(row.role)"/>
      </template>
    </div>
  </div>
</template>
<style>

.csv-input {
  width: 95% !important;
}

.csv-guide {
  background: #eee;
  padding: 10px;
}
.csv-import-table {
  margin: 10px;
}

.row-button {
  font-size: 0.8rem !important;
  font-weight: 300;
  margin-right: 5px;
}

.table-investors td {
  padding: 0.4em 0.5em !important;
  padding-left: 0 !important;
  vertical-align: middle !important;
}
</style>

<script>

import Papa from 'papaparse'
import Vuetable from 'vuetable-2'
import VuetableBootstrapPagination from 'components/vuetable_bootstrap_pagination.vue'
import _ from 'lodash'

export default {
  components: { Vuetable, VuetableBootstrapPagination },
  data() {
    return {
      tableCSS: {
        tableWrapper: '',
        tableHeaderClass: 'mb-0',
        tableBodyClass: 'mb-0',
        tableClass: 'table table-investors',
        loadingClass: 'loading',
        detailRowClass: 'vuetable-detail-row'
      },
      fields: [
        { name: "email", width: "30%"},
        { name: "firstname", title: "First Name", width: "25%"},
        { name: "lastname", title: "Last Name", width: "25%"},
        { name: "role", width: "20%"},
        { name: "buttons", title: "", width: "5%"}
      ],
      validatedCSV: [],
      filename: null,
      csvErrorMessage: ""
    }
  },
  methods: {
    dataManager(sortOrder, pagination) {
      if (this.validatedCSV.length < 1) return;

      let local = this.validatedCSV.filter(r => !r.deleted);
      this.$emit("user-count", local.length)

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        10
      );
      let from = pagination.from - 1;
      let to = from + 10;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    deleteRow(rowData) { 
      rowData.deleted = true
      this.$refs.vuetable.reload()
    },
    mapRole(role ) { 
      if ( role == "shareholder" ) 
        return "backstage"
      else if ( role == "vip" ) 
        return "vip"
      else 
        return "prospect"
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    processCSV(ev) { 
      Papa.parse(ev.target.files[0], { 
        complete: this.validateCSV
      })
    },
    validateCSV(results, file) { 
      this.parsedCSV = results.data.map(r => {
        return {
          email: r[0],
          firstname: r[1],
          lastname: r[2],
          role: r[3] || 'guest'
        }
      })
      this.filename = file.name


      let emailMap = {}
      let validated = []
      let duplicates = 0
      let errors = 0
      for ( const index in this.parsedCSV ) {
        let row = this.parsedCSV[index]
        
        if ( !row.email ) {
          errors++
          continue
        }

        row.edit = false

        if ( row.email ) 
          row.email = row.email.toLowerCase()

        if ( emailMap[row.email] ) {
          duplicates++
        } else if ( !row.email.match(/.*@.*/) ) {
          if ( index == 0 ) 
            continue
          else {
            errors++
          }
        } else {
          emailMap[row.email] = 1
          if ( row.role ) {
            row.role = row.role.toLowerCase()
            if ( !["guest", "vip", "shareholder"].includes(row.role) ) {
              row.role = "guest"
            }
          }
          validated.push(row)
        }
      }
      if ( duplicates || errors ) { 
        this.csvErrorMessage = `Removed ${duplicates} duplicate and ${errors} invalid row(s)`
      } else {
        this.csvErrorMessage = ""
      }
      this.validatedCSV = validated
      this.$emit("user-count", this.validatedCSV.length)
      this.$refs.vuetable.refresh()
    }
  }
}
</script>
