<template>
  <div>
    <example-overlay noun="media" :is-example="liveMedia.isExample">
      <div v-for="m in liveMedia.data" class="mt-2 red-dot">
        <div v-if="m.type == 'video'">
          <h4>{{ m.title }}</h4>
        </div>
        <div v-if="m.type == 'video'" class="video-container w-100 mx-0 mb-3">
          <mainstage-video :url="m.url"/>
        </div>
        <div v-else-if="m.type == 'document'" class="p-0">
          <h4>{{ m.title }}</h4>
          <a :href="documentURL(m)" target="_blank">
            <span class="fas fa-file"></span>
            {{ m.document_filename }}
          </a>
        </div>
        <div v-else-if="m.type == 'link'" class="p-0">
          <h4><a :href="m.url">{{ m.title }}</a></h4>
        </div>
        <div v-else-if="m.type == 'image'" class="p-0">
          <h4>{{ m.title }}</h4>
          <img :src="imageURL(m)" class="media-image"/>
        </div>
        <div v-else-if="m.type == 'separator'" class="p-0 mt-3">
          <h4>{{ m.title }}</h4>
        </div>
        <vue-markdown v-if="m.description" :source="m.description"/>
        <hr class="w-100" :class="{ separator: m.type == 'separator' }" />
      </div>
    </example-overlay>
  </div>
</template>
<style scoped>
h4 {
  color: #444;
}

.media-image {
  max-width: 100%;
  max-height: 500px;
}

.separator {
  border: 1px solid rgba(0, 0, 0, 0.5);
}
</style>

<script>
import DocumentURL from 'components/document_url'
import ExampleData from 'mixins/example_data.js'

let exampleMedia = [
  {
    "url": "https://www.nytimes.com/live/2021/06/16/world/covid-vaccine-coronavirus-mask",
    "type": "link",
    "title": "Company article in the New York times",
    "description": "New York Times columnist Aldous Huxley writes an Op-ed for the Times describing the inherit ineffability of the Company's process."
  },
  {
    "type": "image",
    "title": "Engineer Paul Williams goes over budgetary concerns",
    "image_url": "/images/example_team/pitch.jpg",
  },
  {
    "url": "https://www.youtube.com/watch?v=jAGgKE82034",
    "type": "video",
    "title": "Board member Vera Regan gives Ted Talk",
    "description": "Our board member Vera Regan talks about speaking style and unconscious bias at TEDx."
  },
]

export default {
  mixins: [ DocumentURL, ExampleData ],
  props: [ "media" ],
  methods: {
    imageURL(m) {
      if ( m.image_url )
        return m.image_url
      else if ( m.image_id )
        return `/images/${m.image_id}`
      else
        return ''
    }
  },
  computed: {
    liveMedia() {
      let media

      if ( this.media )
        media = this.media.filter(m => !m.deleted)

      return this.exampleData(media, exampleMedia)
    }
  }
}
</script>

