<template>
  <div class="form-group purple-box">
    <h1>Market Facts</h1>
    <div v-for="(metric, i) in editFacts" :key="i">
      <mainstage-text
        maxlength="14"
        prefix="company"
        :label="'Fact ' + (i + 1)"
        field="key_metrics[][number]"
        :placeholder="metricPlaceHolders[i].number"
        v-model="metric.number"
        :errors="errors"/>
      <mainstage-input
        class="mb-3"
        prefix="company"
        field="key_metrics[][unit]"
        :placeholder="metricPlaceHolders[i].unit"
        v-model="metric.unit"
        :errors="errors"/>
    </div>
    <div class="preview">
      <div class="metric-container">
        <market-fact v-for="(metric, i) in editFacts" :key="i" :metric="metric" v-if="metric.number || metric.unit"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.metric-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
</style>

<script>
import MarketFact from 'market_fact.vue'

export default {
  components: { MarketFact },
  props: ["facts", "errors"],
  data() {
    return {
      editFacts: this.padFacts(this.facts),
      metricPlaceHolders: [
        { number: "10 million", unit: "People who already own widgets" },
        { number: "$3.5 billion", unit: "Estimated widget market size" },
        { number: "100 million", unit: "Daily widget users" }
      ]
    }
  },
  methods: {
    padFacts(facts) {
      if ( !facts )
        facts = []

      let toAdd = 3 - facts.length
      for ( let i = 0; i < toAdd; i++ )
        facts.push({number: '', unit: ''})

      return facts
    }
  }
}
</script>
