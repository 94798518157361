<template>
  <div class="purple-box">
    <div class="float-right">
      <button class="btn btn-primary" @click.prevent="toggleReorder" v-if="!reordering">Change order</button>
      <button class="btn btn-primary" @click.prevent="toggleReorder" v-else>Done</button>
    </div>
    <h1 v-if="reg_cf">Press</h1>
    <h1 v-else>More Media</h1>
    <div class="container-fluid top-container">
      <div v-if="reordering">
        <draggable :clone="handleClone" v-model="editMedia" handle=".handle" @end="$emit('input', editMedia)" class="mb-2">
          <div class="list-group-item row" v-for="(m, idx) in editMedia" :key="m._vue_id">
            <div class="col-12 d-flex align-items-center">
              <b>{{ m.type }}</b>: {{ m.title }}</b>
              <span class="flex-grow-1 text-right">
                <span class="btn handle">
                  <i class="fa fa-bars"></i>
                </span>
              </span>
            </div>
          </div>
        </draggable>
      </div>
      <div v-show="!reordering" class="row mb-4 position-relative" v-for="(m, idx) in editMedia" :key="m._vue_id">
        <div v-if="m.deleted" class="col-12">
          <div class="d-flex w-100 justify-content-between">
            <b>{{ m.type }} "{{m.title}}" will be deleted on save.</b>
            <button class="btn btn-secondary" @click="m.deleted = false">Undelete</button>
          </div>
        </div>
        <div v-if="!m.deleted" class="col-12">
          <label class="mainstage-form-label">Media Type</label>
          <div class="input-group form-group">
            <select v-model="m.type" name="company[media][][type]" class="form-control media-type-select">
              <option value="video">Video</option>
              <option value="document">Document</option>
              <option value="link">Link</option>
              <option value="image">Image</option>
              <option value="separator">Separator</option>
            </select>
            <span class="flex-grow-1 text-right">
              <button class="btn btn-danger" @click.prevent="deleteMedia(idx)">
                <i class="fas fa-trash"></i>
              </button>
            </span>
          </div>
        </div>
        <div v-if="!m.deleted" class="col-12 col-md-6">
          <mainstage-text
            placeholder="title"
            :label="`${m.type} title` | capitalize"
            prefix="company[media][]"
            field="title"
            v-model="m.title"
          />
        </div>
        <div v-if="m.type == 'video' && !m.deleted" class="col-12 col-md-6">
          <label class="mainstage-form-label">Youtube/Vimeo link</label>
          <div class="input-group form-group">
            <input
              type="text"
              placeholder="https://youtube.com/watch?v=xxxx or https://vimeo.com/123456789"
              v-model="m.url"
              name="company[media][][url]"
              :id="`company_media_${idx}_url`"
              class="form-control"
              :class="errorAt(idx) ? 'is-invalid' : ''"
            />
          </div>
          <mainstage-errors :errors="errorAt(idx)"/>
        </div>
        <div v-else-if="m.type == 'document' && !m.deleted" class="col-12">
          <div v-if="m.document_id">
            <a :href="documentURL(m)">{{ m.document_filename }}</a>
            <input type=hidden name="company[media][][document_id]" :value="m.document_id"/>
            <input type=hidden v-if="m.document_filename" name="company[media][][document_filename]" :value="m.document_filename"/>
            <input type=hidden v-if="m.upload_id" name="company[media][][upload_id]" :value="m.upload_id"/>
          </div>
          <FileDragTarget v-else
            class="d-none d-sm-block"
            fileInput="child"
            @change="uploadDocument($event, m)"
            @dragstart="m._dragging = true"
            @dragend="m._dragging = false">
            <div class="big-drag-target d-flex flex-column justify-content-center align-items-center" :class='{dragging: m._dragging}'>
              <div class="mb-2">
                Drag document here
              </div>
              <label class="btn btn-primary d-inline-block">
                Choose a file...
                <input type="file" @change="uploadDocument($event, m)" hidden/>
              </label>
            </div>
          </FileDragTarget>
          <!-- mobile, don't bother showing drag-drop target. -->
          <label class="btn btn-primary d-inline-block d-sm-none">
            Choose a file...
            <input type="file" @change="uploadDocument($event, m)" hidden/>
          </label>
        </div>
        <div v-else-if="m.type == 'link' && !m.deleted" class="col-12">
          <label class="mainstage-form-label">URL</label>
          <div class="input-group form-group">
            <input
              type="text"
              placeholder="https://www.example.com"
              v-model="m.url"
              name="company[media][][url]"
              :id="`company_media_${idx}_url`"
              class="form-control"
              :class="errorAt(idx) ? 'is-invalid' : ''"
            />
          </div>
        </div>
        <div v-else-if="m.type == 'image' && !m.deleted" class="col-12">
          <input v-if="m.image_id" type="hidden" name="company[media][][image_id]" :value="m.image_id"/>
          <StaticImageField
            file-field="company[media][][image]"
            :image-id="m.image_id"
            default-image="/images/drag_image.png"
            @result="$set(m, 'image_url', $event)"
          />
        </div>
        <div v-if="m.type != 'separator' && !m.deleted" class="col-12">
          <label class="mainstage-form-label">{{ m.type | capitalize }} description</label>
          <markdown-editor v-model="m.description" placeholder="Additional text..." name="company[media][][description]"/>
        </div>
        <hr class="w-100"/>
      </div>
      <div v-if="!reordering" class="row">
        <div class="col-12 text-right">
          <button class="btn btn-primary" @click.prevent="addMedia">+ add media</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.handle {
  cursor: move !important;
}

.media-type-select {
  max-width: 400px;
}

.big-drag-target {
  max-width: 400px;
  border: 2px dashed #ccc;
  padding: 10px;
}
.big-drag-target.dragging {
  border: 2px dashed #333;
}
</style>
<script>

import FileDragTarget from 'components/file_drag_target.vue'
import StaticImageField from 'components/static_image_field.vue'

import UploadWithProgress from 'mixins/upload_with_progress.js'
import DocumentURL from 'components/document_url.js'

import Draggable from 'vuedraggable'

export default {
  props: [ "media", "errors", "reg_cf" ],
  components: { FileDragTarget, StaticImageField, Draggable },
  mixins: [ UploadWithProgress, DocumentURL ],
  data() {
    let media = this.media || []
    let editMedia = media.map(m => { return { ...m } })

    if ( !editMedia || editMedia.length == 0 ) {
      editMedia = [
        { url: "", type: "video", _dragging: false, description: "", deleted: false }
      ]
    } else {
      editMedia.forEach(m => {
        if ( !m.type )
          m.type = "video"

        if ( !m.description )
          m.description = null

        m._dragging = false
        m.deleted = false
      })
    }


    /* give each media item a UI key, helps with re-ordering */
    let id = 0
    editMedia.forEach(m => {
      m._vue_id = id++
    })

    return {
      errs: this.errors,
      editMedia: editMedia,
      reordering: false
    }
  },
  watch: {
    editMedia: {
      deep: true,
      handler() {
        this.$emit('input', this.editMedia)
      }
    }
  },
  methods: {
    toggleReorder() {
      this.reordering = !this.reordering
    },
    errorAt(idx) {
      if ( this.errors && this.errors['media_' + idx] )
        return this.errors['media_' + idx]
      else
        return null
    },
    deleteMedia(idx) {
      this.errs['media_' + idx] = null
      if ( this.editMedia[idx].isNew )
        this.editMedia.splice(idx, 1)
      else
        this.editMedia[idx].deleted = true
    },
    addMedia() {
      this.editMedia.push({ url: "", type: "video", deleted: false, isNew: true})
      this.errs['media_' + this.editMedia.length] = null
    },
    uploadDocument(data, media) {
      let file
      if( data.target )
        file = data.target.files[0]
      else
        file = data[0]

      let doc = {
        id: "_new_9999999",
        document_type: "more_media",
        title: null,
        filename: file.name,
        upload_id: null,
        progress: 0,
        required_role: 0
      }

      this.$set(media, 'document_filename', file.name)
      this.uploadWithProgress(doc, file).then(() => {
        this.$set(media, "upload_id", doc.upload_id)
        this.$set(media, "document_id", doc.id)
      })
    }
  }
}

</script>

