<template>
  <div class="purple-box less-padded">
    <div class="headline-container">
      <h1>Team Members <button @click.prevent="addMember" class="btn btn-primary ml-2">+ Member</button></h1>
    </div>
    <draggable v-model="dataMembers" handle=".handle" class="team-members d-flex flex-wrap" @end="onDragEnd">
      <div v-for="member in sortedMembers" :key="member.id" class="team-member">
        <TeamMemberEdit
          :member="member"
          ref="members"
          @updatePhotoURL='updatePhotoURL(member, $event)'
          @delete="deleteMember(member)"
          @undelete="member.deleted = false"
        />
      </div>
    </draggable>
    <div v-if="sortedMembers.length == 0">
      It's as much about who you are as about what you do.  Introduce you and your team here by clicking "+ Member".
    </div>
  </div>
</template>

<style scoped>
.less-padded {
  padding: 40px 2px;
}
.headline-container {
  margin-left: 0px;
}
.team-members {
  width: 100%;
}

.team-member {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;

  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}


@media (min-width: 768px) {
  .team-members {
    padding: 10px;
  }

  .team-member {
    width: 320px;
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .less-padded {
    padding: 40px 34px;
  }
}

.new-member-container {
  padding: 10px;
}
</style>

<script>
  import Team from './team.vue'
  import TeamMemberEdit from './team_member_edit.vue'
  import draggable from 'vuedraggable'
  import scrollWithOffset from 'util/scroll_with_offset.js'

  export default {
    data() {
      var idx = 0;

      let clonedMembers = this.members.map((m, idx) => {
        let newMember = { ...m }

        if ( !newMember.id ) // got passed back through validation errors
          newMember.id = "_new_" + idx

        newMember.isEditing = false
        newMember.croppie_url = null
        newMember.deleted = false
        return newMember
      })

      return {
        dataMembers: clonedMembers
      }
    },
    methods: { // this says, separate this out into a sub-component
      addMember() {
        this.dataMembers.push({
          id: "_new_" + this.dataMembers.length + 1,
          name: "",
          position: "",
          bio: "",
          isEditing: true,
          idx: this.dataMembers.length,
          croppie_url: null
        })

        this.$nextTick(() => {
          let el = this.$refs.members[this.$refs.members.length - 1].$el
          if ( el ) {
            scrollWithOffset(el)
            const input = el.querySelector("input[type='text']")
            console.log(input)
            if ( input ) input.focus()
          }
        })
      },
      onDragEnd(ev) {
        let arr = this.sortedMembers.slice()

        if ( ev.newIndex < ev.oldIndex ) {
          for( let i = ev.newIndex ; i < ev.oldIndex ; i++ )
            arr[i].idx++
        } else {
          for( let i = ev.oldIndex + 1; i <= ev.newIndex ; i++ )
            arr[i].idx--
        }

        arr[ev.oldIndex].idx = ev.newIndex
      },
      updatePhotoURL(member, url) {
        member.croppie_url = url
        this.$emit('input', this.dataMembers)
      },
      deleteMember(member) {
        // just remove recently created members.  otherwise
        // set it up to modify on save
        if ( typeof(member.id) == "string" && member.id.startsWith("_new") )
          this.dataMembers = this.dataMembers.filter(m => m.id != member.id)
        else
          member.deleted = true
      }
    },
    computed: {
      sortedMembers() {
        return this.dataMembers.slice().sort((a,b) => a.idx - b.idx)
      }
    },
    props: ["members", "editable"],
    components: { TeamMemberEdit, Team, draggable },
    watch: {
      dataMembers: {
        deep: true,
        handler() {
          this.$emit('input', this.dataMembers)
        }
      }
    }
  }
</script>
