<template>
  <div class="mb-1">
    <div class="d-block d-sm-flex justify-content-between">
      <h1>Audience</h1>
      <div class="form-group has-search">
        <span class="fa fa-search form-control-feedback"></span>
        <input type="text" class="form-control" @input="searchInput" placeholder="Search by name / email">
      </div>
    </div>
    <investor-table-filter @input="filterInput($event)" :searching="searching"/>
    <vuetable
      ref="vuetable"
      class="investor-table mainstage-table"
      :css="tableCSS"
      :fields="fields"
      :show-sort-icons="true"
      api-url="/audience/search.json"
      :append-params="apiParams"
      data-path="investors"
      pagination-path="links.pagination"
      :sort-order = "sortOrder"
      :initial-page = "initialPage"
      @vuetable:pagination-data="onPaginationData">

      <div slot="name-slot" slot-scope="props">
        <a :href="`/audience/${props.rowData.id}`">{{ props.rowData.fullname }}</a>
      </div>
      <div slot="email-slot" slot-scope="props">
        {{ props.rowData.email }}
      </div>
      <div slot="invited_at-slot" slot-scope="props">
        <span class="d-none d-md-inline">
          {{ props.rowData.invited_at | formatDatetime('full') }}
        </span>
        <span class="d-inline d-md-none">
          {{ props.rowData.invited_at | formatDatetime }}
        </span>
      </div>
      <div slot="last_visit-slot" slot-scope="props">
        <span class="d-none d-md-inline">
          {{ props.rowData.last_visit_at | formatDatetime('full') }}
        </span>
        <span class="d-inline d-md-none">
          {{ props.rowData.last_visit_at | formatDatetime }}
        </span>
      </div>
      <div slot="role-slot" slot-scope="props">
        {{ $roleHash[props.rowData.role].label }}
      </div>

    </vuetable>
    <div v-if="apiParams.filter == 'invited' && paginationData.total > 0" class="text-small text-secondary text-right mt-2">
      {{ paginationData.total }} pending {{ 'invites' | pluralize(paginationData.total) }}.  <a href="/audience/resend_invites" class="purple">Resend invites</a>
    </div>
    <div class="d-flex justify-content-between mt-3">
      <div>
        <a v-if="!$globals.isForeign" href="/audience/invite" class="btn btn-primary mr-2"><i class="far fa-envelope-open mr-2"></i>Invite Prospects</a>
        <a href="/audience.csv" class="btn btn-primary"><i class="fa fa-list mr-2"></i>Export CSV</a>
      </div>
      <div>
      </div>
      <vuetable-bootstrap-pagination
        @vuetable-pagination:change-page="onChangePage"
        ref="pagination" />
    </div>
  </div>
</template>
<style lang="scss">
@import 'vars';

.investor-table th:hover {
  color: $mainstage-purple-hover !important;
}

.sort-icon {
  font-size: 0.85em;
  margin-left: 5px;
}


.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

</style>
<script>

import Vuetable from 'vuetable-2'
import VuetableBootstrapPagination from 'components/vuetable_bootstrap_pagination.vue'

import InvestorTableFilter from 'investor_table_filter.vue'
import formatCurrency from 'util/format_currency.js'

import HashChange from 'mixins/hash_change.js'


let defs = {
  email: {
    name: "email-slot",
    sortField: "email",
    title: "Email",
    titleClass: "d-none d-sm-table-cell",
    dataClass: "d-none d-sm-table-cell"
  },
  name: {
    name: "name-slot",
    sortField: "lastname",
    title: "Name"
  },
  role: {
    name: "role-slot",
    title: "Type",
    sortField: "role"
  },
  committed: {
    name: "committed",
    sortField: "committed",
    title: "Committed",
    titleClass: "d-none d-sm-table-cell",
    dataClass: "d-none d-sm-table-cell",
    formatter (value) {
      return formatCurrency(value, false)
    }
  },
  invested: {
    name: "invested",
    sortField: "invested",
    title: "Invested",
    titleClass: "d-none d-sm-table-cell",
    dataClass: "d-none d-sm-table-cell",
    formatter (value) {
      return formatCurrency(value, false)
    }
  },
  last_invited: {
    name: "invited_at-slot",
    sortField: "invited_at",
    title: "Invited on"
  },
  visits: {
    name: "visits",
    title: "Visits",
    sortField: "visits"
  },
  last_visit: {
    name: "last_visit-slot",
    sortField: "last_visit_at",
    title: "Last Visit"
  }
}

import debounce from 'lodash/debounce'

export default {
  mounted() {
    this.hashChange()
  },
  data() {
    let apiParams = { per_page: 9 }

    let hashInfo = this.parseHash()
    apiParams.filter = hashInfo.filter

    return {
      tableCSS: {
        tableWrapper: '',
        tableHeaderClass: 'mb-0',
        tableBodyClass: 'mb-0',
        tableClass: 'table table-hover',
        loadingClass: 'loading',
        ascendingIcon: 'fa fa-chevron-up purple',
        descendingIcon: 'fa fa-chevron-down purple',
        ascendingClass: 'sorted-asc',
        descendingClass: 'sorted-desc',
        sortableIcon: 'fa fa-sort',
        detailRowClass: 'vuetable-detail-row',
        handleIcon: 'fa fa-bars text-secondary',
        renderIcon(classes, options) {
          return `<i class="${classes.join(' ')}"></span>`
        }
      },
      apiParams: apiParams,
      sortOrder: this.getDefaultSort(apiParams.filter),
      searching: false,
      initialPage: hashInfo.page,
      savedTableState: null, // entering search clobbers sort/filter state, we stash it here,
      paginationData: {}
    }
  },
  methods: {
    parseHash() {
      let hash = window.location.hash
      if ( !hash ) {
        return { filter: 'invited', page: 1 }
      } else {
        hash = hash.substring(1) // skip hash character
        let arr = hash.split('/')
        let filter = arr[0]
        let page = arr[1] || '1'
        return { filter: filter, page: parseInt(page) }
      }
    },
    hashChange() {
      let info = this.parseHash()
      this.$refs.vuetable.currentPage = info.page
      this.changeFilter(info.filter)
    },
    filterInput(newFilter) {
      this.$refs.vuetable.currentPage = 1
      this.changeFilter(newFilter)
      this.pushState()
    },
    changeFilter(newFilter) {
      this.apiParams.filter = newFilter
      this.$nextTick(() => this.$refs.vuetable.reload())
      this.sortOrder = this.getDefaultSort(newFilter)
    },
    onPaginationData(paginationData) {
      this.paginationData = paginationData
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
      this.pushState()
    },
    pushState() {
      let hash = this.apiParams.filter
      if ( this.$refs.vuetable.currentPage > 1 )
        hash = hash + "/" + this.$refs.vuetable.currentPage

      let url = window.location.pathname + '#' + hash
      history.pushState({}, '', url)
    },
    getDefaultSort(filter) {
      switch(filter) {
        case "invited":
          return [
            { field: "invited_at-slot", sortField: "invited_at", direction: "desc" }
          ]

      }
    },
    searchInput: debounce(function(ev) {
      let str = ev.target.value
      if ( str ) {
        this.apiParams.q = ev.target.value

        if ( !this.savedTableState ) {
          this.savedTableState = {
            filter: this.apiParams.filter,
            sort: this.sortOrder
          }
          this.apiParams.filter = "all"
          this.sortOrder = undefined
        }

        this.searching = true
      } else {
        // exiting search
        this.apiParams.q = null
        if ( this.savedTableState ) {
          this.apiParams.filter = this.savedTableState.filter
          this.sortOrder = this.savedTableState.sort
          this.savedTableState = null
        }

        this.searching = false
      }
      this.$nextTick(() => this.$refs.vuetable.refresh())
    }, 100)
  },
  computed: {
    fields() {
      let f
      switch(this.apiParams.filter) {
        case 'invited':
          return [defs.name, defs.email, defs.role, defs.last_invited]
        case 'prospect':
        case 'vip':
          return [defs.name, defs.email, defs.visits, defs.last_visit, defs.committed]
        case 'backstage':
          return [defs.name, defs.email, defs.committed, defs.invested]
        case 'committed':
          return [defs.name, defs.email, defs.role, defs.committed, defs.invested]
        case 'removed':
          return [defs.name, defs.email]
        default:
          return [defs.name, defs.email, defs.role, defs.committed, defs.invested]
      }
    }
  },
  components: {
    Vuetable, VuetableBootstrapPagination, InvestorTableFilter
  },
  mixins: [ HashChange ]
}
</script>
