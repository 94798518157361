<template>
  <div class="mb-1">
    <vuetable
      ref="vuetable"
      class="investor-table mainstage-table"
      :css="tableCSS"
      :fields="fields"
      :api-url="apiUrl"
      :per-page="5"
      data-path="activity"
      pagination-path="links.pagination"
      :transform="transform"
      @vuetable:pagination-data="onPaginationData">
      <div slot="fullname-slot" slot-scope="props">
        <a v-if="!props.rowData.isContinued" :href="`/audience/${props.rowData.user.id}`">{{ props.rowData.user.fullname }}</a>
      </div>
      <div slot="activity-date-slot" slot-scope="props">
        {{ props.rowData.created_at | formatDatetime }}
      </div>
      <div slot="document-title-slot" slot-scope="props">
        <document-title :doc="props.rowData.document"/>
      </div>
    </vuetable>
    <div class="d-flex justify-content-end mt-2">
      <vuetable-bootstrap-pagination
        @vuetable-pagination:change-page="onChangePage" ref="pagination" />
    </div>
  </div>
</template>
<style lang="scss">
@import 'vars';

.investor-table th:hover {
  color: $mainstage-purple-hover !important;
}

</style>
<script>

import Vuetable from 'vuetable-2'
import VuetableBootstrapPagination from 'components/vuetable_bootstrap_pagination.vue'

export default {
  props: [ "api-url" ],
  data() {
    return {
      tableCSS: {
        tableWrapper: '',
        tableHeaderClass: 'mb-0',
        tableBodyClass: 'mb-0',
        tableClass: 'table table-hover',
        loadingClass: 'loading',
        detailRowClass: 'vuetable-detail-row',
        handleIcon: 'fa fa-bars text-secondary',
        renderIcon(classes, options) {
          return `<i class="${classes.join(' ')}"></span>`
        }
      },
      fields: [
        {
          name: "fullname-slot",
          title: "Name"
        },
        {
          name: "activity-date-slot",
          title: "View Date"
        },
        {
          name: "document-title-slot",
          title: "Document"
        }
      ]
    }
  },
  methods: {
    transform(data) {
      let lastUser = null

      data.activity.forEach(a => {
        if ( a.user.id == lastUser )
          a.isContinued = true
        else
          lastUser = a.user.id
      })

      return data
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    }
  },
  components: {
    Vuetable, VuetableBootstrapPagination
  }
}
</script>
