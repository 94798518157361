<template>
  <div v-if="activity.action_text" class="activity-item">
    <a class="user" v-if="showUser" :href="`/audience/${activity.user_id}`">{{ activity.fullname }}</a>
    <span class="action">{{ activity.action_text | capitalize }}</span>
    <span class="activity-date d-none d-sm-inline">{{ formattedDate }}</span>
  </div>
</template>
<style scoped>
.activity-date {
  font-weight: 425;
  white-space: nowrap;
  text-align: right;
  margin-left: 5px;
}
.action {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
}

.activity-item {
  display: flex;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.user {
  flex: 0 0 33%;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

</style>
<script>
import format from 'date-fns/format'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

import parseJSON from 'date-fns/parseJSON'
import sub from 'date-fns/sub'

export default {
  computed: {
    formattedDate() {
      let at = this.activity.created_at
      if ( !at )
        return ""

      let dt = parseJSON(at)
      let yesterday = sub(new Date(), { days: 1 })
      if ( dt > yesterday ) {
        return formatDistanceToNow(dt) + " ago"
      } else {
        return format(dt, "MMM dd, yyyy")
      }
    }
  },
  props: ["activity", "show-user"]
}
</script>
