<template>
  <div class="purple-box social-media-links-form container-fluid">
    <h1>Links</h1>
    <div>
      <mainstage-text
        prefix="company"
        label="Company Website"
        field="url"
        placeholder="www.gizmo.com"
        :value="company_url"
        @input="$emit('company-url-update', $event)"
      />
    </div>

    <label class="mainstage-form-label">Social Media Links</label>
    <div v-for="site in sites" :key="site.name" class="form-group row">
      <div class="input-group col">
        <img v-if="site.img" class="social-button-image" :src="site.img"/>
        <div v-else class="color social-button borderless" :class="`icon-${site.name}`"></div>
        <div class="input-group-prepend d-none d-md-inline-block">
          <div class="input-group-text social-label justify-content-end" id="inputGroupPrepend">{{ site.urlHint }}</div>
        </div>
        <input type="text" :name="`company[links][${site.name}]`" @input="$emit('link-update', site.name, $event.target.value)" :value="links[site.name]" class="form-control"/>
      </div>
    </div>
  </div>
</template>

<script>
import socialMediaSites from 'social_media_sites.js'

export default {
  props: [ "links", "company_url"],
  data() {
    return {
      sites: socialMediaSites
    }
  }
}
</script>


