import Vue from 'vue'
import axios from 'axios'

export default function(user, to, state)
{
  let parameters = {
    authenticity_token: Vue.prototype.$csrfToken,
    user: {
      role: to
    }
  }

  Vue.set(state, 'roleChanging', true)

  return axios.put(`/users/${user.id}.json`, parameters)
    .then(a => {
      state.role = a.data.user.role
      state.roleChanging = false
      Vue.set(state, 'roleChanged', true)
      console.log(state.role)
      return state.role
    })
    .catch(a => {
      state.roleChanging = false
      Vue.set(state, 'roleErrors', ["Couldn't change role!"])
    })
}
