<template>
  <example-overlay :is-example="filteredMembers.isExample" noun="team" edit-link="/edit#team" max-width="400px">
    <div class="team-members d-flex flex-wrap">
      <TeamMember v-for="member in filteredMembers.data" :key="member.id" :member="member"/>
    </div>
  </example-overlay>
</template>

<style scoped>
.team-members {
  width: 100%;
  background-color: white;
}
@media (min-width: 576px) {
  .team-members {
    background-color: #fafafa;
  }
}
</style>

<script>

import TeamMember from 'team_member.vue'
import ExampleData from 'mixins/example_data.js'

let exampleMembers = [
  {
    name: "Sanda Domigan",
    position: "CEO",
    profile_image_url: "/images/example_team/sandra.jpg",
    photo_crop: [],
    bio: "Sandra Domigan has started five companies since 1997. As president of Prendifar Group, she advises entrepreneurial clients, companies, venture capital firms and professionals.",
    linkedin: "https://goog.com"
  },
  {
    name: "John Prendifar",
    position: "COO",
    profile_image_url: "/images/example_team/john.jpg",
    photo_crop: [0, 146, 3149, 3296],
    bio: "John Prendifar as COO has also shown herself to be a force to be reckoned with. As revealed by Frontier Developments’ community manager Jeremy Lea in a Reddit thread last month, Domigan has directly replied to dozens of threads discussing the biggest issues that have been plaguing the community.",
    linkedin: "https://goog.com"
  },
  {
    name: "William L Nearlove",
    position: "CTO",
    profile_image_url: "/images/example_team/william.jpg",
    photo_crop: [384, 97, 1675, 1389],
    bio: "William joined the company as CTO in 2021. He had built a career as an investor, focusing on infrastructure for emerging technologies such as artificial intelligence and autonomous driving.",
    linkedin: "https://goog.com"
  },
]

export default {
  mixins: [ ExampleData ],
  props: ["members"],
  components: { TeamMember },
  computed: {
    filteredMembers() {
      let members = this.members.filter(m => !m.deleted)
      return this.exampleData(members, exampleMembers)
    }
  }
}

</script>
