<template>
  <div class="row">
    <div class="form-group col-12 col-md-6 col-lg-4">
      <label class="mainstage-form-label">Folder</label>
      <select class="form-control" :class="folderClass" :name="fieldName" v-model="selectValue" @input="changeFolder">
        <option value="">(none)</option>
        <option v-for="f in folders" :value="f">{{ f }}</option>
        <option disabled>---</option>
        <option value="__create">New folder</option>
      </select>
    </div>
    <b-modal body-class="m-2 p-0" size="sm" centered @ok="createFolder" ref="createFolderModal" title="Create Folder" @shown="modalShown">
      <div class="container-fluid">
        <div class="row" v-if="folders && folders.length > 0">
          <div class="form-group col">
            <label class="mainstage-form-label">Parent folder</label>
            <select v-model="newFolderParent" class="form-control">
              <option value="">(none)</option>
              <option v-for="f in folders" :value="f">{{ f }}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="form-group col">
            <label class="mainstage-form-label">Folder Name</label>
            <input class='form-control'
              @input="newFolderNameErrorText = null"
              @keydown.enter="createFolder"
              v-model="newFolderName"
              :class="{ 'is-invalid': !!newFolderNameErrorText }"
              ref="newFolderName"
            />
            <small class="text-danger">{{ newFolderNameErrorText }}</small>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: [ 'fieldName', 'value', 'folders' ],
  data() {
    return {
      selectValue: this.value || '',
      newFolderNameErrorText: null,
      newFolderName: "",
      newFolderParent: ""
    }
  },
  watch: {
    value() {
      this.selectValue = this.value
    }
  },
  computed: {
    folderClass() {
      if ( this.value ) {
        return [ 'has-folder' ]
      } else
        return [ 'no-folder' ]
    }
  },
  methods: {
    modalShown() {
      this.$refs.newFolderName.focus()
    },
    changeFolder(ev) {
      if ( ev.target.value == "__create" ) {
        this.newFolderName = ""
        this.newFolderParent = ""
        this.$refs.createFolderModal.show()
      } else {
        this.$emit("input", ev.target.value)
      }
    },
    createFolder(ev) {
      if ( this.newFolderName == "" )
        return this.folderCreateError(ev, "Folder name required")

      let name = ""
      if ( this.newFolderParent ) {
        name = `${this.newFolderParent} / ${this.newFolderName}`
      } else {
        name = this.newFolderName
      }

      if ( this.folders.find(f => f == name) )
        return this.folderCreateError(ev, "Folder already exists")

      this.$emit("input", name)
    },
    folderCreateError(ev, text) {
        ev && ev.preventDefault()
        this.newFolderNameErrorText = text
        this.$refs.newFolderName.focus()
    }
  }
}
</script>
