<template>
  <div class="faq-list accordion d-flex">
    <div class="card faq-item" v-for="(faq, i) in faqs" @click="toggle(i)" >
      <div :class="{collapsed: collapsed[i]}" class="card-header d-flex">{{ faq.question }}</div>
      <div class="card-body" :class="{collapse: collapsed[i]}">
          <vue-markdown :source="faq.answer"/>
        </div>
    </div>
  </div>
</template>
<style>
.card-header {
  padding-left: 15px !important;

}
.faq-list {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .faq-item {
      flex: 0 0 48% !important;
      max-width: 48% !important;
  }
}
.accordion .card{border: 0px;box-shadow: 0px 2px 3px 0px #0003;margin-bottom: 18px !important; cursor: pointer;}
.accordion .card-header{border: 0px;background: #fff;}
.accordion .card-header .card-title{border: 0px;color: #1d1e20;}
.accordion .card-header .card-title:hover{text-decoration: none !important;}
.accordion .card .card-body p{line-height: 24px;}
.accordion .card-header::after {font-family: "Font Awesome 5 Free";font-weight: 900; content: "\f068";color: #4285f4;position: absolute; right: 10px; top: 13px;}
.accordion .card-header.collapsed:after { content: "\f067"; }
</style>
<script>

export default {
  props: ["faqs"],
  methods: { 
    toggle(idx) {
      this.$set(this.collapsed, idx, !this.collapsed[idx])
    }
  },
  data() {
    let collapsed = []
    for ( let i in this.faqs ) {
      collapsed[i] = true
    }

    return { 
      collapsed: collapsed
    }
  }
}
</script>
