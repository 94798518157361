<template>
  <div class="form-group" v-if="emails.length > 1">
    <label :for="inputName">Sender</label>
    <select class="form-control" :name="inputName" :id="inputName" v-model="from" @input="$emit('input', $event.target.value)">
      <option v-for="e in emails" :value="e.key">{{ e.display }}</option>
    </select>
  </div>
  <input v-else type="hidden" :name="inputName" :value="emails[0].key">
</template>

<script>
export default { 
  props: [ "form-prefix" ],
  data() {
    return {
      "from": this.$globals.currentUser.last_email_from || "replyTo" 
    }
  },
  computed: {
    inputName() { 
      return this.formPrefix + "[email_from]"
    },
    emails() {
      return this.$globals.fromEmails
    }
  }
}
</script>
