<template>
  <div>
    <input v-if="investment.id" type="hidden" name="investor[investments[][id]" :value="investment.id"/>
    <input v-if="investment.deleted" type="hidden" name="investor[investments[][deleted]" :value="deleted"/>
    <div class="position-relative">
      <div class="deleted-overlay" v-if="investment.deleted"></div>
      <div class="form-row">
        <label class="col-6 col-form-label" for="investor[investments][][committed]">Commitment amount:</label>
        <div class="col-6">
          <mainstage-currency prefix="investor[investments][]" field="committed" placeholder="$10k"
            v-model="investment.committed" :errors="investment.errors" />
        </div>
      </div>
      <div class="form-row my-1">
        <label class="col-6 col-form-label" for="investor[investments][][committed_on]">Commitment Date</label>
        <div class="col-6">
          <mainstage-input type="date" prefix="investor[investments][]" field="committed_on" :placeholder="todaysDate"
            v-model="investment.committed_on" :errors="investment.errors" />
        </div>
      </div>
      <div v-if="role == 'backstage'">
        <div class="form-row">
          <label class="col-6 col-form-label" for="investor[investments][][invested]">Invested amount:</label>
          <div class="col-6">
            <mainstage-currency prefix="investor[investments][]" field="invested" placeholder="$10k"
              v-model="investment.invested" :errors="investment.errors" />
          </div>
        </div>
        <div class="form-row my-1">
          <label class="col-6 col-form-label" for="investor[investments][][invested_on]">Investment Date</label>
          <div class="col-6">
            <mainstage-input type="date" prefix="investor[investments][]" field="invested_on" :placeholder="todaysDate"
              v-model="investment.invested_on" :errors="investment.errors" />
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <small class="col-6"><span v-if="!investment.deleted && outstandingCommitments">Outstanding 
        commitment: <b>{{ outstandingCommitments | currency }}</b></span>
      </small>
        <a class="col-6 text-right" href="#" v-if="investment.deleted" @click.prevent="investment.deleted = false">
          <small>undelete investment</small>
        </a>
        <a class="col-6 text-right" href="#" v-else-if="showDelete" @click.prevent="$emit('delete')">
          <small>delete investment</small>
        </a>
    </div>
  </div>
</template>
<style scoped>
  .deleted-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    backdrop-filter: blur(3px);
  }
</style>

<script>
import { format } from 'date-fns'
export default {
  props: [ "investment", "role", "show-delete"],
  computed: {
    outstandingCommitments() {
      let invested = this.investment.invested || 0
      if ( this.investment.committed && this.investment.committed > this.investment.invested ) {
        return this.investment.committed - this.investment.invested
      } else
        return null
    },
    todaysDate() {
      return format(new Date(), "yyyy-MM-dd")
    }
  }
}
</script>

