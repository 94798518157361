import { render, staticRenderFns } from "./company_onboarding.vue?vue&type=template&id=4208e85c&scoped=true&"
import script from "./company_onboarding.vue?vue&type=script&lang=js&"
export * from "./company_onboarding.vue?vue&type=script&lang=js&"
import style0 from "./company_onboarding.vue?vue&type=style&index=0&id=4208e85c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4208e85c",
  null
  
)

export default component.exports