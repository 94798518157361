<template>
  <div class="goal-bar-container" :class="{ 'bar-hidden': hideBar }">
    <div v-if="!hideBar" class="goal-bar">
      <div class="round-bar-container">
        <div class="round-bar goal"></div>
        <div class="round-bar committed" :style="{ width: committedTowardsGoal + '%' }"></div>
        <div class="round-bar complete" :style="{ width: pctTowardsGoal + '%' }"></div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div v-if="round.total_invested">
          <span class="legend complete"></span>Raised: {{ round.total_invested | currency }} from {{ round.investment_count }} {{ 'investors' | pluralize(round.investment_count) }}
        </div>
        <div v-if="round.total_committed" class="d-none d-md-block">
          <span class="legend committed"></span>Investments in process: {{ round.total_committed | currency }}
        </div>
        <div v-if="roundAmount" class="text-md-right">Raise: {{ roundAmount }}</div>
        <div v-else-if="showExamples" class="text-md-right"><a href="/edit#deal"><i class="far fa-edit mr-2"></i>Enter raise information</a></div>
      </div>
    </div>
    <div v-if="company.corekonx_listing_id">
      <div v-if="company.subdomain == 'airingsmartmask'" class="d-flex flex-column">
        <a href="#" onclick="return false;" 
          class="btn invest-btn kcxHinvestProcessForm mb-2" data-buttonname="ButtonName" 
          data-listing_id="50116" data-kcxClientToken="FSn5DVe67W9cwGfLq3MgvY">
          Invest Now<br/>
          $5k - $50k
        </a>
        <a href="#" onclick="return false;" 
          class="btn invest-btn kcxHinvestProcessForm" data-buttonname="ButtonName" 
          data-listing_id="50120" data-kcxClientToken="Y4prgzXYJ662c77051ZnfN">
            Invest Now<br/>
            $50k+
          </a>
        </div>
        <div v-else>
          <a v-if="isLoggedIn" onclick="return false;" 
            class="kcxHinvestProcessForm btn invest-btn" data-buttonname="ButtonName" 
            :data-listing_id="company.corekonx_listing_id" :data-kcxClientToken="company.corekonx_client_token">Invest now</a>
          <a v-else class="btn invest-btn" href="/users/sign_up">Invest now</a>
      </div>
        
    </div>
    <div v-else>
       <a class="btn invest-btn" href="/#invest_now">Commit $</a> 
    </div>
  </div>
</template>
<style scoped>
.goal-bar-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px 10px;
}

.bar-hidden {
  justify-content: flex-end;
}

.goal-bar {
  flex-grow: 1;
  width: 100%;
}

@media (min-width: 500px) {
  .goal-bar-container {
    padding: 0px 15px;
  }

  .goal-bar {
    width: auto;
  }
}

.round-bar-container {
  position: relative;
  height: 16px;
}

.round-bar {
  position: absolute;
  border-radius: 3px;
  left: 0px;
  right: 0px;
  height: 100%;
}

.legend {
  height: 13px;
  width: 13px;
  margin-right: 5px;
  display: inline-block;
}

.goal {
  background-color: #ecebeb;
  width: 100%;
}

.complete {
  background-color: #308c20;
}

.committed {
  background-color: #afdc92;
}

.invest-btn {
  background-color: #308c20;
  color: #fff;
  white-space: nowrap;
  margin-left: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.invest-btn:hover {
  color: #fff;
  background-color: #379f24;
}

.bar-hidden .invest-btn {
  margin-left: 0px;

}

@media (min-width: 500px) {
  .invest-btn {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 1rem;
  }
}
</style>
<script>
import ExampleData from 'mixins/example_data.js'

export default {
  props: ["round", "company", "hide-bar"],
  mixins: [ ExampleData ],
  computed: {
    roundAmount() {
      if ( this.round.amount ) {
        return this.$options.filters.currency(this.round.amount)
      } else {
        return null
      }
    },
    committedTowardsGoal() {
      if ( this.round.amount == 0 )
        return 0

      let invested_and_committed = this.round.total_invested + this.round.total_committed
      let pct = (invested_and_committed / this.round.amount) * 100
      return Math.min(pct, 100)
    },
    pctTowardsGoal() {
      if ( this.round.amount == 0 )
        return 0

      let pct = (this.round.total_invested / this.round.amount) * 100
      return pct > 100 ? 100 : pct
    },
    isLoggedIn() { 
      return this.$globals.currentUser && !this.$globals.currentUser.anonymous
    }
  }
}
</script>
