<template>
  <b-modal
    :visible="inviting"
    class="container-fluid mt-3"
    size="lg"
    :ok-title="buttonTitle"
    @ok.prevent="send"
    title="Invite new Admin"
    @cancel.prevent="$emit('close')"
    @close.prevent="$emit('close')"
    @shown="shown">
    <csrf-token ref="csrf"/>
    <div class="row">
      <mainstage-text ref="email" class="col-12 col-md-6" prefix="invitation" field="email" label="Email" v-model="email" :errors="fieldErrors.email"/>
      <div class="col-12 col-md-6">
        <label class="mainstage-form-label">Role</label>
        <div>
          <mainstage-admin-roles v-model="role"/>
        </div>
      </div>
    </div>
    <div class="row">
      <mainstage-text class="col-12 col-md-6" prefix="invitation" field="firstname" label="First Name" v-model="firstname" :errors="fieldErrors.firstname"/>
      <mainstage-text class="col-12 col-md-6" prefix="invitation" field="lastname" label="Last Name" v-model="lastname" :errors="fieldErrors.lastname"/>
    </div>
    <div v-if="confirmUpgrade" class="text-info">
      {{ email }} already exists as an investor in the system.  Please confirm you'd like to upgrade them to an admin.
    </div>
    <div v-if="confirmResend" class="text-info">
      {{ email }} has already been invited to be help administer the site.  Resend the invitation?
    </div>
    <div>{{ statusMessage }}</div>
    <mainstage-errors :errors="errors"/>
  </b-modal>
</template>

<script>
import axios from 'axios'
import MainstageAdminRoles from 'components/mainstage_admin_roles.vue'

export default {
  props: ["inviting"],
  components: { MainstageAdminRoles },
  data() {
    return {
      confirmUpgrade: false,
      confirmResend: false,
      statusMessage: "",
      message: "",
      email: "",
      role: "admin",
      firstname: "",
      lastname: "",
      errors: [],
      fieldErrors: {
        firstname: [],
        lastname: [],
        email: []
      }
    }
  },
  methods: {
    send() {
      let params = {
        authenticity_token: this.$refs.csrf.token,
        confirm_upgrade: this.confirmUpgrade,
        confirm_resend: this.confirmResend,
        invitation: {
          message: this.message,
          email: this.email,
          firstname: this.firstname,
          lastname: this.lastname,
          role: this.role
        }
      }
      this.errors = []
      this.fieldErrors = {
        email: []
      }

      axios.post('/invitations/admin', params)
        .then(response => {
          this.confirmUpgrade = false
          this.statusMessage = "Invitation sent!"
          setTimeout(() => {
            let user = response.data.user

            // kinda makes me think we need a javascript model somewhere.
            user.fullname = user.firstname + ' ' + user.lastname
            this.$emit("sent", user)
          }, 1000)
        })
        .catch(a => {
          switch(a.response.status) {
            case 403:
              this.errors.push("Permission denied")
              break
            case 422:
              if ( a.response.data?.errors?.email?.includes("need_confirm_upgrade") ) {
                this.confirmUpgrade = true
              } else if ( a.response.data?.errors?.email?.includes("need_confirm_resend") ) {
                this.confirmResend = true
              } else { 
                this.fieldErrors = a.response.data.errors
              }
              break
            case 500:
              this.errors.push("Sorry, an error occurred while trying to invite.")
          }
        })
    },
    shown() {
      $(this.$refs.email.$el).find("input").focus()
    }
  },
  watch: {
    inviting() {
      if ( this.inviting ) {
        this.firstname = ""
        this.lastname = ""
        this.email = ""
        this.statusMessage = ""
      }
    }
  },
  computed: {
    buttonTitle() { 
      if ( this.confirmUpgrade ) { 
        return "Upgrade"
      } else if ( this.confirmResend ) { 
        return "Resend"
      } else { 
        return "Send"
      }
    },
    adminRoles() {
      return [
        this.$roleHash['admin'],
        this.$roleHash['agent']
      ]
    }
  }
}
</script>
