<template>
  <div class="purple-box mb-5">
    <div class="d-flex justify-content-between">
      <h1>FAQs</h1>
      <div>
        <button class="btn btn-primary" @click.prevent="addQuestion">+ add question</button>
      </div>
    </div>
    <div v-for="(faq, i) in faqs" :key="i">
      <div v-if="faq.isEditing" class="mb-3">
        <label class="mainstage-form-label" for="faqs[][question]">Question #{{i + 1}}</label>
        <input type="text"
          class="form-control"
          name="company[faqs][][question]"
          placeholder="your question..."
          v-model="faq.question"
          @input="updateFAQs"
        />
        <label class="mainstage-form-label" for="faqs[][answer]">Answer #{{i + 1}}</label>
        <markdown-editor
          v-model="faq.answer"
          placeholder="answer..."
          @input="updateFAQs"
          name="company[faqs][][answer]" 
          id="faqs[][answer]"/>
        <button @click.prevent="faq.isEditing=false" class="btn btn-secondary mt-2">Done</button>
        <button @click.prevent="deleteAt(i)" class="btn btn-danger mt-2">Delete</button>
        <hr/>
      </div>
      <div v-else>
        <h5>Question #{{ i + 1 }}: <a href="#" @click.prevent="faq.isEditing=true"><i class="fa fa-edit"></i></a></h5>
        <i>{{ faq.question }}</i>
        <input type="hidden" name="company[faqs][][question]" :value="faq.question">
        <input type="hidden" name="company[faqs][][answer]" :value="faq.answer">
        <vue-markdown :source="faq.answer"/>
        <hr/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() { 
    let faqs = this.company.faqs

    if ( faqs ) { 
      faqs = faqs.slice()
      faqs.map(f => this.$set(f, 'isEditing', false))
    }

    if ( !faqs || faqs.length == 0) {
      faqs = [{question: "", answer: "", isEditing: true}]
    }

    return { 
      faqs: faqs
    }
  },
  props: ["company"],
  methods: {
    addQuestion() { 
      for ( let faq of this.faqs ) {
        faq.isEditing = false
      }
      this.faqs.push({question: "", answer: "", isEditing: true})
    },
    deleteAt(idx) {
      this.faqs.splice(idx, 1)
      this.$emit('input', this.faqs)
    },
    updateFAQs() { 
      this.$emit('input', this.faqs)
    }
  }
}
</script>
