<template>
  <div
    v-if="sortedData.length"
    class="timeline">
    <div
      class="timeline__item"
      v-for="(item, idx) in sortedData"
      :style="{'--index': idx}"
      :key="item._id"
    >
      <div class="content">
        <p
          v-if="item.date.length"
          class="content__date">
          {{ item.date }}
        </p>
        <h2
          v-if="item.title && item.title.length"
          class="content__heading"
          :class="titleClass(item)"
        >
          {{ item.title }}
        </h2>
        <vue-markdown v-if="item.text && item.text.length" :source="item.text"/>
        <div v-if="item.tags.length">
          <span
            class="content__tag"
            v-for="tag in item.tags"
            :key="tag"
          >
            {{ tag }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import parseISO from 'date-fns/parseISO'

export default {
  name: 'VerticalTimeline',

  props: {
    inputData: {
      type: Array,
      required: true,
      default: () => [],
    },
    reversed: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  methods: {
    titleClass(item) {
      return { 'title-only': !item.text || !item.text.length }
    }
  },
  computed: {
    sortedData() {
      let inputData = this.inputData.filter(d => d.text || d.title )

      inputData.sort((a, b) => {
        let a1 = parseISO(a.jsonDate)
        let b1 = parseISO(b.jsonDate)
        return a1 - b1
      })

      if (this.reversed) inputData.reverse()

      return inputData
    }
  }
}
</script>

<style scoped lang="scss">

.title-only {
  font-size: 1.0rem !important;
}

p {
  margin-bottom: 0.25rem !important;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;

  & * {
    box-sizing: border-box;
  }

  &::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #ccc;
    top: 0;
    bottom: 0;
    left: 90%;
    margin-left: -3px;
    border-radius: 10px;

    @media screen and (min-width: 1024px) {
      left: 50%;
    }
  }


  &__item {
    padding: 10px 20px 10px 0px;
    position: relative;
    background-color: inherit;
    width: 90%;

    @media screen and (min-width: 1024px) {
      width: 50%;
      &:nth-child(n+2) {
        margin-top: -60px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      right: -12.5px;
      background-color: #eee;
      border: 4px solid #0B5184;
      top: calc(50% - 16px);
      border-radius: 50%;
      z-index: 1;

      @media screen and (min-width) {
        top: calc(50% - 16px);
      }
    }

    &:nth-child(odd) {
      left: 0;

      @media screen and (min-width: 520px) {
        &::before {
          content: "";
          position: absolute;
          top: calc(50% - 6px);
          right: 0;
          width: 50%;
          z-index: 0;
          height: 4px;
          background-color: #0B5184;
        }
      }

      .content {
        @media screen and (min-width: 520px) {
          padding: 20px 40px 20px 30px;
          margin-right: 60px;

          &::before {
            content: '';
            position: absolute;
            height: 10px;
            width: 10px;
            top: 50%;
            right: -6px;
            background-color: #0B5184;
            border: 6px solid #0B5184;
            top: calc(50% - 10px);
            border-radius: 50%;
            z-index: 1;
          }
        }
      }
    }

    &:nth-child(even) {
      @media screen and (min-width: 520px) {
        &::before {
          content: "";
          position: absolute;
          top: calc(50% - 6px);
          right: 0;
          width: 50%;
          z-index: 0;
          height: 4px;
          background-color: #0B5184;

          @media screen and (min-width: 1024px) {
            left: 0;
          }
        }
      }

      @media screen and (min-width: 1024px) {
        left: 50%;
      }

      .content {
        @media screen and (min-width: 520px) {
          padding: 20px 50px 20px 30px;
          margin: 0 60px 0 0;

          &::before {
            content: '';
            position: absolute;
            height: 10px;
            width: 10px;
            top: 50%;
            right: -6px;
            background-color: #0B5184;
            border: 6px solid #0B5184;
            top: calc(50% - 10px);
            border-radius: 50%;
            z-index: 1;

            @media screen and (min-width: 1024px) {
              left: -5px;
            }
          }
        }

        @media screen and (min-width: 1024px) {
          padding: 20px 10px 20px 20px;
          margin: 0 0 0 60px;
        }
      }

      &::after {
        @media screen and (min-width: 1024px) {
          left: -12.5px;
        }
      }
    }
  }

  .content {
    padding: 10px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: white;
    position: relative;

    &__heading {
      font-size: 1.5rem;
      color: #0b5184;
    }

    &__date {
      font-family: times;
      font-style: italic;
    }

    &__tag {
      padding: px 10px;
      margin-right: 5px;
      background-color: #0b5184;
      font-size: 10px;
      color: #FFF;
      border-radius: 5px;
    }
  }
}
</style>

