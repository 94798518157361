<template>
  <div class="grid-list">
    <span>
      {{ investor.firstname }}
      {{ investor.lastname }}
    </span>
    <span class="text-right float-right">
      <a v-if="investor.linkedin" :href="investor.linkedin" target="_blank">
        <img src="/images/linkedin.svg" class="linkedin-image"/>
      </a>
    </span>
  </div>
</template>
<style scoped>
.linkedin-image {
  width: 25px;
  height: 25px;

}
</style>
<script>
  export default {
    props: [ "investor" ]
  }
</script>
