<template>
  <div class="position-relative">
    <div class="form-group">
      <label>Title</label>
      <input class="form-control" v-model="ev.title" :name="$fieldName('title')"/>
    </div>
    <div class="form-group">
      <label>Body</label>
      <markdown-editor v-model="ev.body" :name="$fieldName('body')"/>
    </div>
    <div class="form-group">
      <label>Event Date</label>
      <input class="form-control" type="date" v-model="ev.event_date" :name="$fieldName('event_date')"/>
    </div>
    <div v-if="!event.deleted">
      <button class="btn btn-primary" v-if="!ev.isNew" @click.prevent="$emit('cancel')">Cancel</button>
      <button class="btn btn-danger" @click.prevent="$emit('delete')">Delete</button>
    </div>
  </div>
</template>

<style scoped>
.delete-overlay {
  margin-bottom: -20px;
}
</style>

<script>
import axios from 'axios'
export default {
  props: [ "event" ],
  computed: {
    formPrefix() {
      return `traction[${this.event.id}]`
    }
  },
  watch: {
    ev: {
      handler() {
        this.$emit("input", this.ev)
      },
      deep: true
    }
  },
  data() {
    return { ev: { ... this.event } }
  }
}
</script>
