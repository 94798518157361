<template>
  <button @click.prevent="doSubscribe" class="btn btn-outline-primary">
    {{ text }}
    <i v-if="waitForRedirect" class="fa fa-spin fa-spinner ml-2"></i>
  </button>
</template>

<script>

import subscription from 'api/subscription.js'

export default {
  props: [ "text" ],
  data() {
    return {
      waitForRedirect: false
    }
  },
  methods: {
    doSubscribe() {
      this.waitForRedirect = true
      subscription.getCheckoutSession().then(r => {
        new Stripe(r.data['public-key'])
          .redirectToCheckout({sessionId: r.data['session-id']})
      })
    }
  }
}
</script>
