<template>
  <div>
    <div class="admin-container">
      <button class="btn btn-primary float-right" @click="inviting = true">
        <i class="far fa-envelope-open mr-2"></i>Invite admin
      </button>
      <h1>Manage access</h1>
      <div v-for="admin in editAdmins" class="row align-items-center mb-3 admin-row">
        <div class="col">
          <a :href="`/users/${admin.id}/edit`">
            <span v-if="admin.fullname">{{ admin.fullname }}</span>
            <span v-else>{{ admin.email }}</span>
          </a>
        </div>
        <div class="col text-right">
          <span v-if="admin.role == 'owner'">Owner</span>
          <span v-else-if="admin.role == 'removed'" class="text-danger">Removed</span>
          <span v-else>
            <i v-if="admin.roleChanging" class="fa fa-spinner fa-spin"></i>
            <i v-else-if="admin.roleChanged" class="fa fa-check text-success mr-2"></i>

            <mainstage-admin-roles
              show-make-owner="1"
              show-delete="1"
              :value="admin.role"
              @input="handleInput(admin, $event)"
              :success="admin.roleChanged"
            />
            <mainstage-errors :errors="admin.roleErrors"/>
          </span>
        </div>
      </div>
    </div>
    <manage-access-invite-modal :inviting="inviting" @sent="sent($event)" @close="inviting=false"/>
    <b-modal
      v-if="ownershipTransfer"
      centered
      :visible="!!ownershipTransfer"
      class="container-fluid mt-3"
      size="md"
      @cancel="ownershipTransfer = null"
      @close="ownershipTransfer = null"
      ok-variant="danger"
      ok-title="Transfer Ownership"
      @ok.prevent="doTransfer"
      title="Confirm ownership transfer">
      <div>
        Really transfer ownership to {{ ownershipTransfer.fullname }}?  After
        doing so you will be demoted to an admin and thus no longer be able to
        manage access on this account.
      </div>
      <div>{{ ownershipTransferConfirmation }}</div>
    </b-modal>
    <b-modal
      v-if="removeAdmin"
      centered
      :visible="!!removeAdmin"
      class="container-fluid mt-3"
      size="md"
      @cancel="removeAdmin = null"
      @close="removeAdmin = null"
      ok-variant="danger"
      ok-title="Remove Admin"
      @ok="doRemove()"
      title="Confirm admin removal">
      <div>
        Really remove {{ removeAdmin.fullname }}? This may not be undone.
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
.admin-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.admin-row {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}

</style>
<script>

import ManageAccessInviteModal from 'manage_access_invite_modal.vue'
import MainstageAdminRoles from 'components/mainstage_admin_roles.vue'
import axios from 'axios'
import api from 'api/api.js'

export default {
  components: { ManageAccessInviteModal, MainstageAdminRoles },
  props: ["admins"],
  data() {
    return {
      inviting: false,
      ownershipTransfer: null,
      removeAdmin: null,
      ownershipTransferConfirmation: "",
      editAdmins: this.admins.slice()
    }
  },
  methods: {
    handleInput(admin, to) {
      switch(to) {
        case 'admin':
        case 'agent':
          api.changeRole(admin, to, admin)
          break
        case 'remove':
          this.removeAdmin = admin
          break
        case 'owner':
          this.ownershipTransfer = admin
          break
      }
    },
    doRemove() {
      api.changeRole(this.removeAdmin, 'removed', this.removeAdmin)
      this.removeAdmin = null
    },
    doTransfer() {
      let parameters = {
        authenticity_token: this.$csrfToken,
        user_id: this.ownershipTransfer.id
      }

      axios.put("/access/transfer_ownership", parameters)
        .then(a => {
          this.ownershipTransferConfirmation = "Ownership transferred."
          setTimeout(() => window.location.href = "/", 1000)
        })
    },
    sent(user) {
      this.inviting = false
      this.editAdmins.push(user)
    }
  }
}
</script>
