<template>
  <div>
    <input
      class="form-control"
      :maxlength="maxlength"
      :class="classes"
      :name="$fieldName(field)"
      :id="$fieldName(field)"
      :type="type"
      :size="size"
      :placeholder="placeholder"
      :value="value"
      :data-lpignore="dataLpignore"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @input="$emit('input', $event.target.value)"
    />
    <mainstage-errors :errors="errList"/>
  </div>
</template>

<script>
  import ServerSideErrors from 'mixins/server_side_errors.js'

export default {
    mixins: [ ServerSideErrors ],
    props: [ "inputClass", "prefix", "field", "type", "size", "placeholder", "value", "data-lpignore", "maxlength" ],
    data() {
      return {
        formPrefix: this.prefix
      }
    },
    computed: {
      inputClasses() {
        if ( this.inputClass )
          return this.inputClass
        else
          return []
      },
      classes() {
        return this.errorClasses.concat(this.inputClasses)
      }
    }
  }
</script>
