<template>
  <original-vue-markdown :source="cleanedMarkdown" v-bind="$attrs">
     <slot></slot>
   </original-vue-markdown>
</template>
<script>
import OriginalVueMarkdown from '@adapttive/vue-markdown'

export default {
  components: { OriginalVueMarkdown },
  props: ["source"],
  computed: {
    cleanedMarkdown() {
      let markdown = this.source
      if ( !markdown || !markdown.replaceAll ) {
        return markdown
      }
      return markdown.replaceAll(/\*\*\s*(.*?)\s*\*\*/g, '**$1**')
    }
  }
}
</script>
