<template>
  <div>
    <form method="post" :action="formAction" ref="form">
      <csrf-token/>
      <input type="hidden" name="from_prompt" :value="fromPrompt"/>
      <email-from-chooser form-prefix="communication" @input="emailFrom = $event" id="email-from"/>
      <mainstage-text prefix="communication" v-model="communication.subject" label="Subject" field="subject" placeholder="Email subject line" :errors="communication.errors"/>
      <div class="form-group">
        <label for="communication_body">Body</label>
        <markdown-editor
          v-model="communication.body"
          field="body"
          placeholder="Your Message"
          name="communication[body]"
          :errors="communication.errors"
          :disable-underline="1"
        />
      </div>
      <div class="d-flex justify-content-between flex-wrap">
        <button @click.prevent="preview" class="btn" :class="btnClass">{{ btnText }}</button>
        <span v-if="showBackstageToggle" class="mt-3 my-sm-0">
          <input type="checkbox" v-model="backstage" name="communication[backstage]" value="1"/>
              Only send to shareholders
        </span>
      </div>
      <email-preview 
        @send="submit" 
        :subject='communication.subject' 
        :body='communication.body'
        ref='email-preview' 
        :to-text="previewToText"
        mail-type="communication_email"
       :email-from="emailFrom"
      />
    </form>
  </div>
</template>
<script>

import EmailPreview from 'email_preview.vue'
import EmailFromChooser from 'email_from_chooser.vue'

export default {
  props: ['from-prompt', 'communication', 'btn-text', 'btn-class', 'form-action', 'show-backstage-toggle', 'btn-backstage-text' ],
  data() {
    return {
      backstage: false, 
      emailFrom: 'replyTo'
    }
  },
  methods: {
    submit() {
      this.$refs['form'].submit()
    },
    preview() {
      this.$refs['email-preview'].getPreview()
    }
  },
  mounted() {
    if ( this.fromPrompt ) {
      setTimeout(() => this.$refs.form.scrollIntoView(), 250)
    }
  },
  computed: {
    previewToText() {
      if ( this.backstage )
        return "to shareholders"
      else
        return "to your entire audience"
    }
  },
  components: { EmailPreview, EmailFromChooser }
}
</script>


