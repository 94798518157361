import axios from 'axios'

let ajaxCheck = $.debounce((subdomain, target, errContainer) => {
  axios("/check_subdomain?subdomain=" + subdomain).then(j => {
    if ( target.val() != subdomain )
      return

    if ( j.data.available ) {
      target.addClass("is-valid")
      const host = window.location.host;

      errContainer.html("Your company's URL will be " + subdomain + "." + host)
      errContainer.addClass("text-success")
    } else {
      target.addClass("is-invalid")
      errContainer.html("Subdomain already taken")
      errContainer.addClass("text-danger")
    }
  })
}, 500, false)

let validateSubdomain = () => {
  let target = $("#company_subdomain")
  let subdomain = target.val()
  let errContainer = target.next("small")

  let ajax

  target.removeClass("is-invalid")
  target.removeClass("is-valid")
  errContainer.removeClass("text-danger")
  errContainer.removeClass("text-success")

  if ( !subdomain ) {
    target.removeClass("is-invalid")
    target.removeClass("is-valid")
    errContainer.html("")
  } else if ( !subdomain.match(/^[a-zA-Z0-9\-]+$/) ) {
    target.addClass("is-invalid")
    errContainer.addClass("text-danger")
    errContainer.html("Only letters, numbers and hyphens please.")
  } else {
    target.removeClass("is-invalid")
    errContainer.html("checking availability...")
    ajaxCheck(subdomain, target, errContainer)
    $("#subdomain-url").text(subdomain)
  }


}


function setupReferralForm(value) { 
  if ( ["event", "referral", "other"].includes(value) ) {
    $("#user_referral_text").show()
    let placeholder_text
    if ( value == "event" ) { 
      placeholder_text = "At what event?"
    } else if ( value == "referral" ) { 
      placeholder_text = "Who referred you?"
    } else {
      placeholder_text = "How did you hear of us?"
    }
    $("#user_referral_text").attr("placeholder", placeholder_text)
  } else {
    $("#user_referral_text").val("")
    $("#user_referral_text").hide()
  }
}

export default function() {
  $("#company_subdomain").on('input', validateSubdomain)
  $("#company_name").on('blur', (e) => {
    if ( $("#company_subdomain").val() == "" ) {
      let subbed = e.target.value.replace(/[^a-zA-Z0-9\-]/g, '')
      $("#company_subdomain").val(subbed)
      validateSubdomain()
    }
  })
  $("#user_referral").on('change', e => setupReferralForm(e.target.value))
  setupReferralForm($("#user_referral").val())
}
