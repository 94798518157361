// this is setup for the non-vue javascript part of mainstage, global components like the sidebar
// as well as signup forms and a few misc things.

import "./vue"
import axios from 'axios'

let fixupURL = (ev) => {
  let input = ev.target
  let value = input.value
  if ( value == "" )
    return

  if ( !value.startsWith("http://") && !value.startsWith("https://") )
    input.value = "https://" + input.value
}


import setupLocalTime from './local_time.js'
import setupSignupForm from './signup_form.js'
import formatCurrency from './vue/util/format_currency.js'
import "./activity"

$(document).ready(() => {
  $("input[type=url]").blur(fixupURL)

  window.msGlobals = $("#vue-globals").data()

  setupSignupForm()

  // synchronize entering edit with the currently chosen tab
  $("#company-edit-link").click(e => {
    e.preventDefault()
    window.location = $(e.target).attr("href") + window.location.hash
  })

  $('.toast').toast('show');

  $("#close-update-prompt").click(e => {
    e.preventDefault()
    axios.post("/communications/clear_notification").then(r => $(".update-prompt").addClass("hidden"))
  })


  let sidebar = $(".sidebar")
  $(".sidebar-toggle").click(e => {
    e.preventDefault()
    sidebar.toggleClass("opened")
  })

  $(".sidebar").find(".nav-link").click(() => {
    if ( sidebar.hasClass("opened") && window.location.pathname != '/')
      sidebar.removeClass("opened")
  })

  $(".currency").each((i, element) => {
    let value = $(element).data("amount")
    if ( !value )
      value = $(element).text()
    let noSmall = $(element).hasClass("currency-no-small")
    $(element).html(formatCurrency(value, !noSmall))
  })

  $('#contact-popover-link').popover({
    html: true,
    content: $('#contact-popover-content').html(),
    sanitize: false,
    title: $('#contact-popover-title')
  })



  $('#contact-popover-link').on('shown.bs.popover', () => {
    if ( msGlobals.currentUser.anonymous ) {
      $(".popover .contact-popover-email").focus()
    } else {
      $(".popover .contact-popover-body").focus()
    }

    $('.contact-popover-close').click((ev) => {
      ev.preventDefault()
      $('#contact-popover-link').popover('hide')
    })

    $(".popover .contact-popover-recaptcha-submit").click((ev) => {
      ev.preventDefault()
      grecaptcha.ready(() => {
        grecaptcha.execute(msGlobals.recaptchaSite, { action: 'submit' }).then((token) => {
          $(".popover #contact-popover-recaptcha-token").val(token)
          $(".popover #new_communication").submit()
        })
      })
    })
  })

  setupLocalTime()
})
