<template>
  <div class="purple-box">
    <h1>Deal Info</h1>
    <div class="form-group form-row">
      <label for="round[deal_type]" class="col-6 col-md-3 col-form-label mainstage-form-label">Deal Type</label>
      <div class="col-6 col-md-3 mb-3 mb-md-0">
        <select class="form-control" name="round[deal_type]" id="round[deal_type]" v-model="editRound.deal_type">
          <option value="equity">Equity</option>
          <option value="debt">Debt</option>
        </select>
      </div>
      <label for="round[price_per_share]" class="col-6 col-md-3 col-form-label text-md-right mainstage-form-label">Raise Amount</label>
      <div class="col-6 col-md-3 mb-3 mb-md-0">
        <mainstage-currency
          prefix="round"
          field="amount"
          placeholder="$1.5m"
          v-model="editRound.amount"
        />
      </div>
    </div>
    <template v-if="editRound.deal_type == 'equity'">
      <div class="form-group form-row">
        <label for="round[price_per_share]" class="col-6 col-md-3 col-form-label mainstage-form-label">Price Per Share/Unit</label>
        <div class="col-6 col-md-3 mb-3 mb-md-0">
          <mainstage-currency
            prefix="round"
            field="price_per_share"
            placeholder="$1.09"
            v-model="editRound.price_per_share"
          />
        </div>
        <label for="round[shares_offered]" class="col-6 col-md-3 col-form-label text-md-right mainstage-form-label">Shares/Units Offered</label>
        <div class="col-6 col-md-3">
          <input
            id="round[shares_offered]"
            name="round[shares_offered]"
            placeholder="1,000,000"
            class="form-control"
            v-model="editRound.shares_offered"
          />
        </div>
      </div>
      <div class="form-group form-row">
        <label for="round[min_amount]" class="col-6 col-md-3 col-form-label mainstage-form-label">Min Investment Amount</label>
        <div class="col-6 col-md-3 mb-3 mb-md-0">
          <mainstage-currency
            prefix="round"
            field="min_amount"
            placeholder="$10,000"
            v-model="editRound.min_amount"
          />
        </div>
        <label for="round[max_amount]" class="col-6 col-md-3 col-form-label text-md-right mainstage-form-label">Max Investment Amount</label>
        <div class="col-6 col-md-3">
          <mainstage-currency
            prefix="round"
            field="max_amount"
            placeholder="$100,000"
            v-model="editRound.max_amount"
          />
        </div>
      </div>
      <div class="form-group form-row">
        <label for="round[funding_stage]" class="col-6 col-md-3 col-form-label mainstage-form-label">Funding Stage</label>
        <div class="col-6 col-md-3 mb-3 mb-md-0">
          <mainstage-input
            prefix="round"
            field="funding_stage"
            placeholder="Series A"
            v-model="editRound.funding_stage"
          />
        </div>
        <label for="round[pre_money_valuation]" class="col-6 col-md-3 col-form-label text-md-right mainstage-form-label">Pre-Money Valuation</label>
        <div class="col-6 col-md-3">
          <mainstage-currency
            prefix="round"
            field="pre_money_valuation"
            placeholder="$1.5m"
            v-model="editRound.pre_money_valuation"
          />
        </div>
      </div>
      <div class="form-group form-row">
        <label for="round[funding_security_type]" class="col-6 col-md-3 col-form-label mainstage-form-label">Funding Security Type</label>
        <div class="col-6 col-md-9 mb-3 mb-md-0">
          <mainstage-input
            prefix="round"
            field="funding_security_type"
            placeholder="Preferred Shares, Convertible Note, SAFE Note"
            v-model="editRound.funding_security_type"
          />
        </div>
      </div>
    </template>
    <template v-else-if="editRound.deal_type == 'debt'">
      <div class="form-group form-row">
        <label for="round[interest_rate_pct]" class="col-6 col-md-3 col-form-label mainstage-form-label">Interest Rate %</label>
        <div class="col-6 col-md-3 mb-3 mb-md-0">
          <mainstage-input
            prefix="round"
            field="interest_rate_pct"
            placeholder="8%"
            v-model="editRound.interest_rate_pct"
          />
        </div>
        <label for="round[discount_rate]" class="col-6 col-md-3 col-form-label text-md-right mainstage-form-label">Discount Rate</label>
        <div class="col-6 col-md-3">
          <mainstage-input
            prefix="round"
            field="discount_rate"
            placeholder="30%"
            v-model="editRound.discount_rate"
          />
        </div>
      </div>
      <div class="form-group form-row">
        <label for="round[min_amount]" class="col-6 col-md-3 col-form-label mainstage-form-label">Min Investment Amount</label>
        <div class="col-6 col-md-3 mb-3 mb-md-0">
          <mainstage-currency
            prefix="round"
            field="min_amount"
            placeholder="$10,000"
            v-model="editRound.min_amount"
          />
        </div>
        <label for="round[max_amount]" class="col-6 col-md-3 col-form-label text-md-right mainstage-form-label">Max Investment Amount</label>
        <div class="col-6 col-md-3">
          <mainstage-currency
            prefix="round"
            field="max_amount"
            placeholder="$100,000"
            v-model="editRound.max_amount"
          />
        </div>
      </div>
      <div class="form-group form-row">
        <label for="round[funding_stage]" class="col-6 col-md-3 col-form-label mainstage-form-label">Funding Stage</label>
        <div class="col-6 col-md-3 mb-3 mb-md-0">
          <mainstage-input
            prefix="round"
            field="funding_stage"
            placeholder="Series A"
            v-model="editRound.funding_stage"
          />
        </div>
        <label for="round[maturity_date]" class="col-6 col-md-3 col-form-label text-md-right mainstage-form-label">Maturity Date</label>
        <div class="col-6 col-md-3">
          <mainstage-input
            prefix="round"
            type="date"
            field="maturity_date"
            placeholder="2020-01-01"
            v-model="editRound.maturity_date"
          />
        </div>
      </div>
      <div class="form-group form-row">
        <label for="round[funding_security_type]" class="col-6 col-md-3 col-form-label mainstage-form-label">Funding Security Type</label>
        <div class="col-6 col-md-9 mb-3 mb-md-0">
          <mainstage-input
            prefix="round"
            field="funding_security_type"
            placeholder="Preferred Shares, Convertible Note, SAFE Note"
            v-model="editRound.funding_security_type"
          />
        </div>
      </div>
      <div class="form-group form-row">
        <div class="col-12 col-md-6">
          <label for="round[conversion_rights]" class="mainstage-form-label">Conversion Rights</label>
          <textarea
            class="form-control"
            v-model="editRound.conversion_rights"
            name="round[conversion_rights]"
            id="round[conversion_rights]"
            rows=5
            placeholder="Convertible Promissory Note - Seed Round"
          />
        </div>
      </div>
    </template>
    <div class="form-group form-row">
      <div class="col-12 col-md-6">
        <label for="round[additional_info]" class="mainstage-form-label">Additional Information</label>
        <textarea
          class="form-control"
          v-model="editRound.additional_info"
          name="round[additional_info]"
          id="round[additional_info]"
          rows=5
          placeholder="Anything else about your fundraise?"
        />
      </div>
    </div>
    <h2>Use of Funds</h2>
    <p>How you'll use the funds you raise.</p>
    <div class="form-group form-row" v-for="(n, index) in 5" :key="index">
      <div class="col-12 col-lg-8">
        <input
          class="form-control"
          name="round[use_of_funds_bullets][]"
          :id="`round[use_of_funds_bullets][${index}]`"
          type="text"
          placeholder=""
            v-model="editRound.use_of_funds_bullets[index]"
        />
      </div>
    </div>
    <div class="form-group form-row">
      <div class="col-12 col-lg-8">
        <markdown-editor
          id="user_of_funds_text" name="round[use_of_funds_text]" 
          disable-underline="true"
        v-model="editRound.use_of_funds_text" placeholder="Additional use of funds information"
      />
      </div>
    </div>
    <h2>Options</h2>
    <div class="form-group form-row">
      <div class="col-12 col-md-6">
        <input
          type="checkbox"
          v-model="editRound.hide_goal"
          name="round[hide_goal]"
          id="round[hide_goal]"
        />
        <label for="round[hide_goal]" class="mainstage-form-label">Hide raise progress (don't show green bar)</label>
      </div>
    </div>
    <div class="form-group form-row">
      <div class="col-12 col-md-6">
        <input
          type="checkbox"
          v-model="editRound.accepts_ira"
          name="round[accepts_ira]"
          id="round[accepts_ira]"
        />
        <label for="round[accepts_ira]" class="mainstage-form-label">Company can accept IRA investments</label>
      </div>
    </div>
    <input type="hidden" name="round[show_example]" :value="editRound.show_example && !modified">
  </div>
</template>
<script>
import MainstageText from "./components/mainstage_text.vue"

export default {
  props: [ "round" ],
  data() {
    let editRound = Object.assign({}, this.round)
    if ( !editRound.deal_type  )
      editRound.deal_type = "equity"

    if ( !editRound.use_of_funds_bullets ) {
      editRound.use_of_funds_bullets = []
    }

    for ( let i in [0,1,2,3,4] ) { 
      if ( editRound.use_of_funds_bullets[i] === undefined ) 
        editRound.use_of_funds_bullets[i] = ""
    }

    return {
      modified: false,
      editRound: editRound
    }
  },
  watch: {
    editRound: {
      deep: true,
      handler() {
        this.modified = true
        this.editRound.show_example = false
        this.$emit("input", this.editRound)
      }
    }
  },
  components: {
    MainstageText
  }

}
</script>

