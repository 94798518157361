<template>
  <input type="hidden" name="authenticity_token" :value="token"/>
</template>

<script>
  export default {
    data() {
      return {
        token: this.$csrfToken
      }
    }
  }
</script>
