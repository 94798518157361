import { render, staticRenderFns } from "./deleted_overlay.vue?vue&type=template&id=2723869c&scoped=true&"
import script from "./deleted_overlay.vue?vue&type=script&lang=js&"
export * from "./deleted_overlay.vue?vue&type=script&lang=js&"
import style0 from "./deleted_overlay.vue?vue&type=style&index=0&id=2723869c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2723869c",
  null
  
)

export default component.exports