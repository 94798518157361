<template>
  <b-dropdown :text="labelFor(value)" right offset="10" :variant="success ? 'outline-success' : 'outline-primary'" class="role-button">
    <b-dropdown-item class="role-item" @click="$emit('input', 'admin')">
      <div class="role-header">Admin</div>
      <div class="role-text">
        Can perform any task on your site except making more admins.
      </div>
    </b-dropdown-item>
    <b-dropdown-item class="role-item" @click="$emit('input', 'agent')">
      <div class="role-header">Agent</div>
      <div class="role-text">
        May invite and communicate with your audience, but may not update
        site data.
      </div>
    </b-dropdown-item>
    <b-dropdown-item v-if="showMakeOwner" class="role-item" @click="$emit('input', 'owner')">
      <div class="role-header">Make owner</div>
      <div class="role-text">
        Transfer ownership of this Main Stage instance to this user.
      </div>
    </b-dropdown-item>
    <b-dropdown-item v-if="showDelete" class="role-item" @click="$emit('input', 'remove')">
      <div class="role-header text-danger">Remove</div>
      <div class="role-text">
        Remove this user.
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  props: [ "show-make-owner", "show-delete", "value", "success" ],
  methods: {
    labelFor(key) {
      return this.$roleHash[key].label
    }
  }
}
</script>


