<template>
  <div>
    <b-nav class="my-2 d-none d-md-flex">
      <template v-for="item in filterItems">
        <b-nav-item class="investor-filter-item" :class="{ selected: activePill == item.key, searching: searching }"
          @click.prevent="activateItem(item.key)" :disabled="activeItem == item.key || searching">{{ item.label }}</b-nav-item>
      </template>
    </b-nav>
    <select class="d-md-none form-control filter-select mb-2" :disabled="searching" v-model="activeItem" @change="activateItem($event.target.value)">
      <option v-for="item in filterItems" :value="item.key">{{ item.label }}</option>
    </select>
  </div>
</template>

<style lang="scss">
@import 'vars';

.filter-select {
  max-width: 500px;
}

.investor-filter-item a {
  outline: none;
  font-weight: bold;
  color: #636363;
  padding: 0.25em 1em;
}

.investor-filter-item.selected a {
  border-radius: 30px;
  background-color: $mainstage-purple;
  color: white;
  font-weight: 600;
}

.investor-filter-item.selected.searching a {
  background-color: #ccc;
}
</style>
<script>
import HashChange from 'mixins/hash_change.js'

export default {
  props: [ 'searching' ],
  data() {
    return {
      activeItem: 'invited'
    }
  },
  mounted() {
    this.hashChange()
  },
  methods: {
    hashChange() {
      if ( location.hash ) {
        let hash = location.hash.replace('#', '')
        this.activeItem = hash.split('/')[0]
      }
    },
    activateItem(key) {
      this.activeItem = key
      this.$emit('input', key)
    },
  },
  computed: {
    activePill() {
      if ( this.searching )
        return 'all'
      else
        return this.activeItem
    },
    filterItems() {
      return [
        { key: 'invited', label: 'Invited' },
        { key: 'prospect', label: 'Guests' },
        { key: 'vip', label: 'VIPs' },
        { key: 'committed', label: 'Committed' },
        { key: 'backstage', label: 'Shareholders' },
        { key: 'removed', label: 'Removed' },
        { key: 'all', label: 'All' }
      ]
    }
  },
  mixins: [ HashChange ]
}
</script>
