<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-auto mr-5 px-0">
        <b>Activity</b>
        <table class="detail-table">
          <tr>
            <td>Visits in the last 7 days:</td>
            <td>{{ company.activity.week_visits }}</td>
          </tr>
          <tr>
            <td>Visits total:</td>
            <td>{{ company.activity.total_visits }}</td>
          </tr>
          <tr>
            <td>Updates in the last 7 days:</td>
            <td>{{ company.activity.week_updates }}</td>
          </tr>
          <tr>
            <td>Updates total:</td>
            <td>{{ company.activity.total_updates }}</td>
          </tr>
          <tr>
            <td>Documents viewed in the last 7 days:</td>
            <td>{{ company.activity.week_documents }}</td>
          </tr>
          <tr>
            <td>Documents viewed total:</td>
            <td>{{ company.activity.total_documents }}</td>
          </tr>
        </table>
      </div>
      <div class="col-auto">
        <b>Audience By Type</b>
        <table class="detail-table">
          <tr v-for="role in investorsByRole">
            <td>{{ role.name }}:</td>
            <td>{{ role.count }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto px-0">
        <table class="detail-table">
          <tr>
            <td><b>Company Created:</b></td>
            <td>{{ company.created_at | formatDatetime }}</td>
          </tr>
          <tr>
            <td><b>First Invite Sent:</b></td>
            <td>{{ company.first_invite_on | formatDatetime }}</td>
          </tr>
        </table>
        <table v-if="company.subscription" class="subscription-table mt-2">
          <subscription-row :subscription="company.subscription"/>
          <a v-if="company.subscription.name != 'grandfather'" :href="`/admin/sponsor?id=${company.company_id}`" class="btn btn-primary btn-small">Sponsor company</a>
          <a :href="`/admin/archive?id=${company.company_id}`" class="btn btn-danger btn-small ml-2">Archive company</a>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
.detail-table {
  margin-top: 5px;
}

.detail-table tr {
  border: 0;
}

.detail-table td {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 1em;
}

.detail-table td:nth-child(2) {
  text-align: right;
  font-weight: bold;
  font-size: 110%;
  font-family: monospace;
}

.subscription-table tr {
  border-bottom: none !important;

}
</style>
<script>
import SubscriptionRow from 'subscription_row.vue'

export default {
  props: [ "company", "detail" ],
  components: { SubscriptionRow },
  computed: {
    investorsByRole() {
      return this.$globals.roles
        .filter(r => r.type == 'investor' && r.key != 'removed')
        .map(r => {
          return {
            name: r.label,
            count: this.detail.investors[r.key]
          }
        })
    }
  }
}
</script>
