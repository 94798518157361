import Vue from 'vue'
import axios from 'axios'

export default {
  getCheckoutSession() {
    let parameters = {
      authenticity_token: Vue.prototype.$csrfToken,
    }

    return axios.post(`/subscription/session.json`, parameters)
  }
}
