<template>
  <div>
    <div :class="{ 'middle-row': !preview }">
      <div v-show="preview" id="company-profile">
        <CompanyProfile :company="previewCompany" :editTab="profileTabForEditTab"/>
      </div>
      <ValidationObserver ref="observer">
        <HashTabs v-show="!preview"
          @input="currentHash = $event"
          class="bg-white"
          ref="hashTabs"
          nav-class="mainstage-edit-tabs">
          <b-tab v-if="$globals.currentUser.role == 'root'" title="Mainstage admin" id="admin">
            <MainstageAdminEdit
              :company="previewCompanyData"
              @input="previewCompanyData = $event"
            />
          </b-tab>
          <b-tab title="Basics" id="basics">
            <BasicsEdit
              :company="previewCompanyData"
              @input="previewCompanyData = $event"
              @upload-start="uploadStart"
              @upload-progress="uploadProgress"
              @upload-complete="uploadComplete"
              @pitch-deck-upload="pitchDeckUpload"
              @pitch-deck-delete="pitchDeckDelete"
            />
          </b-tab>
          <b-tab title="Team" id="team">
            <TeamEdit :members="previewTeamData" @input="previewTeamData = $event"/>
          </b-tab>
          <b-tab title="FAQ" id="faq">
            <FAQEdit
              :company="previewCompanyData"
              @input="previewFAQ = $event"
            />
          </b-tab>
          <b-tab title="Documents" id="documents">
            <DocumentEdit :documents="company.documents" @input="previewDocuments = $event"
              @upload-start="uploadStart" @upload-progress="uploadProgress" @upload-complete="uploadComplete"/>
          </b-tab>
          <b-tab title="Deal Info" id="deal">
            <DealInfoEdit :round="previewRoundData" @input="previewRoundData = $event"/>
          </b-tab>
          <b-tab title="Timeline" id="timeline">
            <TractionEdit :traction_events="company.traction_events" @input="previewTractionEvents = $event"/>
          </b-tab>
          <b-tab id="links">
            <SocialMediaEdit :links="company.links" :company_url="previewCompany.url" @link-update="socialLinkUpdate" @company-url-update="previewCompany.url = $event"/>
          </b-tab>
          <b-tab id="facts">
            <MarketFactsEdit :facts="company.key_metrics" :errors="company.errors"/>
          </b-tab>
          <b-tab id="media">
            <MoreMediaEdit :media="company.media" :errors="company.errors" :reg_cf="company.reg_cf" @input="previewMoreMedia = $event" 
              @upload-start="uploadStart" @upload-progress="uploadProgress" @upload-complete="uploadComplete" />
          </b-tab>
        </HashTabs>
      </ValidationObserver>
      <input type="hidden" name="current_tab" :value="profileTabForEditTab"/>
    </div>
    <div class="px-3 py-2 editor-form-footer" :class="{ changed: hasChanges }">
      <div class="d-flex align-items-center" v-if="uploading">
        <div class="flex-grow-1 mr-3">
          <b-progress v-if="progress > 0 && progress <= 1" :value="progress" max="1" class="m-auto"></b-progress>
        </div>
        <div>
          <span v-if="processing">processing</span>
          <span v-else>uploading</span>
          {{ uploading }} file(s), please wait...
        </div>
      </div>
      <div v-else class="d-flex">
        <div class="flex-grow-1">
          <span class="text-danger" v-if="hasErrors">We couldn't save your changes.  Please fix the errors and try again.</span>
          <span v-else>
            <span class="d-none d-sm-inline" v-if="preview">
              Previewing changes as guest.
            </span>
            <span v-else>
              You have unsaved changes.
            </span>
          </span>
        </div>
        <div class="text-right">
          <a id="editor-form-save" class="mr-3" href="#" @click.prevent="preview=!preview">
            <span v-if="preview">
              Return to editing
            </span>
            <span v-else-if="!hasErrors">
              Preview changes
            </span>
          </a>
          <button id="editor-form-save" 
            @click="saveForm($event)" 
            class="btn btn-primary"
            :disabled="isSaving"
            ref="save-button"
          >
            <i v-if="isSaving" class="fa fa-spinner fa-spin"></i>
            <span v-else>Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.mainstage-edit-tabs {
  display: none !important;
}
</style>

<style scoped lang="scss">
@import 'vars';

.editor-form-footer {
  line-height: 60px; /* Vertically center the text there */
  display: block;
  position: fixed;
  height: 72px;
  bottom: -72px;
  left: 0px;
  width: 100%;
  background-color: rgba(220, 220, 220, 0.8);
  z-index: 55;
  transition-property: bottom;
  transition-duration: .2s;
}

.editor-form-footer.changed {
  bottom: 0px;
}

@media (min-width: 992px) {
  .editor-form-footer {
    left: $mainstage-sidebar-width;
    width: calc(100% - #{$mainstage-sidebar-width});
  }

  body.preview .editor-form-footer {
    left: 0px !important;
    width: 100%;
  }
}

</style>

<script>
  let Diff = require('diff')
  import MainstageAdminEdit from 'company_edit_mainstage_admin.vue'
  import BasicsEdit from 'company_edit_basics.vue'
  import TeamEdit from 'team_edit.vue'
  import FAQEdit from 'faq_edit.vue'
  import DocumentEdit from 'document_edit.vue'
  import DealInfoEdit from 'deal_info_edit.vue'
  import CompanyProfile from 'company_profile.vue'
  import TractionEdit from 'traction_edit.vue'
  import HashTabs from 'components/hash_tabs.vue'
  import SocialMediaEdit from 'company_edit_social_media.vue'
  import MarketFactsEdit from 'market_facts_edit.vue'
  import MoreMediaEdit from 'more_media_edit.vue'
  import scrollWithOffset from 'util/scroll_with_offset.js'

  export default {
    props: [ "company" ],
    components: { MainstageAdminEdit, BasicsEdit, TeamEdit, FAQEdit, HashTabs, CompanyProfile, DocumentEdit, DealInfoEdit, TractionEdit, SocialMediaEdit, MarketFactsEdit, MoreMediaEdit },
    data() {
      if ( !this.company.key_metrics )
        this.company.key_metrics = []

      return {
        isSaving: false,
        hasChanges: false,
        hasErrors: false,
        lastSerialized: null,
        previewCompanyData: { ... this.company },
        previewDocuments: this.company.documents.slice(),
        previewTeamData: this.company.team_members.slice(),
        previewRoundData: { ... this.company.active_round },
        previewTractionEvents: this.company.traction_events,
        previewMoreMedia: this.company.media,
        previewFAQ: this.company.faqs,
        preview: false,
        currentHash: null,
        uploads: {}
      }
    },
    mounted() {
      window.addEventListener('beforeunload', e => {
        let devMode = window.location.href.match(/localhost/)
        if ( !devMode && this.hasChanges && !this.isSaving ) {
          e.preventDefault();
          e.returnValue = '';
        }
      })
      this.$nextTick(() => this.lastSerialized = JSON.stringify(this.previewCompany))
    },
    watch: {
      preview(isPreview) {
        if ( isPreview ) {
          $("body").addClass("preview")
        } else {
          $("body").removeClass("preview")
        }
      },
      previewCompany: {
        deep: true,
        handler: function(company) {
          let newStr = JSON.stringify(company)

          console.log("previewCompany watch triggered")
          if ( this.lastSerialized && this.lastSerialized != newStr ) {
            // console.log(Diff.diffChars(this.lastSerialized, newStr))
            console.log("previewCompany watch hasChanges")
            this.hasChanges = true
            this.hasErrors = false
          } else {
            console.log("previewCompany has no changes")
          }
        }
      }
    },
    methods: {
      uploadStart(doc) {
        this.hasChanges = true
        this.$set(this.uploads, doc.id, {status: "uploading", progress: 0 })
      },
      uploadProgress(doc) {
        this.hasChanges = true
        this.uploads[doc.id].progress = doc.progress
        if ( doc.progress == 1 )
          this.uploads[doc.id].status = "processing"
      },
      uploadComplete(doc) {
        this.hasChanges = true
        this.uploads[doc.id].status = "complete"
      },
      pitchDeckUpload(upload_id) {
        this.hasChanges = true
        this.previewCompanyData.pitch_deck_url = "/upload/" + upload_id;
      },
      pitchDeckDelete() {
        this.hasChanges = true
        this.previewCompanyData.pitch_deck_url = null;
      },
      socialLinkUpdate(name, value) {
        this.$set(this.previewCompanyData.links, name, value)
      },
      saveForm(ev) {
        let observer = this.$refs.observer
        this.hasErrors = false
        this.isSaving = true

        observer.validate().then(success => {
          if (!success) {
            ev.preventDefault();
            this.isSaving = false;
            // vee-validate is sometimes, but not always able to tell us what fields
            // failed immediately
            setTimeout(() => {
              for (const key of Object.keys(observer.fields).sort()) {
                if (observer.fields[key].invalid) {
                  let tab = key.split(".")[0]
                  this.$refs.hashTabs.activateByHash(tab)
                  this.preview = false
                  this.hasErrors = true

                  this.$nextTick(() => {
                    let el = observer.refs[key].$el
                    scrollWithOffset(el)
                    let input = el.querySelector("input")

                    input && input.focus({ preventScroll: true})
                  })
                  return
                }
              }
            }, 100)
          } else { 
            const form = this.$refs["save-button"].closest("form")
            form.submit();
          }
        })
      }
    },
    computed: {
      activeUploads() {
        return Object.values(this.uploads).filter(u => u.status != 'complete')
      },
      uploading() {
        return this.activeUploads.length
      },
      processing() {
        return this.activeUploads.every(u => u.status == "processing")
      },
      progress() {
        let sum = this.activeUploads.reduce((a, b) => b.progress + a, 0)
        return sum / this.activeUploads.length
      },
      previewCompany() {
        let company = this.previewCompanyData

        company.team_members = this.previewTeamData
        company.active_round = this.previewRoundData
        company.traction_events = this.previewTractionEvents
        company.documents = this.previewDocuments
        company.media = this.previewMoreMedia
        company.faqs = this.previewFAQ

        return company
      },
      profileTabForEditTab() {
        let tab = this.currentHash
        switch(tab) {
          case "#documents":
            return "#deal"
          case "#basics":
          case "#admin":
            return "#story"
          case "#links":
            return "#story"
          default:
            return tab
        }
      }
    }
  }
</script>


