<template>
    <div class="metric-box">
      <div class="metric-number">{{ metric.number }}</div>
      <div class="metric-unit">{{ metric.unit }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'vars';

.metric-box {
  border: 1px solid #ccc;
  padding: 10px 20px;
  text-align: center;
  background-color: #fff;
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 645px) {
  .metric-box {
    width: 300px;
  }
}

.metric-number {
  font-size: 40px;
  color: $mainstage-red;
  margin: 10px 0px;
}

.metric-unit {
  font-size: 20px;
  color: #4a4a4a;
  margin: 10px 0px;
}
</style>

<script>
export default {
  props: [ "metric" ]
}
</script>
