<template>
  <example-overlay :is-example="docs.isExample" edit-link="/edit#documents">
    <documents-folder :folder="tree" :expanded="true" :is-example="docs.isExample"/>
  </example-overlay>
</template>

<script>
import ExampleData from 'mixins/example_data.js'
import Document from 'document.vue'
import DocumentsFolder from 'documents_folder.vue'

let exampleDocuments = [
  { document_type: 'investor_pitch_deck', id: 1 },
  { document_type: 'articles_of_incorporation', id: 2 },
  { document_type: 'convertible_note', id: 3 },
  { document_type: 'other', id: 4, title: 'Series A Investor Voting Document', required_role: 20 },
  { document_type: 'other', id: 5, title: 'Patent Portfolio', required_role: 20 },
  { document_type: 'other', id: 6, title: 'Q3 2020 Quarterly Report', folder: '2020 Investor Updates', required_role: 30 }
]

export default {
  components: { DocumentsFolder },
  mixins: [ ExampleData ],
  name: "documents",
  props: ["documents", "pitch-deck-id"],
  computed: {
    docs() {
      return this.exampleData(this.documents, exampleDocuments)
    },
    tree() {
      let root = { type: 'root', items: [], expanded: true }
      let docs = this.docs.data

      for (let d of docs) {
        if ( !d.folder || d.folder.length == 0 ) {
          root.items.push(d)
        } else {
          let folders = d.folder.split(' / ')
          let folder = root

          folders.forEach(name => {
            let f = folder.items.find(i => i.type == 'folder' && i.name == name)
            if ( !f ) {
              f = { type: 'folder', name: name, items: [], expanded: this.showExamples }
              folder.items.push(f)
            }
            folder = f
          })
          folder.items.push(d)
        }
      }
      return root
    }
  }
}
</script>
