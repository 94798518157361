<template>
  <div class="purple-box">
    <b-modal v-if="showRenew" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc centered v-model="showRenew" size="lg" title="Subscription expired">
      <div class="p-4" v-if="this.subscription.name == 'expired_trial'">
        <p>
          <span v-if="isOwner">
            Please subscribe to a subscription plan to access The Main Stage.
          </span>
          <span v-else>
            Please ask the site's owner to subscribe to a plan.
          </span>
        </p>
        <div class="text-center">
          <subscribe-now-button v-if="isOwner" text="Subscribe Now"/>
        </div>
      </div>
      <div v-else>
        <p>
            Your subscription was canceled on {{ this.subscription.ends_at | formatDatetime }}.
            Your audience will retain access to their investor dashboard and your story vault for 14 days,
            after which time they will lose access.
          <span v-if="isOwner">
            To prevent this, please renew your subscription.
          </span>
          <span v-else>
            To prevent this, please ask the site's owner to renew the subscription.
          </span>
        </p>
        <div class="text-center">
          <subscribe-now-button v-if="showRenew && isOwner" text="Renew now"/>
        </div>
      </div>
    </b-modal>
    <table class="mainstage-table w-100 mb-2">
      <tr>
        <th>Plan</th>
        <th>Status</th>
        <th>Date</th>
      </tr>
      <subscription-row :subscription="subscription"/>
    </table>
   <a href="/subscription/portal" class="btn btn-primary">Manage subscription</a>
  </div>
</template>

<script>
import SubscriptionRow from 'subscription_row.vue'
import SubscriptionUpsell from 'subscription_upsell.vue'
import SubscribeNowButton from 'subscribe_now_button.vue'

export default {
  props: ["subscription"],
  components: { SubscriptionRow, SubscriptionUpsell, SubscribeNowButton },
  computed: {
    isOwner() {
      return this.$globals.currentUser.role == 'owner'
    },
    showRenew() {
      return ["canceled", "expired"].includes(this.subscription.status)
    }
  }
}
</script>
