import Vue from 'vue'


import { TabsPlugin, NavPlugin, ProgressPlugin, ModalPlugin, TooltipPlugin, DropdownPlugin } from 'bootstrap-vue'
Vue.use(TabsPlugin)
Vue.use(NavPlugin)
Vue.use(ProgressPlugin)
Vue.use(ModalPlugin)
Vue.use(TooltipPlugin)
Vue.use(DropdownPlugin)

import VueCroppie from 'vue-croppie'
Vue.use(VueCroppie)

import VueMarkdown from 'components/mainstage_vue_markdown.vue'
Vue.component('vue-markdown', VueMarkdown)

import VuePluralize from 'vue-pluralize'
Vue.use(VuePluralize)

import CKEditor from '@ckeditor/ckeditor5-vue'
Vue.use(CKEditor)

// vee-validate
//
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';


// Add a rule.
extend('required', {
  ...required,
  message: '{_field_} is required'
})

extend('max', {
  ...max,
  message: 'too long!'
})

configure({
  classes: {
    invalid: 'is-invalid'
  }
})

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

