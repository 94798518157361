<template>
  <div>
    <DocumentEditRow
      v-if="pitch"
      :document="pitch"
      :hide-delete="hideDelete"
      document-type="investor_pitch_deck"
      @upload-start="$emit('upload-start', $event)"
      @upload-progress="onUploadProgress"
      @upload-complete="$emit('upload-complete', $event)"
      accept="application/pdf"
      @input="$emit('input', $event)"
      :upload_id="pitch.upload_id"
    />
    <FileDragTarget class="d-none d-sm-block" v-else fileInput="child" @change=uploadDocuments @dragstart="dragging = true" @dragend="dragging = false">
      <div class="big-drag-target d-flex flex-column justify-content-center align-items-center" :class='{dragging: dragging}'>
        <div class="mb-1 p-2">
          Drag your investor presentation here...
        </div>
        <label class="btn btn-secondary d-inline-block">
          Or choose a file...
          <input type="file" accept="application/pdf" @change=uploadDocuments hidden/>
        </label>
        <div class="text-secondary mt-2">
          We only accept <i>PDF</i> files and recommend a <b>16:9</b> aspect ratio.
        </div>
      </div>
    </FileDragTarget>
    <!-- mobile, don't bother showing drag-drop target. -->
    <label class="btn btn-primary d-inline-block d-sm-none">
      Choose a file...
      <input type="file" accept="application/pdf" @change=uploadDocuments hidden/>
    </label>
    <div v-if="showSizeWarning" class="alert alert-danger">
      Warning: your presentation is {{ humanSize }} in size.  While we'll happily serve that for you,
      it may be slow to view, especially over mobile connections.
      We suggest that you try compressing the PDF:
      <a href="https://www.adobe.com/acrobat/online/compress-pdf.html" target="_blank">this
        Adobe website can help you do that.</a>
    </div>
  </div>
</template>
<style scoped>
.big-drag-target {
  height: 160px;
  border: 2px dashed #ccc;
  padding: 10px;
}
.big-drag-target.dragging {
  border: 2px dashed #333;
}
</style>

<script>

import DocumentEditRow from 'document_edit_row.vue'
import FileDragTarget from 'components/file_drag_target.vue'
import UploadWithProgress from 'mixins/upload_with_progress.js'

export default {
  props: ["pitchDoc", "hide-delete"],
  components: { DocumentEditRow, FileDragTarget },
  mixins: [UploadWithProgress],
  data() {
    return {
      fileSize: 0,
      dragging: false,
      pitch: this.pitchDoc
    }
  },
  methods: {
    onUploadProgress(ev) {
      this.fileSize = ev.total
      this.$emit('upload-progress', ev)
    },
    uploadDocuments(data) {
      let file
      if( data.target )
        file = data.target.files[0]
      else
        file = data[0]

      let doc = {
        id: "_new_9999999",
        document_type: "investor_pitch_deck",
        title: null,
        filename: file.name,
        upload_id: null,
        progress: 0,
        required_role: 10
      }

      this.uploadWithProgress(doc, file, { onUploadProgress: this.onUploadProgress })
      this.pitch = doc
    }
  },
  computed: {
    showSizeWarning() {
      return this.fileSize > 10000000
    },
    humanSize() {
      if ( this.fileSize > 1000000000 ) {
        return (this.fileSize / 1000000000).toFixed(1) + "GB"
      } else {
        return (this.fileSize / 1000000).toFixed(1) + "MB"
      }
    }
  }
}
</script>

