<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: [ 'fileInput' ],
  mounted() {
    let el = $(this.$el).children().first()

    el.on("dragenter", e => {
      e.preventDefault()
      this.$emit("dragstart")
      this.dragging = true
    })

    el.on("dragleave", e => {
      e.preventDefault()
      this.$emit("dragend")
      this.dragging = false
    })

    el.on("dragover", e => e.preventDefault())

    el.on("drop", (e) => {
      e.preventDefault()

      let files = e.originalEvent.dataTransfer.files

      let input
      if ( this.fileInput == "child" ) {
        input = el.find("input[type='file']")
      } else {
        input = $(this.fileInput)
      }

      input[0].files = files
      input.trigger('change', files)

      this.$emit("change", files)
      this.$emit("dragend")
    });
  }
}

</script>
