<template>
  <form method="post" action="/audience/invite" ref="form">
  <csrf-token/>
  <div class="mt-3 purple-box container-fluid">
    <h1 v-if="!csvUpload">Invite New Prospect
      <h6><a href="#" @click.prevent="csvUpload=true" class="text-small">CSV upload</a></h6>
    </h1>
    <h1 v-else>
      Invite via CSV upload
      <h6><a href="#" @click.prevent="csvUpload=false" class="text-small">Cancel</a></h6>
    </h1>
    <investor-invite-csv v-if="csvUpload" class="my-4" @user-count="csvUserCount = $event"/>
    <template v-else> 
      <div class="row mb-2">
        <div class="col">
          <label>Type:</label>
          <mainstage-investor-roles v-model="role" size="sm" class="ml-2"/>
          <input type="hidden" name="invitation[role]" :value="role"/>
        </div>
      </div>
      <div class="row">
        <email-from-chooser form-prefix="invitation" class="col-md-6" @input="emailFrom = $event" id="email-from"/>
      </div>
      <div class="row">
        <!-- these are headers for the desktop display which is more table-like.  mobile gets headers for each row. -->
        <div class="d-none d-md-block col-md">
          <label>Email</label>
        </div>
        <div class="d-none d-md-block col-12 col-md">
          <label>First Name</label>
        </div>
        <div class="d-none d-md-block col-12 col-md">
          <label>Last Name</label>
        </div>
        <div class="form-group col-12 col-md-1" v-if="showDelete">
        </div>
      </div>
      <div v-for="(user, idx) in invite.users" class="row">
        <mainstage-text
          data-lpignore="true"
          class="form-group col-12 col-md"
          label-class="d-md-none"
          prefix="invitation[users][]"
          :errors="user.errors"
          label="Email"
          field="email"
          v-model="user.email"
          placeholder="pat@example.com"
          @blur="checkInvestor(user)"/>

        <mainstage-text
          data-lpignore="true"
          class="form-group col-12 col-md"
          label-class="d-md-none"
          prefix="invitation[users][]"
          :errors="user.errors"
          label="First Name"
          field="firstname"
          v-model="user.firstname"
          placeholder="Pat"/>

        <mainstage-text
          data-lpignore="true"
          class="form-group col-12 col-md"
          label-class="d-md-none"
          prefix="invitation[users][]"
          :errors="user.errors"
          label="Last Name"
          field="lastname"
          v-model="user.lastname"
          placeholder="Smith"/>

        <div class="form-group col-12 col-md-1" v-if="showDelete">
          <button class="btn btn-danger fas fa-trash" @click.prevent="invite.users.splice(idx, 1)"/>
        </div>
      </div>
      <div class="text-right">
        <button class="btn btn-primary" @click.prevent="addUser">+ add prospect</button>
      </div>
    </template>
    <div class="form-group">
      <label for="invitation[message]">Message</label>
      <markdown-editor
        id="invitation[message]" name="invitation[message]" :errors="invite.errors"
        disable-underline="true"
        v-model="invite.message" placeholder="your message goes here...

The message will automatically include a link to your Story Vault page."
      />
        <small v-for="error in errors(invite, 'message')" class="text-danger">
          {{ error }}
        </small>
    </div>
    <div class="d-flex flex-direction-column align-items-center">
      <button class="btn btn-primary" @click.prevent="preview">Preview &amp; Send</button>
      <span class="flex-grow-1"></span>
    </div>
    <div class="mt-2">
      <b><i>Please note:</i></b>
      <i class="spam-note"> It's a good practice to let your prospects know via personal e-mail
        that they will be receiving an invite to your Story Vault.
        Invitations that mention investments may trigger spam filters, so if
        someone does not receive your invitation, please have them check
        their Spam folder.</i>
      </i>
    </div>
     <email-preview
       @send="submit" subject=''
       :body='invite.message'
       ref='email-preview'
       :to-text="previewToText"
       mail-type="invitation_email"
       :to-email="invite.users[0].email"
       :firstname="invite.users[0].firstname"
       :lastname="invite.users[0].lastname"
       :email-from="emailFrom"
     />
  </div>
  </form>
</template>

<style scoped>
label {
  font-weight: bold;
}
.spam-note {
  font-weight: 50;
  color: #777;
  margin-top: 10px;
}
</style>

<style>
#email-from label {
  font-weight: bold;
}
</style>

<script>
import MainstageInvestorRoles from 'components/mainstage_investor_roles.vue'
import EmailPreview from 'email_preview.vue'
import EmailFromChooser from 'email_from_chooser.vue'
import InvestorInviteCSV from 'investor_invite_csv.vue'
import axios from 'axios'

export default {
  components: { EmailPreview, MainstageInvestorRoles, EmailFromChooser, "investor-invite-csv": InvestorInviteCSV },
  props: [ "inviteProp" ],
  data() {
    let invite = { ... this.inviteProp }
    if ( !invite.users ) {
      invite.users = [this.createUser()]
    }
    return {
      csvUpload: false, 
      csvUserCount: null,
      role: 'prospect',
      emailsChecked: {},
      invite: invite,
      emailFrom: 'replyTo',
      formPrefix: "invitation"
    }
  },
  methods: {
    errors(user, field) {
      if ( user.errors && user.errors[field] )
        return user.errors[field]
      else
        return null
    },
    createUser() {
      return {
        firstname: '',
        lastname: '',
        email: '',
        role: 10
      }
    },
    errorClassFor(user, field) {
      let err = this.errors(user, field)
      if ( err && err.length > 0 ) {
        return [ "is-invalid" ]
      } else {
        return []
      }
    },
    addUser() {
      this.invite.users.push(this.createUser())
    },
    preview() {
      this.$refs['email-preview'].getPreview()
    },
    submit() {
      this.$refs.form.submit()
    },
    checkInvestor(user) {
      let afterCheck = (exists) => {
        if ( !user.errors ) {
          this.$set(user, "errors", {})
        }

        this.$set(user.errors, "email", [])

        if ( exists ) {
          user.errors.email.push("this user has already been invited.")
        }
        this.emailsChecked[user.email] = exists
      }

      if ( user.email in this.emailsChecked )
        return afterCheck(this.emailsChecked[user.email])

      axios("/audience/search.json?email=" + user.email).then(u =>
        afterCheck(u.data.investors.length > 0)
      )
    }
  },
  computed: {
    userCount() {
      return this.csvUserCount || this.invite.users.length
    },
    showDelete() {
      return this.invite.users.length > 1
    },
    previewToText() {
      if ( this.userCount == 1 && this.invite.users[0].firstname )
        return "to " + this.invite.users[0].firstname
      else
        return "to " + this.userCount + " " + this.$pluralize("prospect", this.userCount)
    }
  }
}
</script>


