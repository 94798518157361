<template>
  <table class="mainstage-table">
    <tr>
      <th></th>
      <th>Company</th>
      <th>Last Update</th>
      <th>Brand</th>
      <th>Owner</th>
      <th>Visits / 7 </th>
      <th>$ Committed</th>
      <th>$ Invested</th>
      <th>Raise</th>
    </tr>
    <tbody v-for="company in companyActivities">
      <tr v-if="company.status == 'pending'">
        <td></td>
        <td>{{ company.name }}</td>
        <td colspan=6 class="text-right">Invitation sent</td>
      </tr>
      <tr v-else-if="company.status == 'declined'">
        <td></td>
        <td>{{ company.name }}</td>
        <td colspan=6 class="text-right">Invitation declined</td>
      </tr>
      <tr v-else :class="{ expanded: expanded[company.company_id] == 'open' }">
        <td>
          <a @click.prevent="toggleExpanded(company)" href="#">
            <i v-if="expanded[company.company_id] == 'open'" class="fa fa-minus"></i>
            <i v-else-if="expanded[company.company_id] == 'loading'" class="fa fa-spin fa-spinner"></i>
            <i v-else class="fa fa-plus"></i>
          </a>
        </td>
        <td><a :href="becomePath(company)" target="_blank">{{ company.name }}</a></td>
        <td>{{ company.last_updated | formatDatetime }} </td>
        <td>{{ company.brand_name || "tms" }} </td>
        <td>
          <a v-if="company.owner" :href="'mailTo:' + company.owner.email">{{ company.owner.fullname }}</a>
          <span v-else>No owner</span>
        </td>
        <td>{{ company.activity.week_visits }}</td>
        <td class="currency">{{ company.investments.total_committed }}</td>
        <td class="currency">{{ company.investments.total_invested }}</td>
        <td class="currency">{{ company.investments.amount }}</td>
      </tr>
      <tr v-if="expanded[company.company_id] == 'open'">
        <td></td>
        <td colspan=10>
          <admin-company-detail :company="company" :detail="moreData[company.company_id]"/>
        </td>
      </tr>
    </tbody>

  </table>
</template>
<style scoped>
.mainstage-table tr.expanded {
  border-bottom: 0px;
}
</style>

<script>
import axios from 'axios'
import AdminCompanyDetail from 'admin_company_detail.vue'

export default {
  components: { AdminCompanyDetail },
  data() {
    let expanded = {}
    this.companyActivities.forEach(c => expanded[c.company_id] = 'closed')
    return {
      expanded: expanded,
      moreData: {}
    }
  },
  props: [ "companyActivities" ],
  methods: {
    fetchMore(company_id) {
      return axios("/admin/detail/" + company_id).
        then(j => this.$set(this.moreData, company_id, j.data))
    },
    toggleExpanded(company) {
      const id = company.company_id
      const state = this.expanded[id]
      if ( state == 'open' ) {
        this.expanded[id] = 'closed'
      } else if ( state == 'loading' ) {
        // do nothing
      } else if ( state == 'closed' ) {
        if ( this.moreData[id] ) {
          this.expanded[id] = 'open'
        } else {
          this.expanded[id] = 'loading'
          this.fetchMore(id).then(() => this.expanded[id] = 'open')
        }
      }
    },
    becomePath(company) {
      return `/admin/become?id=${company.company_id}&subdomain=${company.subdomain}`
    }
  }
}
</script>

