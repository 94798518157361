import { render, staticRenderFns } from "./example_overlay.vue?vue&type=template&id=1a4c5a83&scoped=true&"
import script from "./example_overlay.vue?vue&type=script&lang=js&"
export * from "./example_overlay.vue?vue&type=script&lang=js&"
import style0 from "./example_overlay.vue?vue&type=style&index=0&id=1a4c5a83&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a4c5a83",
  null
  
)

export default component.exports