import axios from 'axios'
import debounce from 'lodash/debounce'

$(document).ready(() => { 
   $("#activity-search").on('input', debounce((event) => { 

      axios("/admin/activity.json", { params: { q: event.target.value }})
      .then(response => { 
         window.topLevelComponents.AdminCompanies.$props.companyActivities = response.data.companies
         $(".pagination-container").html(response.data.pagination)
         const currentURL = window.location.href;
         let url = new URL(currentURL);
         url.searchParams.set("q", event.target.value);
         url.searchParams.delete("page");
         history.replaceState(null, "", url.href);
       })
   }, 100))
 })

