import Vue from 'vue'

import FormNames from '../form_names.js'
Vue.use(FormNames)

import CSRFToken from 'components/csrf_token.vue'
Vue.component('csrf-token', CSRFToken)

import MainstageInput from 'components/mainstage_input.vue'
import MainstageText from 'components/mainstage_text.vue'
import MainstageCurrency from 'components/mainstage_currency.vue'
import MainstageErrors from 'components/mainstage_errors.vue'
import MarkdownEditor from 'components/markdown_editor.vue'
import MainstageVideoEmbed from 'components/mainstage_video_embed.vue'
import DocumentTitle from 'components/document_title.vue'
import DeletedOverlay from 'components/deleted_overlay.vue'

Vue.component('mainstage-input', MainstageInput)
Vue.component('mainstage-text', MainstageText)
Vue.component('mainstage-currency', MainstageCurrency)
Vue.component('mainstage-errors', MainstageErrors)
Vue.component('markdown-editor', MarkdownEditor)
Vue.component('mainstage-video', MainstageVideoEmbed)
Vue.component('document-title', DocumentTitle)
Vue.component('deleted-overlay', DeletedOverlay)

import formatCurrency from 'util/format_currency.js'
Vue.filter('currency', formatCurrency)

import format from 'date-fns/format'
import parseJSON from 'date-fns/parseJSON'
import parseISO from 'date-fns/parseISO'


window.topLevelComponents = {}

Vue.filter('formatDate', (input, fmt) => {
  if ( !input ) return input

  if ( typeof(input) == 'string' ) {
    input = parseISO(input)
  }

  if ( !fmt ) {
    fmt = "MMMM dd, yyyy"
  }
  return format(input, fmt)
})

Vue.filter('formatDatetime', (input, fmt) => {
  if ( !input ) return input
  if ( typeof(input) == 'string' ) {
    input = parseJSON(input)
  }
  if ( !fmt ) {
    fmt = "MMMM dd, yyyy"
  } else if ( fmt == 'full' ) {
    fmt = "PPP p"
  }
  return format(input, fmt)
})

Vue.filter('capitalize', s => {
  return s[0].toUpperCase() + s.slice(1)
})

import CompanyProfile from '../company_profile.vue'
import CompanyEdit from '../company_edit.vue'
import CompanyRedcrowDisclaimer from '../company_redcrow_disclaimer.vue'
import CompanyOnboarding from '../company_onboarding.vue'
import AgencyInvite from '../agency_invite.vue'
import InvestorInvite from '../investor_invite.vue'
import InvestorReinvite from '../investor_reinvite.vue'
import InvestorTable from '../investor_table.vue'
import InvestorEdit from '../investor_edit.vue'
import InvestorShow from '../investor_show.vue'
import InvestorDocuments from '../investor_documents.vue'
import InvestorDocusign from '../investor_docusign.vue'
import InvestorActivity from '../investor_activity.vue'
import InvestorRemove from '../investor_remove.vue'
import InvestmentCircle from '../investment_circle.vue'
import Communications from '../communications.vue'
import CommunicationsList from '../communications_list.vue'
import ActivityDocuments from '../activity_documents.vue'
import ActivityVisits from '../activity_visits.vue'
import AdminCompanies from '../admin_companies.vue'
import ManageAccess from '../manage_access.vue'
import SubscriptionIndex from '../subscription_index.vue'

$(document).ready(() => {
  let pages = [
    {
      container: ".vue-markdown-editor",
      component: MarkdownEditor
    },
  ]

  const components = { 
    ActivityDocuments,
    ActivityVisits,
    AdminCompanies,
    AgencyInvite,
    Communications, 
    CommunicationsList, 
    CompanyEdit, 
    CompanyRedcrowDisclaimer,
    CompanyOnboarding,
    CompanyProfile,
    InvestmentCircle,
    InvestorActivity,
    InvestorEdit,
    InvestorDocuments,
    InvestorDocusign,
    InvestorInvite,
    InvestorReinvite,
    InvestorRemove,
    InvestorShow,
    InvestorTable,
    ManageAccess,
    MarkdownEditor,
    SubscriptionIndex
  }

  pages.forEach(p => {
    $(p.container).each((index, c) => {
      let data = $(c).data()
      let extended = Vue.extend(p.component)

      let attributes = { propsData: data }

      new extended(attributes).$mount(c)
    })
  })

  $(".vue-component").each((index, c) => { 
    let data = $(c).data()
    if ( data.vueComponent ) {
      const componentName = data.vueComponent
      const component = components[componentName]

      if ( component ) {
        const extended = Vue.extend(component)
        delete data.vueComponent
        let vueComponent = new extended({ propsData: data})
        vueComponent.$mount(c)
        window.topLevelComponents[componentName] = vueComponent
      } else { 
        $(c).html("<b>No such component: " + componentName + "</b>")
      }
    }
  })
})


