<template>
  <span>
    <document-icon :doc="doc"/>{{ title }}
  </span>
</template>

<script>
import DocumentIcon from 'components/document_icon.vue'

export default {
  components: { DocumentIcon },
  props: [ "doc" ],
  computed: {
    docTypeHash() {
      let h = {}
      this.$globals.documentTypes.forEach(t => h[t.key] = t.title)
      return h
    },
    title() {
      if ( !this.doc.document_type ) {
        return "<new document>"
      } else if ( this.doc.document_type == "other" || this.doc.document_type == "more_media" ) {
        return this.doc.title
      } else {
        return this.docTypeHash[this.doc.document_type]
      }
    }
  }
}
</script>
