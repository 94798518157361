<template>
  <div class="team-member">
    <div class="team-profile-pic">
      <a href="#" @click.prevent="showModal"><img :src="imageURL"></a>
    </div>
    <div class="team-profile-name">
      {{ member.name || "Name" }}
    </div>
    <div class="team-profile-position">
      {{ member.position || "&nbsp;" }}
    </div>
    <div class="team-profile-bio">
      <vue-markdown :source="truncatedBio" :anchorAttributes="{ target: '_blank' }"/>
      <span v-if="isTruncated"><a href="#" @click.prevent="showModal">read more...</a></span>
    </div>
    <div class="team-profile-footer text-right">
      <a v-if="member.linkedin" :href="member.linkedin" target="_blank">
        <img src="/images/linkedin.svg" class="team-profile-linkedin-image"/>
      </a>
    </div>
    <team-member-modal :member="member" ref="modal" :imageURL="imageURL"/>
  </div>
</template>
<style scoped>

.team-member {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;

  padding: 15px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

@media (min-width: 576px) {
  .team-member {
    width: 349px;
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
  }
}

.team-profile-name {
  color: #9B9B9B;
  font-size: 1.1rem;
}

.team-profile-position {
  color: #9B9B9B;
  font-size: 0.9rem;
}

.team-profile-pic {
  margin-bottom: 10px;
}

.team-profile-bio {
  color: #4A4A4A;
  font-size: 0.85rem;
  flex-grow: 1;
}

.team-profile-name {
  font-weight: bold;
}

.team-profile-pic img {
  width: 100%;
  clip-path: circle(50% at center);
}

.team-profile-linkedin-image {
  width: 40px;
  height: 40px;
  margin-right: -15px;
  margin-bottom: -17px;
}
</style>
<script>
import TeamMemberModal from './team_member_modal.vue'
import truncate from 'lodash/truncate'

let maxLength = 600

export default {
  components: { TeamMemberModal },
  props: [ "member" ],
  methods: {
    showModal() {
      this.$refs.modal.showModal()
    }
  },
  data() {
    return {
      modalVisible: this.member.position == "DEO"
    }
  },
  computed: {
    isTruncated() {
      return this.truncatedBio != this.member.bio
    },
    truncatedBio() {
      return truncate(this.member.bio, {length: 600, separator: /,? +/})
    },
    imageURL() {
      return this.member.croppie_url || this.member.profile_image_url || "/images/blank-avatar.svg"
    }
  }
}
</script>
