<template>
  <div class="white-box container-fluid">
    <div v-show="page == 0">
      <div class="d-flex align-items-center">
        <img v-if="comp.brand_name == 'redcrow'" src='/images/redcrow/sidebar_logo.svg' class="ms-logo">
        <img v-else="/images/ms_black_logo.svg" class="ms-logo">
        <div class="ml-3">
          <h1>Welcome!</h1>
          <h4>We need a bit more info...</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ValidationProvider vid="#basics.company_name"
            name="Company name" rules="required" v-slot="{  errors }">
            <mainstage-text
              prefix="company"
              label="Company Name"
              field="name"
              v-model="comp.name"
              col-six="true"
              :errors="errors"
            />
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label class="mainstage-form-label" for="company[mission_statement]">Mission statement (20 words or less)</label>
          <textarea
            id="company[mission_statement]"
            name="company[mission_statement]"
            class="form-control"
            v-model="comp.mission_statement"
            maxlength=180
            placeholder="Describe your company on the back of a business card."></textarea>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12">
          <label class="mainstage-form-label" for="deal_overview">Are you actively fund-raising?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="dealOverview" id="equity" value="equity" v-model="dealOverview">
            <label class="form-check-label" for="equity">Yes, I'm raising an equity round</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="dealOverview" id="debt" value="debt" v-model="dealOverview">
            <label class="form-check-label" for="debt">Yes, I'm raising a debt round</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="dealOverview" id="none" value="none" v-model="dealOverview">
            <label class="form-check-label" for="none">No, I'm not fund-raising right now / I'll enter the information later</label>
          </div>
        </div>
      </div>
      <div v-if="dealOverview == 'debt' || dealOverview == 'equity'" class="row">
        <div class="form-group col-12">
          <input type="hidden" name="round[deal_type]" :value="dealOverview">
          <label for="round[amount]" class="mainstage-form-label">How much are you raising?</label>
          <mainstage-currency
            prefix="round"
            label="How much are you looking to raise?"
            field="amount"
            placeholder="$1.5m">
          </mainstage-currency>
        </div>
      </div>
    </div>
    <div v-show="page == 1">
      <div class="row">
        <div class="col-12">
          <mainstage-text
            prefix="company"
            label="Company Video (optional)"
            field="video_url"
            type="url"
            size="40"
            placeholder="https://youtube.com/watch?v=xxxx or https://vimeo.com/123456789"
            v-model="comp.video_url"
            :errors="comp.errors">
            <small class="text-secondary">This video will be the first thing your audience sees.</small>
          </mainstage-text>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12">
          <label class="mainstage-form-label" for="company_logo">Company Logo</label>
          <small>(square, transparent, at least 100x100 pixels)</small>
          <StaticImageField
            file-field="company[logo]"
            :image-id="comp.logo_id"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12">
          <label class="mainstage-form-label">Investor Presentation (optional)</label>
          <pitch-deck-edit
            hide-delete="1"
            @upload-start="onUploadStart"
            @upload-progress="onUploadProgress"
            @upload-complete="onUploadComplete"
          />
          <b-progress v-if="uploadProgress != null" :value="uploadProgress" max="1"/>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12">
          <label class="mainstage-form-label" for="state">State (required)</label>
          <select class="form-control form-required"
            v-model="state" 
            id="state"
            name="company[state]">
            <option :value="null">Choose your state...</option>
            <option v-for="s in $globals.usStates" :value="s[1]">{{ s[0] }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button v-if="page > 0" @click.prevent="page--" class="btn btn-primary">Previous</button>
      <input v-if="page == 1" type="submit" :disabled="!state" value="Done!" class="btn btn-primary">
      <button v-else @click.prevent="page++" class="btn btn-primary" :disabled="disableDone">Next</button>
    </div>
  </div>
</template>
<style scoped>
.white-box {
  background: #ffffff;
  padding: 30px;
  width: 100%;
}

@media (min-width: 576px) {
  .white-box {
    box-shadow: 1px 1px 2px;
    border-radius: 5px;
    width: 576px;
  }
}

h1,h4 {
  color: #000;
}

.row {
  margin-top: 10px;
  margin-bottom: 0px;
}

.ms-logo {
  width: 150px;
}
</style>
<script>
import StaticImageField from 'components/static_image_field.vue'
import PitchDeckEdit from 'pitch_deck_edit.vue'
export default {
  components: { StaticImageField, PitchDeckEdit },
  props: [ "company" ],
  data() {
    let comp = { ... this.company }

    return {
      state: null,
      dealOverview: null,
      comp: comp,
      page: 0,
      uploadProgress: null
    }
  },
  methods: {
    onUploadStart() {
      this.uploadProgress = 0
    },
    onUploadProgress(ev) {
      this.uploadProgress = ev.progress
    },
    onUploadComplete(ev) {
      this.uploadProgress = null
    }
  },
  computed: {
    disableDone() {
      return this.uploadProgress != null && this.uploadProgress < 1
    }
  }
}
</script>

