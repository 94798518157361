<template>
  <div class="d-flex w-100 mb-2">
    <h4 class="flex-grow-1">{{ headline }} </h4>
    <a v-if="showEdit" :href="editLink"><i class="far fa-edit mr-2"></i>{{ editText }}</a>
  </div>
</template>

<script>
export default {
  props: [ 'headline', 'edit-role', 'edit-text', 'edit-link'],
  computed: {
    showEdit() {
      const user = this.$globals.currentUser
      if ( !user )
        return false

      if ( window.location.pathname.match(/^\/edit/) )
        return false

      const roleRequired = this.editRole || 'agent'
      const role = this.$roleHash[roleRequired]

      if ( !role ) // only will happen in test
        return false

      const roleRequiredNumber = role.integer
      return user.numeric_role && user.numeric_role >= roleRequiredNumber
    }
  }
}
</script>
