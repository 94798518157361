let smallFormatter = new Intl.NumberFormat('en', { minimumFractionDigits: 2 })
let largeFormatter = new Intl.NumberFormat('en', { maximumFractionDigits: 2 })

export default function(v, allowSmall) {
  if ( !v || v == "" )
    return v

  let n
  if ( v < 1000 ) {
    if ( allowSmall === false ) {
      n = largeFormatter.format(v)
    } else {
      n = smallFormatter.format(v)
    }
  } else if ( v >= 1000000000 ) {
    n = largeFormatter.format(v / 1000000000) + "b"
  } else if ( v >= 1000000 ) {
    n = largeFormatter.format(v / 1000000) + "m"
  } else {
    n = largeFormatter.format(v / 1000) + "k"
  }

  return "$" + n


}
