<template>
  <div>
    <div class="purple-box mb-5">
      <h1>Company Info</h1>
      <div class="row">
        <div class="col-12 col-md-6">
          <ValidationProvider vid="#basics.company_name"
            name="Company name" rules="required" v-slot="{  errors }">
            <mainstage-text
              prefix="company"
              label="Company Name"
              field="name"
              v-model="comp.name"
              col-six="true"
              :errors="errors"
            />
          </ValidationProvider>
        </div>
        <div class="col-12 col-md-6">
          <mainstage-text
            prefix="company"
            label="Company Website"
            field="url"
            placeholder="www.gizmo.com"
            col-six="true"
            v-model="comp.url"
            :errors="comp.errors"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 form-group">
          <label class="mainstage-form-label" for="company[mission_statement]">Mission statement (20 words or less)</label>
          <textarea
            id="company[mission_statement]"
            name="company[mission_statement]"
            v-model="comp.mission_statement"
            rows=2
            maxlength=180
            class="form-control"
            placeholder="Describe your company on the back of a business card."></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mainstage-text
            prefix="company"
            label="Youtube/Vimeo link"
            field="video_url"
            type="url"
            size="40"
            placeholder="https://youtube.com/watch?v=xxxx or https://vimeo.com/123456789"
            v-model="comp.video_url"
            :errors="comp.errors"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <mainstage-text
            prefix="company"
            label="Reply to: email"
            field="reply_to_email"
            placeholder="communications@example.com"
            v-model="comp.reply_to_email"
            :errors="comp.errors"
            annotation="Emails to your audience will include a reply-to with this email address."
          />
        </div>
        <div class="col-12 col-md-6">
          <mainstage-text
            prefix="company"
            label="Reply to: name"
            field="reply_to_name"
            :placeholder="comp.name"
            v-model="comp.reply_to_name"
            :errors="comp.errors"
            annotation="The reply-to will be from this name."
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12">
          <div class="form-group">
            <label class="required mainstage-form-label" for="company_company_description">Company Description</label>
            <markdown-editor
              v-model="comp.company_description"
              placeholder="More information about the company"
              name="company[company_description]" id="company_company_description"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12">
          <label class="mainstage-form-label">Investor Presentation</label>
          <pitch-deck-edit :pitchDoc="pitch_deck_document"
            @input="pitchDeckUpdate"
            @upload-start="$emit('upload-start', $event)"
            @upload-progress="$emit('upload-progress', $event)"
            @upload-complete="$emit('upload-complete', $event); $emit('pitch-deck-upload', $event.upload_id)"
          />
        </div>
      </div>
    </div>
    <div class="purple-box">
      <h1>Media</h1>
      <div class="row">
        <div class="form-group col-12 col-md-4 mb-5">
          <label class="mainstage-form-label" for="company_logo">Company Logo</label>
          <StaticImageField
            file-field="company[logo]"
            :image-id="comp.logo_id"
            @result="updateLogo($event)"
          />
        </div>
        <div class="form-group col-12 col-md-8 mb-5">
          <label class="mainstage-form-label" for="company_logo">Banner</label>
          <ImageField
            file-field="company[banner]"
            dimensions-field="company[banner_crop]"
            :image-id="comp.banner_id"
            :initialCrop="comp.banner_crop"
            :viewport="{ 'width': 384, 'height': 150 }"
            :boundary="{ 'width': 384, 'height': 150 }"
            @result="updateBanner($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.big-drag-target {
  width: 50%;
  height: 200px;
  border: 2px dashed #ccc;
}
</style>
<script>
  import ImageField from 'components/image_field.vue'
  import StaticImageField from 'components/static_image_field.vue'
  import PitchDeckEdit from 'pitch_deck_edit.vue'
  import MainstageText from 'components/mainstage_text.vue'

  export default {
    props: ["company"],
    components: { StaticImageField, ImageField,  MainstageText, PitchDeckEdit },
    data() {
      let comp = { ... this.company }

      comp.team_members = null
      let pitch_deck_document = comp.documents.find(d => d.document_type == "investor_pitch_deck")

      return {
        ignoreFirstCroppieEvent: !!comp.banner_id,
        pitch_deck_document: pitch_deck_document,
        comp: comp,
      }
    },
    methods: {
      pitchDeckUpdate(deck) {
        if ( deck.deleted ) {
          this.$emit('pitch-deck-delete')
        }
      },
      updateLogo(newURL) {
        this.comp.logo_url = newURL
      },
      updateBanner(newURL) {
        if ( this.ignoreFirstCroppieEvent ) {
          this.ignoreFirstCroppieEvent = false
          return
        }

        this.comp.banner_url = newURL
      },
    },
    watch: {
      comp: {
        deep: true,
        handler() {
          this.$emit("input", this.comp)
        }
      }
    }
  }
</script>
