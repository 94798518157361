<template>
  <b-modal size="lg" title="Request a signature on Docusign" ref="modal" ok-title="Send" :ok-disabled="disableSend" @ok="sendRequest">
    <h2>Send Docusign Request to {{ investor && investor.fullname }}</h2>
    <div v-if="this.templates && this.templates.length > 0">
      To get started, choose a template:
      <select class="form-control" v-model="templateId" @change="fetchRoles">
        <option v-for="template in templates" 
          :key="template.templateId" 
          :value="template.templateId"
          :title="template.emailSubject">{{ template.name }}</option>
      </select>
    </div>
    <div v-else>
      To send the same document to multiple investors, 
      to <a :href="createTemplateURL()" target="_blank">create a document template</a> at DocuSign.
    </div>
    <div v-if="this.roles && this.roles.length > 1" class="mt-2">
      Which role should we use?
      <select class="form-control" v-model="role">
        <option value="">choose a role..</option>
        <option v-for="role in roles" 
          :key="role" 
          :value="role">{{ role }}</option>
      </select>
    </div>
    <p class="mt-2">
      You can also <a :href="oneOffURL">initiate a one-off signing request</a>
    </p>
  </b-modal>
</template>

<style scoped>
</style>

<script>
import axios from 'axios'

export default {
  props: [ "investor-id" ],
  data() {
    return {
      investor: null,
      templates: [],
      templateId: null,
      loading: true,
      roles: null,
      role: "_",
      error: null
    }
  },
  methods: {
    show(templates, investor) {
      this.templates = templates
      this.templateId = this.templates && this.templates[0] && this.templates[0].templateId
      this.investor = investor
      this.$refs.modal.show()
      if ( this.templateId ) {
        this.fetchRoles()
      }
    },
    sendRequest() {
      window.location = `/integrations/docusign/template_signature/${this.templateId}?user_id=${this.investor.id}&role=${this.role}`
    },
    async fetchRoles() {
      this.roles = null
      this.role = ""
      const response = await axios(`/integrations/docusign/template_signers/${this.templateId}`)
      this.roles = response.data.signers.filter(r => !r.email && !r.name).map(r => r.roleName)
      if ( this.roles.length == 0 ) {
        this.error = "Cannot find a signer for this template, try another?"
      } else if ( this.roles.length == 1 ) { 
        this.role = this.roles[0]
      }
    },
    createTemplateURL() {
      return this.$globals.docusignUrl + "/send/templates"
    }
  },
  computed: { 
    disableSend() { 
      return this.templateId == null || !this.role 
    },
    oneOffURL() { 
      return this.investor && `/integrations/docusign/single_signature?user_id=${this.investor.id}`
    }
  }
}
</script>
