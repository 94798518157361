<template>
  <b-modal body-class="m-2" size="lg" ok-only id="comms-modal" :title="title">
    <b>{{ subject }}</b>
    <p>
      <vue-markdown class="mt-3" :source="body"/>
    </p>
    <i>{{ date | formatDatetime("MMMM dd, yyyy") }}</i>
  </b-modal>
</template>

<script>
export default {
  props: ["date", "subject", "direction", "body", "name", "communication-type"],
  methods: {
    show() {
      this.$bvModal.show("comms-modal")
    }
  },
  computed: {
    title() {
      if ( this.communicationType == "invitation" ) {
        return "Initial invitation"
      } else {
        return `Message ${this.direction} ${this.name}`
      }
    }
  }
}
</script>
