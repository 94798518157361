import axios from 'axios'

let createUpload = function(file, progress) {
  let csrfToken = $('meta[name="csrf-token"]').attr("content")

  let formData = new FormData()
  formData.append("authenticity_token", csrfToken)
  formData.append("file", file)

  return axios.request( {
    method: "post",
    url: "/upload.json",
    data: formData,
    onUploadProgress: (p) => {
      if ( progress )
        progress(p)
    }
  })
}

export default {
  methods: {
    uploadWithProgress(obj, file, localMethods) {
      if ( !localMethods ) localMethods = {}

      this.$emit("upload-start", obj)

      if ( localMethods.onUploadStart )
        localMethods.onUploadStart(obj)

      let onProgress = (progress) => {
        obj.progress = progress.loaded / progress.total
        obj.loaded = progress.loaded
        obj.total = progress.total

        if ( localMethods.onUploadProgress )
          localMethods.onUploadProgress(obj)

        this.$emit("upload-progress", obj)

      }

      return createUpload(file, onProgress).then(r => {
        obj.upload_id = r.data.id
        this.$emit("upload-complete", obj)
        if ( localMethods.onUploadComplete )
          localMethods.onUploadComplete(obj)
      })
    }
  }
}
