<template>
  <iframe :src="videoURL" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</template>

<script>
let sites = [
  {
    prefix: "https://www.youtube.com/embed/",
    regexes: [
      /youtube.com\/watch\?.*?v=([\w\-]+)/,
      /youtu.be\/([\w\-]+)/
    ]
  },
  {
    prefix: "https://player.vimeo.com/video/",
    regexes: [
      /vimeo\.com\/(\d+)/,
      /vimeo\.com\/video\/(\d+)/
    ]
  }
]

export default {
  props: ["url"],
  computed: {
    videoURL() {
      return sites.map(site => {
        return site.regexes.map(r => {
          let matches = this.url.match(r)
          if ( matches ) {
            return site.prefix + matches[1]
          }
        }).find(e => e)
      }).find(e => e)
    }
  }
}
</script>



