import { render, staticRenderFns } from "./company_redcrow_disclaimer.vue?vue&type=template&id=06329910&scoped=true&"
var script = {}
import style0 from "./company_redcrow_disclaimer.vue?vue&type=style&index=0&id=06329910&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06329910",
  null
  
)

export default component.exports