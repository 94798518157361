<template>
  <div class="container-fluid">
    <div v-if="videoURL" class="row red-dot">
      <div class="col-12 p-0">
        <div class="video-container profile-video-container">
          <mainstage-video :url="videoURL"/>
        </div>
      </div>
    </div>
    <div class="row red-dot">
      <div class="col-12">
        <h3>Company Description</h3>
        <vue-markdown class="line-height-25" v-if="company.company_description" :source="company.company_description"/>
        <p v-else class="line-height-25">
          Describe your product or company in more detail. You should include
          vital details of your business, as well as descriptions of the problem
          you're solving, your approach to a solution, the nature of the market
          and its size.  Keep in mind that this may be the first time a
          potential investor hears your pitch, so don't assume that they have
          any background or previous knowledge.  Try to present the vital information
          in a clear and concise fashion.
        </p>
      </div>
    </div>
    <div v-if="company.pitch_deck_url" class="row red-dot">
      <div class="col-12">
        <pitch-deck :url="company.pitch_deck_url" :title="company.subdomain == 'oncoheroes' && 'Company Overview'"/>
      </div>
    </div>
    <example-overlay :is-example="metrics.isExample" max-width="400px" height="75%" noun="market facts" edit-link="/edit#facts">
      <div class="metric-container">
        <market-fact v-for="(metric, i) in metrics.data" :key="i" :metric="metric"/>
      </div>
    </example-overlay>
    <div class="row red-dot mt-4" v-if="showFAQ">
      <div class="col-12">
        <h3>FAQs</h3>
        <faq :faqs="company.faqs"/>
      </div>
    </div>
    <div v-if="showComments">
      <div id="disqus_thread"></div>
    </div>
    <div class="row red-dot mt-3" v-if="showLinks">
      <div class="col-12">
        <h3>Company Links</h3>
        <company-links :company="company" class="mb-4"/>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.metric-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.elevator-pitch-text {
  padding: 0px 15px;
}
</style>
<script>

import PitchDeck from 'pitch_deck.vue'
import MarketFact from 'market_fact.vue'
import ExampleData from 'mixins/example_data.js'
import CompanyLinks from 'company_links.vue'
import CompanyProfileHeadline from 'components/company_profile_headline.vue'
import Faq from 'faq.vue'

let exampleMetricData = [
  { number: "$5 billion", unit: "market size" },
  { number: "330 million", unit: "new customers yearly" },
  { number: "33%", unit: "of households own this" }
]

export default {
  components: { PitchDeck, MarketFact, CompanyLinks, CompanyProfileHeadline, Faq },
  mixins: [ ExampleData ],
  props: ["company"],
  computed: {
    videoURL() {
      if ( this.company.video_url )
        return this.company.video_url
      else if ( this.showExamples )
        return "https://www.youtube.com/watch?v=WPd-mqvJCTI"
      else
        return null
    },
    showLinks() {
      // test for url or non-empty social link
      return this.company.url
          || Object.values(this.company.links || {}).some(link => link)
    },
    metrics() {
      let metrics = this.company.key_metrics || []
      metrics = metrics.filter(m => m.number || m.unit)
      return this.exampleData(metrics, exampleMetricData)
    },
    showFAQ() {
      return this.company.faqs && this.company.faqs.length > 0 
    },
    showComments() {
      return this.company.reg_cf
    }
  }
}
</script>
