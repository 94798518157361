<template>
  <div>
    <form method="post" :action="formAction">
      <csrf-token/>
      <div v-if="notifyInvestor">
        <mainstage-text prefix="communication" v-model="communication.subject" label="Subject" field="subject" placeholder="Email subject line" :errors="communication.errors"/>
        <div class="form-group">
          <label for="communication_body">Body</label>
          <markdown-editor
            v-model="communication.body"
            field="body"
            placeholder="Your Message"
            name="communication[body]"
            :errors="communication.errors"
          />
        </div>
      </div>
      <div class="form-group">
        <input type="checkbox" v-model="notifyInvestor" name="notify_investor" value="1" id="notify_investor">
        <label for="notify_investor">Send notification</label>
      </div>
      <div class="form-group">
        <input type="submit" class="btn btn-danger" value="Remove User">
      </div>
    </form>
   </div>
</template>

<script>
import Communications from 'communications.vue'

export default {
  data() {
    return {
      notifyInvestor: true
    }
  },
  props: [ 'communication' ],
  components: { Communications }
}
</script>
