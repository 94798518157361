import axios from 'axios'

export default {
  tabChange(tab) {
    if ( tab.startsWith('#') )
      tab = tab.substr(1)

    axios('/activity/tab_click?tab=' + tab)

    gtag('set', { page_path: '/' + tab })

    gtag('event', 'page_view')
  },
  pitchDeckPage(page) {
    axios('/activity/pitch_deck?page=' + page)
  }
}
