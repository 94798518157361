<template>
  <div class="upsell-container">
    <div class="upsell-box">
      <h3>Starter Plan</h3>
      <p>For individual companies and startups</p>
      <div class="price">$499 / mo</div>
      <ul class="upsell-list">
        <li>Story Vault</li>
        <li>CRM</li>
        <li>Data Vault</li>
        <li>Investor Dashboard</li>
        <li>Communication tools</li>
        <li>Fundraising Complaince tools</li>
      </ul>
      <subscribe-now-button text="Subscribe Now"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'vars';

h3 {
  color: $mainstage-purple;
}

.price {
  font-size: 3rem;
  letter-spacing: -2px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;

}
.upsell-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  justify-content: space-around;
}

.upsell-box {
  width: 380px;
  border-radius: 10px;
  padding: 30px;
  box-shadow: -3px 3px 0px 1px $mainstage-purple;
  border: 1px solid #ddd;
  background: #fefefe;
}

.upsell-list {
  text-align: left;
  list-style: none;
  padding: 0;
}

.upsell-list li {
  padding-left: 1.3em;
  padding-bottom: 0.2rem;
}

.upsell-list li:before {
  content: "\f058";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
}

</style>

<script>
import SubscribeNowButton from 'subscribe_now_button.vue'
export default {
  components: { SubscribeNowButton }
}
</script>

