export default [
  { name: 'facebook', urlHint: 'facebook.com/', urlPrefix: 'https://www.facebook.com/' },
  { name: 'twitter', urlHint: '@', urlPrefix: 'https://twitter.com/' },
  { name: 'instagram', urlHint: '@', urlPrefix: 'https://www.instagram.com/' },
  { name: 'pinterest', urlHint: 'pinterest.com/', urlPrefix: 'https://pinterest.com/' },
  { name: 'vimeo', urlHint: 'vimeo.com/', urlPrefix: 'https://vimeo.com/' },
  { name: 'youtube', urlHint: 'youtube.com/channel/', urlPrefix: 'https://www.youtube.com/channel/' },
  { name: 'linkedin', urlHint: 'linkedin.com/', urlPrefix: 'https://www.linkedin.com/'  },
  { name: 'medium', urlHint: '@', img: '/images/social/medium.svg', urlPrefix: 'https://medium.com/@' }
]

