<template>
  <div class="mt-3 pitch-deck-container">
     <h3>{{ title || "Investor Presentation" }}</h3>
    <div ref="full-screen-target" :class="{ fullscreen: isFullScreen }">
      <pdf :src="url" :page="currentPage" @num-pages="pageCount=$event" @page-loaded="currentPageText=$event" class="pitch-deck" :key="url + forceRerender"/>
      <div id="pagination-container">
        <nav>
          <ul class="pagination justify-content-end align-items-center mt-2">
            <li class="page-item" v-if="supportsFullScreen">
              <button class="fas page-link" :class="fullScreenButtonClass" id="full-screen-button" @click="toggleFullScreen"/>
            </li>
            <li class="page-item" v-else>
              <a class="fas fa-external-link-alt page-link" :href="url"/>
            </li>
            <li class="page-item" :class="{ disabled: currentPage <= 1}">
              <button class="page-link" @click.prevent="setPage(1)">
                &laquo;
              </button>
            </li>
            <li class="page-item" :class="{ disabled: currentPage <= 1}">
                <button class="page-link" @click.prevent="setPage(currentPage - 1)">
                &lsaquo;
              </button>
            </li>
            <li class="page-item">
              <span class="page-link pagination-text">
                Page <input type="text"
                  autofill="off"
                  data-lpignore="true"
                  name="current_page"
                  class="mx-1"
                  @input="setPageFromInput($event.target.value)"
                  @focus="doSelect"
                  @blur="correctCurrentPage"
                  v-model="currentPageText" size=2> of {{ pageCount }}
              </span>
            </li>
            <li class="page-item" :class="{ disabled: currentPage >= pageCount}">
              <button class="page-link" @click.prevent="setPage(currentPage + 1)">
                &rsaquo;
              </button>
            </li>
            <li class="page-item" :class="{ disabled: currentPage >= pageCount}">
              <button class="page-link" @click.prevent="setPage(pageCount)">
                &raquo;
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>
<style scoped>

.fullscreen {
  height: 100%;
  overflow: scroll;
}

.fullscreen #pagination-container {
  position: fixed;
  top: 0px;
  right: 5px;
  z-index: 2;
}

#full-screen-button {
  font-size: 75%;
}

.pagination-text {
  color: #333;
  font-family: monospace;
  padding: 0.3rem 0.5rem;
}

.pagination-text input {
  text-align: right;
}

.pitch-deck {
  border: solid 1px #ccc;
}

.pitch-deck-container {
  max-width: 1040px;
}

.page-link {
  display: flex;
  height: 3rem;
  align-items: center;
}
</style>
<script>
import pdf from '@themainstage/vue-pdf'
import screenfull from 'screenfull'
import Tracking from 'api/tracking.js'

export default {
  props: [ "url", "title" ],
  data() {
    return {
      supportsFullScreen: screenfull.isEnabled,
      forceRerender: 0,
      isFullScreen: false,
      pageCount: 0,
      currentPage: 1,
      currentPageText: "1"
    }
  },
  methods: {
    onFullScreenChange() {
      this.isFullScreen = screenfull.isFullscreen
      if ( !this.isFullScreen ) {
        this.$refs['full-screen-target'].scrollIntoView()
      }
    },
    toggleFullScreen() {
      screenfull.toggle(this.$refs['full-screen-target'])
      this.forceRerender++
    },
    setPage(page) {
      this.currentPage = page
      Tracking.pitchDeckPage(page)
    },
    setPageFromInput(value) {
      if ( /^\d+$/.test(value) ) {
        let parsed = parseInt(value)
        if ( parsed >= 1 && parsed <= this.pageCount )
          this.setPage(parsed)
      }
    },
    correctCurrentPage() {
      let parsed = parseInt(this.currentPageText)
      if ( isNaN(parsed) || parsed < 1 || parsed > this.pageCount ) {
        this.currentPageText = String(this.currentPage)
      }
    },
    doSelect(ev) {
      ev.target.select()
    }
  },
  computed: {
    fullScreenButtonClass() {
      if ( this.isFullScreen )
        return "fa-compress-arrows-alt"
      else
        return "fa-expand-arrows-alt"
    }
  },
  watch: {
    url() {
      this.currentPage = 1
      this.currentPageText = "1"
    }
  },
  mounted() {
    if (  screenfull.isEnabled )
      screenfull.on('change', this.onFullScreenChange)
  },
  beforeDestroy() {
    if (  screenfull.isEnabled )
      screenfull.off('change', this.onFullScreenChange)
  },
  components: { pdf }
}
</script>

