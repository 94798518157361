export default {
  created() {
    if ( !this.hashChange )
      throw("Please define `hashChange` method!")

    window.addEventListener('hashchange', () => this.hashChange(), false)
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.hashChange)
  }
}
