export default {
  props: ["errors", "field"],

  computed: {
    errorClasses() {
      if ( this.errList.length > 0 )
        return ['is-invalid']
      else
        return []
    },
    errList() {
      if ( Array.isArray(this.errors) )
        return this.errors
      else if ( this.errors && this.errors[this.field] )
        return this.errors[this.field]
      else
        return []
    }
  }
}
