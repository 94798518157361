<template>
  <div class="ck-markdown-editor" :class="errorClasses">
    <ckeditor @ready="onReady" :editor="editor" v-model="editorData" @input="$emit('input', $event)" :config="editorConfig"></ckeditor>

    <input type="hidden" :name="name" :value="editorData"/>
    <div v-if="maxCharacters">
      <span :class="{ 'text-danger': characterCount > maxCharacters }">{{ characterCount }}</span>
        of {{ maxCharacters }} characters used
    </div>

    <mainstage-errors :errors="errList"/>
  </div>
</template>
<style>

.ck-markdown-editor {
  /* don't know why I have to do this! */
  max-width: calc(100% - 2px);
}

.ck-markdown-editor.is-invalid .ck-editor {
  border: 1px solid #dc3545;
  border-radius: 4px;
  border-collapse: collapse;
}
</style>
<script>

import MDEditor from '@jdinabox/ckeditor5-build-markdown'
import ServerSideErrors from 'mixins/server_side_errors.js'

export default {
  mixins: [ ServerSideErrors ],
  props: ['name', 'value', 'placeholder', 'maxCharacters', 'vid', 'small', 'disable-underline', 'single-line-enter'],
  data() {
    let items
    if ( this.small ) {
      items = [
        'bold',
        'italic',
        'underline',
        '|',
        'link',
        'bulletedList',
        'numberedList',
        'blockQuote'
      ]
    } else if ( this.singleLineEnter ) {
      items = [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        '|',
        'undo',
        'redo'
      ]
    } else {
      items = [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'blockQuote',
        '|',
        'undo',
        'redo'
      ]
    }

    let removePlugins = []
    if ( this.disableUnderline ) {
      removePlugins.push('Underline')
      items = items.filter(i => i != 'underline')
    }

    return {
      characterCount: null,
      editor: MDEditor,
      editorData: this.value,
      editorConfig: {
        removePlugins: removePlugins,
        placeholder: this.placeholder,
        wordCount: {
          onUpdate: stats => {
            this.characterCount = stats.characters
          },
          maxCharCount: 5
        },
        toolbar: {
          items: items
        },
      }
    }
  },
  methods: {
    onReady(editor) {
      if ( this.singleLineEnter ) {
        editor.editing.view.document.on( 'enter', ( evt, data ) => {
          if ( data.isSoft ) {
            editor.execute( 'enter' )
          } else {
            editor.execute( 'shiftEnter' )
          }

          data.preventDefault()
          evt.stop()
          editor.editing.view.scrollToTheSelection()
        }, { priority: 'high' })
      }
    }
  }
}
</script>
