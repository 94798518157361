<template>
  <tr class="subscription-row">
    <td class="plan">
      {{ name }}
    </td>
    <td class="status">
      {{ status }}
    </td>
    <td class="date">
      <span>
        {{ subscriptionDate | capitalize }}
      </span>
    </td>
  </tr>
</template>
<style scoped>

</style>
<script>
import parseJSON from 'date-fns/parseJSON'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import format from 'date-fns/format'

export default {
  props: [ "subscription" ],
  methods: {
    parseDate(date) {
      let parsed = parseJSON(date)
      return format(parsed, "PP")
    }
  },
  computed: {
    name() {
      switch ( this.subscription.name ) {
        case "starter":
        case "trial":
          return "Starter Plan"
        case "backstage":
          return "Backstage Plan"
        case "expired_trial":
          return "Starter Plan (Trial)"
        case "grandfather":
          return "Friends & Family Free Plan (Starter)"
        case "redcrow":
          return "Redcrow Plan"

      }
    },
    status() {
      switch ( this.subscription.status ) {
        case "trial":
        case "trialing":
          if ( this.subscription.ends_at ) 
            return "Cancelled"
          else 
            return "Free Trial"
        case "active":
        case "incomplete": // i guess this is a race condition state?
          return "Active"
        case "canceled":
          return "Canceled"
        case "expired":
          return "Expired"
      }
    },
    subscriptionDate() {
      switch(this.subscription.status) {
        case "trial":
          let date = parseJSON(this.subscription.ends_at)
          let futureDistance = formatDistanceToNow(date, {addSuffix: true})

          return `Ends ${format(date, "PP")} (${futureDistance})`
        case "expired":
        case "canceled":
          let expiredOn = this.parseDate(this.subscription.ends_at)
          let verb = this.subscription.status
          return `${verb} ${expiredOn}`
        case "active":
          if ( this.subscription.ends_at ) {
            let cancelsOn = this.parseDate(this.subscription.ends_at)
            return `Cancels on ${cancelsOn}`
          } else {
            let since = this.parseDate(this.subscription.created_at)
            return `Since ${since}`
          }
        case "trialing":
          if ( this.subscription.ends_at ) { 
            let cancelsOn = this.parseDate(this.subscription.ends_at)
            return `Free trial until ${cancelsOn} (cancelled)`
          }
          if ( this.subscription.trial_ends_at ) {
            let billsOn = this.parseDate(this.subscription.trial_ends_at)
            return `Free trial until ${billsOn}`
          } else {
            let since = this.parseDate(this.subscription.created_at)
            return `Since ${since}`
          }
        case "expired_trial":
      }
    }
  }
}
</script>
