<template>
  <div class="mb-5">
    <FileDragTarget
      :class="{ dragging: dragging }"
      :style="{ width: width + 'px', height: height + 'px' }"
      :fileInput="$refs.fileInput"
      class="upload-drag-spot"
      ref="container"
      @change="readFile"
      @dragstart="dragging = true"
      @dragend="dragging = false">
      <vue-croppie ref="croppie" @update="update" :enableResize=false :viewport="viewport" :boundary="boundary" :mouseWheelZoom="false"/>
      <label class="btn btn-light upload-button fa fa-edit">
        <input type="file" ref="fileInput" :name="fileField" @change="readFile" hidden/>
        <input type="hidden" :name="dimensionsField" :value="dimensions"/>
      </label>
      <div v-if="!hideDragMessage" class="upload-dragmsg">Drag an image here...</div>
    </FileDragTarget>
  </div>
</template>

<style scoped>
.upload-drag-spot {
  text-align: center;
  border: 1px dashed #ccc;
  padding: 5px;
  position: relative;
}

.upload-drag-spot.dragging {
  border-color: black;
}

.upload-dragmsg {
  position: absolute;
  top: 48%;
  text-align: center;
  left: 5%;
  width: 90%;
  background-color: white;
  pointer-events: none;
  z-index: 1;
}
</style>
<script>
  import FileDragTarget from 'components/file_drag_target.vue'

  export default {
    props: ["image-id", "viewport", "boundary", "fileField", "dimensionsField", "initialCrop"],
    components: { FileDragTarget },
    data() {
      return {
        base64: "",
        dimensions: "",
        hideDragMessage: this.imageId,
        width: this.boundary.width + 10,
        height: this.boundary.height + 10,
        dragging: false,
        doResult: $.debounce(function(croppie) {
          this.$refs.croppie.result({type: 'base64', size: 'original', format: 'png', circle: false})
            .then(base64 => {
              this.$emit("result", base64)
              this.base64 = base64
            })
        }, 250, false),
      }
    },
    methods: {
      update(params) {
        this.hideDragMessage = true
        this.dimensions = params.points.join(",")
        this.doResult()
      },
      readFile () {
        let input = this.$refs.fileInput

        if (input.files && input.files[0]) {
          var reader = new FileReader();

          reader.onload = (e) => {
            this.$refs.croppie.bind({
              url: e.target.result,
              zoom: 0
            })
          }

          reader.readAsDataURL(input.files[0]);
        }
      },
      waitForDOM(callback) {
        let rect = this.$refs['container'].$el.getBoundingClientRect()
        if ( rect.height == 0 )
          setTimeout(() => this.waitForDOM(callback), 50)
        else
          callback()
      }
    },
    mounted() {
      let el = $(this.$refs.croppie.$el)

      if ( this.imageId ) {
        let options = {
          url: `/images/${this.imageId}`
        }
        if ( this.initialCrop ) {
          options.points = this.initialCrop
        }

        this.waitForDOM(() => {
          this.$refs.croppie.bind(options).catch(err => console.log(err))
        })
      }
    }
  }
</script>
