<template>
  <example-overlay :is-example="roundWithExample.isExample" noun="offering data">
    <div class="deal-grid" v-if="roundWithExample.data.deal_type == 'debt'">
      <div class="deal-grid-cell" v-if="roundWithExample.data.amount">
        <h3>Raise Amount</h3>
        <p><b>{{ formatCurrency(roundWithExample.data.amount) }}</b></p>
      </div>
      <div class="deal-grid-cell" v-if="roundWithExample.data.interest_rate_pct">
        <h3>Interest Rate</h3>
        <p>{{ roundWithExample.data.interest_rate_pct }}</p>
      </div>
      <div class="deal-grid-cell" v-if="roundWithExample.data.discount_rate">
        <h3>Discount Rate</h3>
        <p>{{ roundWithExample.data.discount_rate }}</p>
      </div>
      <div class="deal-grid-cell" v-if="roundWithExample.data.min_amount">
        <h3>Min Investment Amount</h3>
        <p>{{ formatCurrency(roundWithExample.data.min_amount) }}</p>
      </div>
      <div class="deal-grid-cell" v-if="roundWithExample.data.max_amount">
        <h3>Max Investment Amount</h3>
        <p>{{ formatCurrency(roundWithExample.data.max_amount) }}</p>
      </div>
      <div class="deal-grid-cell" v-if="roundWithExample.data.funding_stage">
        <h3>Funding Stage</h3>
        <p>{{ roundWithExample.data.funding_stage }}</p>
      </div>
      <div class="deal-grid-cell" v-if="roundWithExample.data.maturity_date">
        <h3>Maturity Date</h3>
        <p>{{ roundWithExample.data.maturity_date | formatDate }}</p>
      </div>
      <div class="deal-grid-cell deal-grid-cell-always-break" v-if="roundWithExample.data.funding_security_type">
        <h3>Funding Security Type</h3>
        <p>{{ roundWithExample.data.funding_security_type }}</p>
      </div>
    </div>
    <div v-if="roundWithExample.data.deal_type == 'debt' && roundWithExample.data.conversion_rights && roundWithExample.data.conversion_rights.length > 0" 
      class="deal-grid-cell deal-grid-cell-wide deal-grid-cell-always-break">
      <h3>Conversion Rights</h3>
      <vue-markdown :source="roundWithExample.data.conversion_rights"/>
    </div>
    <div class="deal-grid" v-else-if="roundWithExample.data.deal_type == 'equity'">
      <div class="deal-grid-cell" v-if="roundWithExample.data.amount">
        <h3>Raise Amount</h3>
        <p><b>{{ formatCurrency(roundWithExample.data.amount) }}</b></p>
      </div>
      <div class="deal-grid-cell" v-if="roundWithExample.data.price_per_share">
        <h3>Price Per Share/Unit</h3>
        <p>{{ formatCurrency(roundWithExample.data.price_per_share) }}</p>
      </div>
      <div class="deal-grid-cell" v-if="roundWithExample.data.shares_offered">
        <h3>Shares/Units Offered</h3>
        <p>{{ roundWithExample.data.shares_offered }}</p>
      </div>
      <div class="deal-grid-cell" v-if="roundWithExample.data.min_amount">
        <h3>Min Investment Amount</h3>
        <p>{{ formatCurrency(roundWithExample.data.min_amount) }}</p>
      </div>
      <div class="deal-grid-cell" v-if="roundWithExample.data.max_amount">
        <h3>Max Investment Amount</h3>
        <p>{{ formatCurrency(roundWithExample.data.max_amount) }}</p>
      </div>
      <div class="deal-grid-cell" v-if="roundWithExample.data.funding_stage">
        <h3>Funding Stage</h3>
        <p>{{ roundWithExample.data.funding_stage }}</p>
      </div>
      <div class="deal-grid-cell" v-if="roundWithExample.data.pre_money_valuation">
        <h3>Pre-Money Valuation</h3>
        <p>{{ formatCurrency(roundWithExample.data.pre_money_valuation) }}</p>
      </div>
      <div class="deal-grid-cell deal-grid-cell-always-break" v-if="roundWithExample.data.funding_security_type">
        <h3>Funding Security Type</h3>
        <p>{{ roundWithExample.data.funding_security_type }}</p>
      </div>
    </div>
    <div v-if="roundWithExample.data.additional_info" class="deal-grid-cell deal-grid-cell-wide">
      {{ roundWithExample.data.additional_info }}
    </div>
    <div v-if="roundWithExample.data.accepts_ira" class="deal-grid-cell deal-grid-cell-wide">
      <i>Company accepts IRA investments</i>
    </div>
    <div v-if="showUseOfFunds" class="mt-2">
      <h2>Use of funds</h2>
      <div v-for="bullet in roundWithExample.data.use_of_funds_bullets" v-if="bullet" class="grid-list">&bull; {{ bullet }}</div>
      <div v-if="roundWithExample.data.use_of_funds_text" class="grid-list">
        <vue-markdown :source="roundWithExample.data.use_of_funds_text"/>
      </div>
    </div>

  </example-overlay>
</template>
<style scoped>
.deal-grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
}

.deal-grid-cell {
  display: flex;
  align_items: center;
  width: 100%;
  background: #fff;
  border: 1px solid #ECEBEB;
  border-collapse: collapse;
  padding: 10px;
  font-size: 16px;
  color: #4a4a4a;
}

.deal-grid-cell:last-child {
  width: auto;
  flex-grow: 1;
}

.deal-grid-cell p {
  flex-grow: 1;
  text-align: right;
  margin-bottom: 0;
}

.deal-grid-cell h3 {
  display: inline-block;
  color: #9b9b9b;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.deal-grid-cell-always-break {
  display: block;
}

.deal-grid-cell-always-break p {
  text-align: left;
}

.deal-grid-cell-wide {
  width: 100% !important;
}

.deal-grid-cell h3::after {
  content: ':'
}

@media screen and (min-width: 520px) {
  .deal-grid-cell {
    width: 50%;
    display: block;
  }

  .deal-grid-cell h3 {
    display: block;
  }

  .deal-grid-cell h3::after {
    content: ''
  }

  .deal-grid-cell p {
    text-align: left;
  }
}

@media screen and (min-width: 768px) {
  .deal-grid-cell {
    width: 25%;
  }
}

</style>
<script>

let exampleRound = {
  amount: 1500000,
  deal_type: 'equity',
  price_per_share: 0.43,
  shares_offered: "10 million",
  min_amount: 10000,
  funding_stage: "Series A",
  pre_money_valuation: 50000000,
  funding_security_type: "Preferred Shares"
}

import formatCurrency from 'util/format_currency.js'
import ExampleData from 'mixins/example_data.js'
export default {
  mixins: [ ExampleData ],
  props: [ "round" ],
  methods: {
    formatCurrency: formatCurrency
  },
  computed: {
    roundWithExample() {
      return this.exampleData(this.round, exampleRound, (!this.round) || this.round.show_example)
    },
    showUseOfFunds() { 
      let bullets = this.roundWithExample.data.use_of_funds_bullets
      return bullets && bullets.find(b => b) || this.roundWithExample.data.use_of_funds_text
    }
  }
}
</script>
