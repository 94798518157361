<template>
  <div>
    <div class="purple-box mb-5">
      <h1>Mainstage Admin</h1>
      <div class="row">
        <div class="col-12">
          <input type="checkbox" v-model="comp.public_site" name="company[public_site]" id="company[public_site]">
          <label for="company[public_site]" class="mainstage-form-label"> 506c / public company?</label>
        </div>
        <div class="col-12">
          <input type="checkbox" v-model="comp.reg_cf" name="company[reg_cf]" id="company[reg_cf]">
          <label for="company[reg_cf]" class="mainstage-form-label"> RegCF company?</label>
        </div>
        <div class="col-12">
          <input type="checkbox" v-model="comp.show_redcrow_disclaimer" name="company[show_redcrow_disclaimer]" id="company[show_redcrow_disclaimer]">
          <label for="company[show_redcrow_disclaimer]" class="mainstage-form-label"> Show RedCrow disclaimer footer?</label>
        </div>
        <div class="col-12">
          <label for="company[corekonx_listing_id]" class="mainstage-form-label">CoreKonx listing ID</label>
          <input class="form-control" type="text" v-model="comp.corekonx_listing_id" name="company[corekonx_listing_id]" id="company[corekonx_listing_id]">
        </div>
        <div class="col-12">
          <label for="company[corekonx_client_token]" class="mainstage-form-label">CoreKonx client token</label>
          <input class="form-control" type="text" v-model="comp.corekonx_client_token" name="company[corekonx_client_token]" id="company[corekonx_client_token]">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ["company"],
    data() {
      let comp = { ... this.company }

      return {
        comp: comp,
      }
    },
    watch: {
      comp: {
        deep: true,
        handler() {
          this.$emit("input", this.comp)
        }
      }
    }
  }
</script>
