<template>
  <div>
    <FileDragTarget
      :class="{ dragging: dragging, 'has-file': imageId || dataURL }"
      :fileInput="$refs.fileInput"
      class="upload-drag-spot"
      ref="container"
      @change="readFile"
      @dragstart="dragging = true"
      @dragend="dragging = false">
      <img :src="imageSource" class="upload-image"/>
      <label class="btn btn-light upload-button fa fa-edit">
        <input type="file" ref="fileInput" :name="fileField" @change="readFile" hidden/>
      </label>
    </FileDragTarget>
  </div>
</template>

<style scoped>
.upload-image {
  max-width: 150px;
  max-height: 150px;
}

.upload-drag-spot {
  position: relative;
  max-width: 150px;
  max-height: 150px;
  width: fit-content;
  height: fit-content;
  border: 2px dashed #ccc;
  padding: 5px;
}

.upload-drag-spot.has-file {
  border: none;
}

.upload-drag-spot.dragging {
  border-color: black;
}
</style>
<script>
  import FileDragTarget from 'components/file_drag_target.vue'

  export default {
    props: ["image-id", "fileField", "default-image" ],
    components: { FileDragTarget },
    data() {
      return {
        dataURL: null,
        dragging: false
      }
    },
    methods: {
      readFile () {
        let input = this.$refs.fileInput

        if (input.files && input.files[0]) {
          var reader = new FileReader();

          reader.onload = (e) => {
            this.dataURL = e.target.result
            this.$emit("result", this.dataURL)
          }
          reader.readAsDataURL(input.files[0])
        }
      }
    },
    computed: {
      imageSource() {
        if ( this.dataURL )
          return this.dataURL
        else if ( this.imageId )
          return `/images/${this.imageId}`
        else if ( this.defaultImage )
          return this.defaultImage
        else
          return '/images/drag_msg.png'
      }
    }
  }
</script>

