<template>
  <div class="mb-1">
    <vuetable
      ref="vuetable"
      class="investor-table mainstage-table"
      :css="tableCSS"
      :fields="fields"
      :api-url="apiUrl"
      :per-page="10"
      data-path="activity"
      pagination-path="links.pagination"
      @vuetable:pagination-data="onPaginationData">
      <div slot="fullname-slot" slot-scope="props">
        <a :href="`/audience/${props.rowData.user.id}`">{{ props.rowData.user.fullname }}</a>
      </div>
      <div slot="activity-date-slot" slot-scope="props">
        {{ props.rowData.created_at | formatDatetime }}
      </div>
    </vuetable>
    <div class="d-flex justify-content-end mt-2">
      <vuetable-bootstrap-pagination
        @vuetable-pagination:change-page="onChangePage" ref="pagination" />
    </div>
  </div>
</template>

<script>

import Vuetable from 'vuetable-2'
import VuetableBootstrapPagination from 'components/vuetable_bootstrap_pagination.vue'

export default {
  props: [ "api-url" ],
  data() {
    return {
      tableCSS: {
        tableWrapper: '',
        tableHeaderClass: 'mb-0',
        tableBodyClass: 'mb-0',
        tableClass: 'table table-hover',
        loadingClass: 'loading',
        detailRowClass: 'vuetable-detail-row'
      },
      fields: [
        {
          name: "fullname-slot",
          title: "Name"
        },
        {
          name: "activity-date-slot",
          title: "Visit Date"
        },
        {
          name: "visits",
          title: "# of visits"
        }
      ]
    }
  },
  methods: {
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    }
  },
  components: {
    Vuetable, VuetableBootstrapPagination
  }
}
</script>
