<template>
  <div>
    <small v-for="err in errList" class="text-danger">
      {{ err }}
    </small>
  </div>
</template>

<script>
export default {
  props: [ "errors", "obj", "field" ],
  computed: {
    errList() {
      if ( Array.isArray(this.errors) )
        return this.errors
      else if ( this.errors )
        return [ this.errors]
      else if ( this.obj && this.field ) {
        if ( this.obj && this.obj.errors && this.obj.errors[this.field] )
          return this.obj.errors[this.field]
        else
          return [ ]
      } else
        return []
    }
  }
}
</script>

